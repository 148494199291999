import React from 'react';

import DashboardAlt from "../dashboards/DashboardAlt";
import AspirantToStudentTable from '../tables/AspirantToStudentTable';

function AspirantToStudent() {
  return (
    <DashboardAlt noPadding={true}>
      <AspirantToStudentTable />
    </DashboardAlt>
  );
}

export default AspirantToStudent;
