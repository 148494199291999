import React, { useEffect, useState } from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Loading from "components/Loading";
import InputDate from "components/Inputs/InputDate";
import { success } from "components/Alerts";

import {
  configEnglishInitialState,
  ENGLISH_DAYS,
} from "utils/states/configEnglish";
import { configEnglishSchema } from "utils/schemas/configEnglish.schemas";
import formatDate from "utils/formatDate";
import formatTime from "utils/formatTime";

import { getListsForAddEnglishClasses, addEnglishLevel } from "api/english";

function FormConfigEnglish() {
  const [selectsList, setSelectsList] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const copyOfValues = { ...values };
    delete copyOfValues.day;
    if (values.day.length === 1) {
      copyOfValues.day = parseInt(values.day[0].value, 10);
      try {
        setLoading(true);
        const response = await addEnglishLevel(copyOfValues);
        setLoading(false);
        if (response) {
          success(
            "Operación exitosa",
            "Esta clase a sido creada correctamente correctamente.",
            "Aceptar",
            () => {}
          );
        }
      } catch (error) {
        // console.log("error", error);
      }
    } else {
      // Pendiente de logica
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const selectValues = await getListsForAddEnglishClasses();
      setSelectsList(selectValues);
      setLoading(false);
    };
    fetchInitialData();
    return () => {};
  }, []);

  if (loading) return <Loading />;

  return (
    <div className={style.container}>
      <h1 className={style.title}>
        Configuración inicial para el proceso de admisión
      </h1>
      <div className={style.contForm}>
        <Formik
          initialValues={configEnglishInitialState}
          validationSchema={configEnglishSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { errors, handleChange, touched, setFieldValue } = props;
            return (
              <Form>
                <Collapse title="Configuración de la clase">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <InputSelect
                      error={touched.englishPeriod && errors.englishPeriod}
                      name="englishPeriod"
                      title="Periodo de inglés"
                      options={selectsList.englishPeriodsList}
                      //   isLoading={!values.checkCurrent && loading}
                      onChange={(selectedOption) => {
                        handleChange("englishPeriod")(selectedOption.value);
                      }}
                    />
                    <InputSelect
                      error={touched.englishLevel && errors.englishLevel}
                      name="englishLevel"
                      title="Nivel de inglés"
                      options={selectsList.englishLevelsList}
                      //   isLoading={!values.checkCurrent && loading}
                      onChange={(selectedOption) => {
                        handleChange("englishLevel")(selectedOption.value);
                      }}
                    />
                    <Input
                      name="limit"
                      title="Limite de alumnos"
                      type="number"
                    />
                  </div>
                </Collapse>
                <Collapse title="Fecha y docente de la clase">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <InputSelect
                      error={touched.day && errors.day}
                      name="day"
                      title="Días de clase"
                      options={ENGLISH_DAYS}
                      //   isLoading={!values.checkCurrent && loading}
                      onChange={setFieldValue}
                      multiple
                    />
                    <InputDate
                      error={touched.hour && errors.hour}
                      name="hour"
                      title="Hora de clase"
                      time={true}
                      date={false}
                      timeFormat="hh:mm a"
                      onChange={(timeFromValue) => {
                        handleChange("hour")(formatTime(timeFromValue));
                      }}
                    />
                    <InputDate
                      error={touched.year && errors.year}
                      name="year"
                      title="Año"
                      time={false}
                      format="YYYY"
                      onChange={(dateFromValue) => {
                        handleChange("year")(formatDate(dateFromValue));
                      }}
                    />
                    <div className={style.inputLong}>
                      <InputSelect
                        error={touched.teacher && errors.teacher}
                        name="teacher"
                        title="Maestro (a)"
                        options={selectsList.teachersList}
                        //   isLoading={!values.checkCurrent && loading}
                        onChange={(selectedOption) => {
                          handleChange("teacher")(selectedOption.value);
                        }}
                      />
                    </div>
                  </div>
                </Collapse>
                <div className={style.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormConfigEnglish;
