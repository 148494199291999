import * as Yup from "yup";

export const addClassroomSchema = Yup.object({
  classroom: Yup.string()
    .max(4, "Solo se admiten 4 carácteres.")
    .required("Este campo es requerido."),
  capacity: Yup.number()
    .min(1, "La capacidad solo puede ser de 1 - 60.")
    .max(60, "La capacidad solo puede ser de 1 - 60.")
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  carrer: Yup.string().required("Selecciona una opción."),
});

export const addClassroomNewSchema = Yup.object({
  key: Yup.string().required("Este campo es requerido."),
  capacity: Yup.number()
    .min(1, "La capacidad solo puede ser de 1 - 100.")
    .max(100, "La capacidad solo puede ser de 1 - 100.")
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  ubication: Yup.string().required("Este campo es requerido."),
  timeCrossing: Yup.boolean(),
  observations: Yup.string(),
});
