import React, { useState } from "react";
import classnames from "classnames";
import { error, success } from "components/Alerts";
import InputCheckTable from "components/Inputs/InputCheckTable";
import InputSearch from "components/Inputs/InputSearch";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Modal from "components/modal/Modal";
import Profile from "components/Profile/Profile";
import style from "assets/css/tables/Table.module.css";
import tableStyle from "assets/css/tables/DesignTableGral.module.css";

import useGetStudents from "hooks/useGetStudents";
import Loading from "components/Loading";

const modalStates = { showAspirant: false, showStudent: false };

function AspirantToStudentTable() {
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [estudiante, setEstudiante] = useState("");
  const [studentListModal, setStudentListModal] = useState(null);
  const [activeModals, setActiveModals] = useState(modalStates);

  const status = "aceptado";
  const nextStatus = "estudiante";
  const carrera = "61be9fa0c758ec001680c4fc";

  const { list, loading, updateStatus, pages, nextPage, previousPage, page } =
    useGetStudents(carrera, status);

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  const handleClickItem = (id) => {
    setUser(id);
    setActiveModals({
      ...activeModals,
      showAspirant: !activeModals["showAspirant"],
    });
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setEstudiante([...estudiante, e.target.value]);
    } else {
      setEstudiante(estudiante.filter((item) => item !== e.target.value));
    }
  };

  const filterName = list.filter((item) =>
    item.name.toUpperCase().includes(search.toUpperCase())
  );

  const sendData = async () => {
    if (estudiante.length > 0) {
      const res = await updateStatus(estudiante, nextStatus);

      if (res.status) {
        setStudentListModal(res.arrayStudents);
        success(
          "Datos correctos",
          "Se han actualizado los datos correctamente",
          "Aceptar",
          () => {
            setActiveModals({
              ...activeModals,
              showStudent: !activeModals["showStudent"],
            });
          }
        );
      } else {
        window.location.reload();
      }
    } else {
      error(
        "Advertencia",
        "Debe seleccionar al menos un alumno para continuar"
      );
    }
  };

  if (loading) return <Loading />;
  return (
    <div className={tableStyle.container}>
      <div className={style.contTitle}>
        <h1 className={style.title}>Lista de aspirantes aceptados</h1>
        <div className={style.contActions}>
          <p>
            Página {page} de {pages}
          </p>
          <div className={style.actFilter}>
            <button className={style.btnFilter} onClick={previousPage}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className={style.btnFilter} onClick={nextPage}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
          <div className={style.contSearch}>
            <InputSearch onChange={(e) => setSearch(e.target.value)} />
          </div>
        </div>
      </div>
      <div className={style.contTable}>
        <table className={style.table}>
          <HeadTable />
          <tbody className={style.bodyTable}>
            {filterName.map((item, index) => {
              const {
                id,
                name,
                firstLastName,
                secondLastName,
                gender,
                dateOfBirth,
              } = item;
              return (
                <tr key={id}>
                  <td onClick={() => handleClickItem(id)}>
                    <div
                      className={classnames(
                        style.contRow,
                        style.contText,
                        style.contStart
                      )}
                    >
                      <p className={style.text}>{index + 1}</p>
                    </div>
                  </td>
                  <td onClick={() => handleClickItem(id)}>
                    <div className={classnames(style.contRow)}>
                      <p
                        className={style.text}
                      >{`${name} ${firstLastName} ${secondLastName}`}</p>
                    </div>
                  </td>
                  <td onClick={() => handleClickItem(id)}>
                    <div className={style.contRow}>
                      <p className={style.text}>
                        {" "}
                        {item.student?.careerOptions?.map(
                          (option, index) =>
                            `${
                              option.firstOption ? option.career.shortName : ""
                            }` +
                            (index < item.student?.careerOptions.length - 1
                              ? ``
                              : ``)
                        )}
                      </p>
                    </div>
                  </td>
                  <td onClick={() => handleClickItem(id)}>
                    <div className={style.contRow}>
                      <p className={style.text}>{dateOfBirth}</p>
                    </div>
                  </td>
                  <td onClick={() => handleClickItem(id)}>
                    <div className={style.contRow}>
                      <p className={style.text}>
                        {gender === "Hombre" ? "M" : "F"}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      className={classnames(
                        style.contRow,
                        style.contEnd,
                        style.contText
                      )}
                    >
                      <InputCheckTable
                        name="AcceptAspirant"
                        value={item.student.id}
                        onChange={handleCheckbox}
                        defaultChecked={
                          estudiante.includes(item.student.id) ? true : false
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={tableStyle.contButton}>
          <BtnPrincipal
            text="Aspirantes a estudiantes"
            onClick={() => sendData()}
          />
        </div>
      </div>
      <Modal
        title="Perfil aspirante"
        show={activeModals.showAspirant}
        close={changeModalState("showAspirant")}
      >
        <div className={style.contModal}>
          <Profile idUser={user} />
        </div>
      </Modal>
      <Modal
        title="Lista de estudiantes aceptados"
        show={activeModals.listStudentsAcepted}
        close={changeModalState("listStudentsAcepted")}
      >
        <div className={style.contModal}>
          <Profile idUser={user} />
        </div>
      </Modal>
      <Modal
        title="Lista de estudiantes aceptados"
        show={activeModals.showStudent}
        close={changeModalState("showStudent")}
      >
        <div className={tableStyle.contModal}>
          <div className={tableStyle.overflowDataList}>
            {studentListModal?.map((item, index) => {
              const { name, firstLastName, secondLastName, student } = item;

              const firstOption = student?.careerOptions?.map(
                (option, index) =>
                  `${option.firstOption ? option.career.shortName : ""}` +
                  (index < item.student?.careerOptions.length - 1 ? `` : ``)
              );

              return (
                <div key={index} className={tableStyle.infoDetailsStudent}>
                  <p>
                    {index + 1} {name} {firstLastName} {secondLastName}
                  </p>
                  <p>{firstOption}</p>
                </div>
              );
            })}
          </div>
          <div className={tableStyle.stylesContainerButtonPrincipal}>
            <BtnPrincipal
              text="Aceptar"
              onClick={() => {
                changeModalState("showAccept");
                window.location.reload();
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

const HeadTable = () => (
  <thead className={style.headTable}>
    <tr className={style.contColumn}>
      <th className={classnames(style.column, style.alignText)}>No</th>
      <th className={style.column}>Nombre de aspirante</th>
      <th className={style.column}>Carreras selecionadas</th>
      <th className={style.column}>Nacimiento</th>
      <th className={style.column}>Sexo</th>
      <th className={classnames(style.column, style.alignText)}>Seleccionar</th>
    </tr>
  </thead>
);

export default AspirantToStudentTable;
