import React, { useState } from "react";
import axios from "axios";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";
import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import TextArea from "components/Inputs/TextArea";
import Loading from "components/Loading";
import ToggleText from "components/Inputs/ToggleText";
import { createClassroom } from "../../../utils/states/classroom";
import { addClassroomNewSchema } from "../../../utils/schemas/classroom.schemas";
import ConsoleHelper from "ConsoleHelper";
import { error, success } from "components/Alerts";

function FormCreateClassroom() {
  const [loading, setLoading] = useState(false);

  if (loading) return <Loading />;

  const handleSubmit = async (values) => {
    setLoading(true);
    values = {
      ...values,
      status: true
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const gps = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          values = { ...values, locationGPS: gps };
          addClassroom(values);
        },
        () => {
          addClassroom(values);
        }
      );
    } else {
      addClassroom(values);
    }
  };

  const addClassroom = async (values) => {
    try {
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/classrooms";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      ConsoleHelper(response.data);
      if (response) {
        success(
          "Agregado correctamente",
          "El aula ha sido creado correctamente.",
          "Aceptar",
          () => {}
        );
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      error(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Crear nuevas aulas</h1>
      <div className={style.contForm}>
        <Formik
          initialValues={createClassroom}
          validationSchema={addClassroomNewSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { values } = props;
            return (
              <Form>
                <Collapse title="Datos del aula">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <Input name="key" title="Nombre" type="text" />
                    <div className={style.inputMedium}>
                      <Input name="ubication" title="Ubicación" type="text" />
                    </div>
                    <Input name="capacity" title="Capacidad" type="number" />
                    <ToggleText
                      name="timeCrossing"
                      title="Cruce de horario"
                      other
                      value={values.timeCrossing}
                    />
                    <div className={style.inputLong}>
                      <TextArea name="observations" title="Observaciones" />
                    </div>
                  </div>
                </Collapse>
                <div className={style.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormCreateClassroom;
