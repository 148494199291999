function transformToRows({ input = [], limit = 10 }) {
  let inputInRows = [[], [], [], [], [], [], [], [], [], []];

  for (let i = 0; i < 10; i++) {
    let semester = input.find((inp) => inp.semester === i + 1);

    for (let j = 0; j < 10; j++) {
      if (semester === undefined) {
        inputInRows[j][i] = false;
      } else {
        let subject = semester.reticleSubjects.find(
          (subj) => subj.order - 1 === j
        );
        if (subject === undefined) {
          inputInRows[j][i] = false;
        } else {
          inputInRows[j][i] = subject;
        }
      }
    }
  }

  return inputInRows;
}

export default transformToRows;
