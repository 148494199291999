import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import CardWelcome from "components/Card/CardWelcome";
import DashboardAlt from "./DashboardAlt";

import style from "assets/css/Dashboard/DashboardDefault.module.css";
import image from "assets/img/theme/work.svg";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";
import { GET_USER_ID } from "utils/graphql/querys";

function DashboardDefault() {
  const [id] = useLocalStorage("id", null);
  const [role] = useLocalStorage("role", null);
  const history = useRef(useHistory());

  useEffect(() => {
    if (role && role === "Aspirante") {
      history.current.push("/404");
    }
  });

  const { loading, data, error } = useQuery(GET_USER_ID, {
    variables: {
      IdUser: id,
      State: "LIVE",
    },
  });

  if (loading) return <Loading />;

  if (error) {
    return <ErrorData btn />;
  }

  return (
    <DashboardAlt>
      <div className={style.container}>
        <div className={style.welcome}>
          <CardWelcome
            user={data?.user?.name}
            completeTitle="bienvenido de nuevo"
            image={image}
          >
            Nos da mucho gusto verte de nuevo por aquí, que tengas un{" "}
            <span>excelente</span> pero sobre todo <span>agradable</span> día de
            trabajo.
          </CardWelcome>
        </div>
      </div>
    </DashboardAlt>
  );
}

export default DashboardDefault;
