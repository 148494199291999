import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import ListReticles from "../tables/ListReticles";

function Reticles() {
  return (
    <DashboardAlt>
      <ListReticles />
    </DashboardAlt>
  );
}

export default Reticles;
