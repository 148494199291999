import React, { useState } from "react";
import axios from "axios";

import styles from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";

import { newDegreeSchema } from "utils/schemas/degree.schemas";
import { createDegreeInitialState } from "utils/states/degree";

import { Formik, Form } from "formik";
import ConsoleHelper from "ConsoleHelper";
import useGetPermissions from "hooks/useGetPermissions";
import Loading from "components/Loading";
import { useQuery } from "@apollo/client";
import { GET_SUBJECT_TYPE_LEVEL_AREA } from "utils/graphql/querys";
import ErrorData from "components/Error/ErrorData";
import { error as errorAlert, success } from "../../../components/Alerts";

function FormNewDegree() {
  const { data, loading, error } = useQuery(GET_SUBJECT_TYPE_LEVEL_AREA);
  const { avaible } = useGetPermissions("career", ["create"]);
  const [loadingCareer, setLoadingCareer] = useState(false);

  if (avaible === null || loading || loadingCareer) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleSubmit = async (values) => {
    try {
      setLoadingCareer(true);
      ConsoleHelper(values);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/careers";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      console.log(response);
      if (response) {
        success(
          "Agregado correctamente",
          "La carrera ha sido creado correctamente.",
          "Aceptar",
          () => {}
        );
      }
    } catch (e) {
      ConsoleHelper(e.response);
      errorAlert(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoadingCareer(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Registro de nueva carrera</h1>
      <div className={styles.contForm}>
        <Formik
          initialValues={createDegreeInitialState}
          validationSchema={newDegreeSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { errors, handleChange, touched } = props;
            return (
              <Form>
                <Collapse title="Claves de la carrera" idCollapse="test">
                  <div
                    className={classnames(styles.formDate, styles.twoColumn)}
                  >
                    <Input
                      name="careerKey"
                      title="Clave de carrera"
                      type="text"
                    />
                    <InputSelect
                      error={touched.school_level && errors.school_level}
                      name="school_level"
                      title="Nivel escolar"
                      options={data.schoolLevels}
                      onChange={(selectedOption) => {
                        handleChange("school_level")(selectedOption.value);
                      }}
                    />
                  </div>
                </Collapse>
                <Collapse title="Datos de la carrera" idCollapse="test">
                  <div
                    className={classnames(styles.formDate, styles.twoColumn)}
                  >
                    <div className={styles.inputLong}>
                      <Input
                        name="name"
                        title="Nombre de la carrera"
                        type="text"
                      />
                    </div>
                    <Input
                      name="shortName"
                      title="Nombre reducido"
                      type="text"
                    />
                    <Input name="abbreviation" title="Siglas" type="text" />
                  </div>
                </Collapse>
                <div className={styles.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormNewDegree;
