import React, { useState } from "react";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputSearch from "components/Inputs/InputSearch";
import InputCheckTable from "components/Inputs/InputCheckTable";
import { error, success } from "components/Alerts";
import styles from "assets/css/tables/DesignTableGral.module.css";
import useGetStudents from "hooks/useGetStudents";
import Loading from "components/Loading";
import Modal from "components/modal/Modal";

const modalStates = { showAccept: false };

function AcceptAspirantTable() {
  const [search, setSearch] = useState("");
  const [estudiante, setEstudiante] = useState("");
  const [studentListModal, setStudentListModal] = useState(null);
  const [activeModals, setActiveModals] = useState(modalStates);

  const status = "aspirante";
  const nextStatus = "aceptado";
  const carrera = "61be9fa0c758ec001680c4fc";

  const { list, loading, updateStatus, pages, nextPage, previousPage, page } =
    useGetStudents(carrera, status);

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setEstudiante([...estudiante, e.target.value]);
    } else {
      setEstudiante(estudiante.filter((item) => item !== e.target.value));
    }
  };

  const filterName = list.filter((item) =>
    item.name.toUpperCase().includes(search.toUpperCase())
  );

  const sendData = async () => {
    if (estudiante.length > 0) {
      const res = await updateStatus(estudiante, nextStatus);

      if (res.status) {
        setStudentListModal(res.arrayStudents);
        success(
          "Datos correctos",
          "Se han actualizado los datos correctamente",
          "Aceptar",
          () => {
            setActiveModals({
              ...activeModals,
              showAccept: !activeModals["showAccept"],
            });
          }
        );
      } else {
        window.location.reload();
      }
    } else {
      error(
        "Advertencia",
        "Debe seleccionar al menos un alumno para continuar"
      );
    }
  };
  if (loading) return <Loading />;

  return (
    <div className={styles.container}>
      <div className={styles.contTitle}>
        <h1 className={styles.title}>Lista de aspirantes</h1>
        <div className={styles.contActions}>
          <p>
            Página {page} de {pages}
          </p>
          <div className={styles.actFilter}>
            <button className={styles.btnFilter} onClick={previousPage}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className={styles.btnFilter} onClick={nextPage}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
          <div className={styles.contSearch}>
            <InputSearch
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
      </div>
      <div className={styles.contTable}>
        <table className={styles.table}>
          <HeadTable />
          <tbody className={styles.bodyTable}>
            {filterName.map((item) => {
              const {
                id,
                name,
                firstLastName,
                secondLastName,
                gender,
                dateOfBirth,
              } = item;

              return (
                <tr className={styles.row} key={id}>
                  <td
                    className={styles.data}
                  >{`${name} ${firstLastName} ${secondLastName}`}</td>
                  <td className={styles.data}>
                    {item.student?.careerOptions?.map(
                      (option, index) =>
                        `${option.career.shortName}` +
                        (index < item.student?.careerOptions.length - 1
                          ? `, `
                          : ``)
                    )}
                  </td>
                  <td className={styles.data}>{dateOfBirth}</td>
                  <td className={styles.data}>
                    {gender === "Hombre" ? "M" : "F"}
                  </td>
                  <td className={styles.data}>
                    <InputCheckTable
                      name="AcceptAspirant"
                      value={item.student.id}
                      onChange={handleCheckbox}
                      defaultChecked={
                        estudiante.includes(item.student.id) ? true : false
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.contButton}>
        <BtnPrincipal text="Aceptar Aspirantes" onClick={sendData} />
      </div>
      <Modal
        title="Lista de aspirantes aceptados"
        show={activeModals.showAccept}
        close={changeModalState("showAccept")}
      >
        <div className={styles.contModal}>
          <div className={styles.overflowDataList}>
            {studentListModal?.map((item, index) => {
              const { name, firstLastName, secondLastName, student } = item;

              const firstOption = student?.careerOptions?.map(
                (option, index) =>
                  `${option.firstOption ? option.career.shortName : ""}` +
                  (index < item.student?.careerOptions.length - 1 ? `` : ``)
              );

              return (
                <div key={index} className={styles.infoDetailsStudent}>
                  <p>
                    {index + 1} {name} {firstLastName} {secondLastName}
                  </p>
                  <p>{firstOption}</p>
                </div>
              );
            })}
          </div>
          <div className={styles.stylesContainerButtonPrincipal}>
            <BtnPrincipal
              text="Aceptar"
              onClick={() => {
                changeModalState("showAccept");
                window.location.reload();
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

const HeadTable = () => (
  <thead className={styles.headTable}>
    <tr className={styles.contField}>
      <th className={styles.field}>Nombre de aspirante</th>
      <th className={styles.field}>Carreras selecionadas</th>
      <th className={styles.field}>Nacimiento</th>
      <th className={styles.field}>Sexo</th>
      <th className={styles.field}>Seleccionar</th>
    </tr>
  </thead>
);

export default AcceptAspirantTable;
