import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import { emergencyInitialState } from "utils/states/aspirant";
import { addEmergencySchema } from "utils/schemas/aspirant.schemas";
import { servicioMedico } from "utils/dataSelectsAspirant";
import { tipoSangre } from "utils/dataSelectsAspirant";
import { donador } from "utils/dataSelectsAspirant";
import useZipCode from "hooks/useZipCode";
import useAddEmergencyData from "hooks/useAddEmergencyData";
import Loading from "components/Loading";
import { error } from "components/Alerts";
import { GET_PARENTS_USER } from "utils/graphql/querys";
import ConsoleHelper from "ConsoleHelper";

function FormEmergency({ setPosition, idUser, token }) {
  const { data } = useQuery(GET_PARENTS_USER, {
    variables: {
      Where: {
        user: idUser,
      },
    },
  });
  const { loading, getZipCode } = useZipCode();
  const [optionZipCode, setOptionsZipCode] = useState([]);
  const { loadingEmergency, addEmergency } = useAddEmergencyData();

  if (loadingEmergency) return <Loading />;

  const handleSubmit = async (values) => {
    ConsoleHelper(values);
    let valuesEconomy = {
      bloodType: values.bloodType,
      medicalService: values.medicalService,
      medicalServiceNumber:
        values.medicalService === "Ninguno" ? "" : values.medicalServiceNumber,
      nameContactEmergency: values.nameContactEmergency,
      organDonor: values.organDonor,
      phoneContactEmergency: values.phoneContactEmergency,
      user: idUser,
    };
    let valuesAddress = {
      postalCode: values.postalCode,
      country: values.country,
      municipaly: values.municipaly,
      colony: values.colony,
      number: 0,
      placeOrigin: true,
      street: values.street,
    };
    ConsoleHelper(valuesEconomy);
    ConsoleHelper(valuesAddress);
    const response = await addEmergency(token, valuesEconomy, valuesAddress);
    if (response) {
      if (response === "error") {
        error(
          "Lo sentimos",
          "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
        );
      } else {
        setPosition(7);
      }
    }
  };

  return (
    <Formik
      initialValues={emergencyInitialState}
      validationSchema={addEmergencySchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched, setFieldValue, values } = props;

        const onChangeZipCode = async (e) => {
          handleChange(e);
          let cp = e.target.value.replace(" ", "");
          if (cp.length === 5) {
            const objCp = await getZipCode(cp);
            setFieldValue("country", objCp.entity, false);
            setFieldValue("municipaly", objCp.town, false);
            setOptionsZipCode(objCp.data);
          }
        };

        return (
          <Form>
            <Collapse title="Datos de emergencia">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <InputSelect
                  name="medicalService"
                  title="¿Cuenta con servicio médico activo?"
                  error={touched.medicalService && errors.medicalService}
                  options={servicioMedico}
                  onChange={(selectedOption) => {
                    handleChange("medicalService")(selectedOption.value);
                    setFieldValue(
                      "medicalServiceNumber",
                      selectedOption.value === "Ninguno" ? "Ninguno" : "",
                      false
                    );
                  }}
                />
                {values.medicalService !== "Ninguno" && (
                  <Input
                    name="medicalServiceNumber"
                    title="Número o clave de derechohabiente"
                    type="text"
                    onPaste={(e) => e.preventDefault()}
                    autoComplete="off"
                  />
                )}
                <InputSelect
                  name="bloodType"
                  title="Tipo de sangre"
                  error={touched.bloodType && errors.bloodType}
                  options={tipoSangre}
                  onChange={(selectedOption) => {
                    handleChange("bloodType")(selectedOption.value);
                  }}
                />
                <InputSelect
                  name="organDonor"
                  title="¿Donador de organos?"
                  error={touched.organDonor && errors.organDonor}
                  options={donador}
                  onChange={(selectedOption) => {
                    handleChange("organDonor")(selectedOption.value);
                  }}
                />
              </div>
            </Collapse>
            <Collapse title="Contacto de emergencia">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <InputSelect
                  name="nameContactEmergency"
                  title="Contacto de emergencia"
                  options={data && data.parents}
                  error={
                    touched.nameContactEmergency && errors.nameContactEmergency
                  }
                  onChange={(selectedOption) => {
                    handleChange("nameContactEmergency")(selectedOption.value);
                  }}
                />
                <Input
                  name="phoneContactEmergency"
                  title="Teléfono de contacto de emergencia"
                  type="tel"
                  maxLength={10}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                />
                <Input
                  name="postalCode"
                  title="Código postal"
                  type="text"
                  maxLength={5}
                  onChange={onChangeZipCode}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                />
                <Input disabled name="country" title="Estado" type="text" />
                <Input
                  disabled
                  name="municipaly"
                  title="Municipio"
                  type="text"
                />
                <InputSelect
                  name="colony"
                  title="Colonia ó localidad"
                  error={touched.colony && errors.colony}
                  options={optionZipCode}
                  isLoading={loading}
                  onChange={(selectedOption) => {
                    handleChange("colony")(selectedOption.label);
                  }}
                />
                <div className={classnames(style.inputLong)}>
                  <Input
                    name="street"
                    title="Calle"
                    type="text"
                    onPaste={(e) => e.preventDefault()}
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormEmergency;
