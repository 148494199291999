import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useAddParent() {
  const [loadingParent, setLoadingParent] = useState(false);

  const addParent = async (token, parentData) => {
    try {
      setLoadingParent(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_USER_PARENT_URL = process.env.REACT_APP_REST_URI + "/parents";
      const parentResponse = await axios.post(ADD_USER_PARENT_URL, parentData, {
        headers: { Authorization: BEARER_TOKEN },
      });
      ConsoleHelper(parentResponse?.data);
      return parentResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingParent(false);
    }
  };

  const addUserParent = async (user, token, parentData) => {
    let response = null;
    if (Object.keys(parentData.father).length !== 0) {
      parentData.father = {
        ...parentData.father,
        contactEmergency: false,
        type: "Father",
        user,
      };
      ConsoleHelper(parentData.father);
      response = await addParent(token, parentData.father);
    }
    if (Object.keys(parentData.mother).length !== 0) {
      parentData.mother = {
        ...parentData.mother,
        contactEmergency: false,
        type: "Mother",
        user,
      };
      ConsoleHelper(parentData.mother);
      response = await addParent(token, parentData.mother);
    }
    if (Object.keys(parentData.tutor).length !== 0) {
      parentData.tutor = {
        ...parentData.tutor,
        contactEmergency: false,
        type: "Tutor",
        user,
      };
      ConsoleHelper(parentData.tutor);
      response = await addParent(token, parentData.tutor);
    }
    if (response) {
      return response;
    } else {
      return "error";
    }
  };

  return { loadingParent, addUserParent };
}

export default useAddParent;
