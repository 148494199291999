import * as Yup from "yup";

export const addGroupSchema = Yup.object({
  career: Yup.string().required("Selecciona una opción."),
  period: Yup.string().required("Selecciona una opción."),
  semester: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido.")
    .min(1, "semestre minimo es 1")
    .max(10, "semestre maximo es 10"),
  subject: Yup.string().required("Selecciona una opción."),
  name: Yup.string().required("Este campo es requerido."),
  capacity: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
});


