import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import leaflet from "leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

leaflet.Marker.prototype.options.icon = DefaultIcon;

function Map({ position }) {
  return (
    <MapContainer
      center={position}
      zoom={20}
      zoomControl={false}
      scrollWheelZoom={false}
      attributionControl={false}
      style={{ width: "100%", height: "100%" }}
    >
      {/* <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      /> */}
      {/* 
      
      mapbox://styles/odalager17/cl1pelir2002v14o4bbn5xp8w
      pk.eyJ1Ijoib2RhbGFnZXIxNyIsImEiOiJjbDFwZXRxMnYxZHhmM2NzOTJzNzBreXpnIn0.KuuhVMo8MBze4G5nle4pYA

      https://api.mapbox.com/styles/v1/odalager17/cl1pelir2002v14o4bbn5xp8w/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2RhbGFnZXIxNyIsImEiOiJjbDFwZXRxMnYxZHhmM2NzOTJzNzBreXpnIn0.KuuhVMo8MBze4G5nle4pYA
      
      mapbox://styles/odalager17/cl1pf8ljz000214nzg9zhimsy
      */}
      <TileLayer
        url="https://api.mapbox.com/styles/v1/odalager17/cl1pelir2002v14o4bbn5xp8w/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2RhbGFnZXIxNyIsImEiOiJjbDFwZXRxMnYxZHhmM2NzOTJzNzBreXpnIn0.KuuhVMo8MBze4G5nle4pYA"
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
      />
      <Marker position={position}>
        <Popup>Aula V1</Popup>
      </Marker>
    </MapContainer>
  );
}

export default Map;
