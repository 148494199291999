import React, { useState } from "react";

import style from "assets/css/components/InputBtnAction.module.css";

import { useField } from "formik";

function InputBtnAction({
  link,
  icon,
  password,
  href,
  type,
  placeholder,
  iconBtn,
  ...props
}) {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.contInput}>
        <div className={style.contIcon}>
          <p className={style.icon}>
            <i className={icon} />
          </p>
        </div>
        <input
          {...field}
          {...props}
          className={style.input}
          type={password ? (showPassword ? "text" : "password") : type}
          placeholder={placeholder}
        />
        <div className={style.contIcon}>
          {link ? (
            <a
              className={style.icon}
              target="_blank"
              rel="noopener noreferrer"
              href={href}
            >
              <i className={iconBtn} />
            </a>
          ) : (
            <p className={style.icon}>
              {password ? (
                <i
                  className={showPassword ? "far fa-eye" : "far fa-eye-slash"}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <i className={iconBtn} />
              )}
            </p>
          )}
        </div>
      </div>
      {meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default InputBtnAction;
