import React from "react";

import styles from "../../assets/css/components/HeaderAlt.module.css";

import imageTec from "../../assets/img/theme/tecNMWhite.svg";

function HeaderAlt({
  children,
  fullName,
  role,
  photo,
  onClick,
  setNoti,
  noti,
  countNoti,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.contBtnMenu}>{children}</div>
      <div className={styles.contImage}>
        <img className={styles.image} src={imageTec} alt="Logo TecNM" />
      </div>
      <div className={styles.contTitle}>
        <p className={styles.title}>Tecnológico Nacional de México</p>
        <p className={styles.desc}>Campus istmo</p>
      </div>
      <div className={styles.notification}>
        <>
          <p className={styles.icon} onClick={() => setNoti && setNoti(!noti)}>
            <i className="fas fa-bell"></i>
          </p>
          {countNoti
            ? countNoti > 0 && <p className={styles.count}>{countNoti}</p>
            : null}
        </>
      </div>
      <div className={styles.contPerfil}>
        <div className={styles.namePerfil}>
          <p className={styles.title}>{fullName}</p>
          <p className={styles.desc}>{role}</p>
        </div>
        <div className={styles.imageProfile}>
          {photo ? (
            <img
              className={styles.imgProfile}
              src={photo}
              alt="Foto de perfil"
              onClick={onClick && onClick}
            />
          ) : (
            <ProfileDefault onClick={onClick && onClick} />
          )}
        </div>
      </div>
    </div>
  );
}

const ProfileDefault = ({ onClick }) => (
  <div className={styles.contIcon} onClick={onClick}>
    <p className={styles.icon}>
      <i className="fas fa-user"></i>
    </p>
  </div>
);

export default HeaderAlt;
