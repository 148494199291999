import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import Aspirant from "../tables/Aspirant"

function ListAspirant() {
  return (
    <DashboardAlt>
      <Aspirant />
    </DashboardAlt>
  );
}

export default ListAspirant;
