import * as Yup from "yup";

export const addSubjectSchema = Yup.object({
  name: Yup.string().required("Este campo es requerido."),
  subjectKey: Yup.string().required("Este campo es requerido."),
  shortName: Yup.string().required("Este campo es requerido."),
  accreditationClassification: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  academic_area: Yup.string().required("Selecciona una opción."),
  school_level: Yup.string().required("Selecciona una opción."),
  subject_type: Yup.string().required("Selecciona una opción."),
  checkExclusive: Yup.boolean(),
});
