import React from "react";
import { useHistory } from "react-router";

import styles from "../../assets/css/components/Button.module.css";
import classnames from "classnames";

function BtnNotFound({ secondary, ...rootDOMAttributes }) {
  const history = useHistory();

  const handleClick = () => {
    window.localStorage.clear();
    history.push("/");
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={
        secondary
          ? classnames(styles.btn, styles.btnSecondary)
          : classnames(styles.btn, styles.btnPrincipal)
      }
      {...rootDOMAttributes}
    >
      Ir al inicio
    </button>
  );
}

export default BtnNotFound;
