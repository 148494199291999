import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormConfigEnglish from "../forms/FormConfigEnglish";

function ConfigEnglish() {
  return (
    <DashboardAlt>
      <FormConfigEnglish />
    </DashboardAlt>
  );
}

export default ConfigEnglish;
