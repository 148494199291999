import React, { useEffect, useRef, useState } from "react";

import style from "assets/css/Forms/FormLoginAspirant.module.css";

import CardAspirant from "components/Card/CardAspirant";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputIcon from "components/Inputs/InputIcon";
import InputBtnAction from "components/Inputs/InputBtnAction";
import Loading from "../../../components/Loading";
import { error, info, warning } from "components/Alerts";

import { loginInitialState } from "utils/states/login";
import { loginSchema } from "utils/schemas/login.schemas";

import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import useLogin from "hooks/useLogin";
import { useLocalStorage } from "hooks/useLocalStorage";
import useReCaptcha from "hooks/useReCaptcha";
import useForgotPassword from "hooks/useForgotPassword";
import ConsoleHelper from "ConsoleHelper";
import { dashboards } from "../../../utils/rolesDashboard";
import axios from "axios";
import { error as errorAlert } from "../../../components/Alerts";

function FormLoginAspirant() {
  const { loadingLogin, loginUser } = useLogin();
  const [jwt, setJwt] = useLocalStorage("jwt", null);
  const [id, setId] = useLocalStorage("id", null);
  const [role, setRole] = useLocalStorage("role", null);
  const [permissions, setPermissions] = useLocalStorage("permissions", null);
  const { loadingForgot, forgotPassword } = useForgotPassword();
  const { getToken } = useReCaptcha();
  const history = useRef(useHistory());
  const [dateSolicitudes, setDateSolicitudes] = useState();

  const moment = require("moment");
  const today = moment();

  const currentDate = today.format("YYYY-MM-DD");

  useEffect(() => {
    if (jwt && id && role && permissions) {
      const resultado = dashboards.find((dash) => dash.role === role);
      if (resultado) {
        history.current.push(resultado.dashboard);
      } else {
        history.current.push("/personal/me");
      }
    }
    return () => {};
  });

  useEffect(() => {
    const fetchDateSolicitud = async () => {
      try {
        const { REACT_APP_REST_URI: uri } = process.env;
        const getPeriods = await axios.get(uri + "/setting-candidates-public");
        console.log(getPeriods.data);
        setDateSolicitudes(getPeriods.data);
      } catch (error) {
        errorAlert(
          "Error al obtener datos",
          "tenemos problemas con el servidor"
        );
      }
    };

    fetchDateSolicitud();
  }, []);

  if (loadingLogin || loadingForgot) {
    return <Loading />;
  }

  const handleSubmit = async (values) => {
    getToken();
    const { email, password } = values;
    const response = await loginUser({ identifier: email, password });
    if (response === "error") {
      error("Verificar", "Correo o contraseña incorrecta.");
    } else if (response === "error-server") {
      error(
        "Lo sentimos, esto no debió pasar",
        "Estamos trabajando para solucionarlo, intenta de nuevo más tarde"
      );
    } else {
      ConsoleHelper(response);
      setJwt(response?.jwt);
      setId(response?.id);
      setRole(response?.role?.name);
      setPermissions(JSON.stringify(response?.permissions));
      // const resultado = dashboards.find(
      //   (dash) => dash.role === response?.role?.name
      // );
      // console.log(resultado);
      // if (resultado) {
      //   history.push(resultado.dashboard);
      // } else {
      //   history.push("/personal/me");
      // }
    }
  };

  const handleForgotPassword = (values) => {
    if (values.email) {
      warning(
        "¿Estás seguro?",
        "Se te enviará un correo con un enlace para poder restablecer la contraseña del correo " +
          values.email,
        "Enviar",
        () => sendEmailForgotPassword(values.email)
      );
    }
  };

  const sendEmailForgotPassword = async (email) => {
    const response = await forgotPassword(email);
    if (response === "error") {
      error("Lo sentimos", "Ah ocurrido un error, intenta de nuevo más tarde");
    } else {
      info(
        "Ve a revisar tu correo",
        "Te hemos mandado un enlace a tu correo para restablecer tu contraseña, revisa tu bandeja de spam si no lo encuentras.",
        () => history.push("/")
      );
    }
  };

  return (
    <Formik
      initialValues={loginInitialState}
      validationSchema={loginSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { values } = props;
        return (
          <Form className={style.form}>
            {currentDate >= dateSolicitudes?.startDate &&
            currentDate <= dateSolicitudes?.finalDate ? (
              <CardAspirant />
            ) : (
              <div />
            )}

            <p className={style.title}>Inicio de sesión de aspirante</p>
            <InputIcon
              name="email"
              icon="fas fa-envelope"
              placeholder="Correo"
            />
            <InputBtnAction
              name="password"
              icon="fas fa-lock"
              password
              placeholder="Contraseña"
            />
            <div className={style.contAction}>
              <p
                className={style.descLog}
                onClick={() => handleForgotPassword(values)}
              >
                ¿Olvidaste tu contraseña?
              </p>
            </div>
            <BtnPrincipal text="Iniciar Sesión" />
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormLoginAspirant;
