import React from "react";
import style from "assets/css/Card/ClassroomCard.module.css";
import Map from "components/Map/Map";

function ClassroomCard({ position }) {
  return (
    <div className={style.card}>
      <div className={style.contMap}>
        <Map position={position} />
      </div>
      <div className={style.classroom}>
        <div className={style.contInfo}>
          <p className={style.name}>Aula V1</p>
          <p className={style.desc}>Capacidad · 35 alumnos</p>
          <p className={style.desc}>Actualizado julio 1, 2022</p>
        </div>
        <button className={style.btn}>
          <i className="fas fa-pen"></i>
        </button>
      </div>
    </div>
  );
}

export default ClassroomCard;
