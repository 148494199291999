import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useUpdateData() {
  const [loading, setLoading] = useState(false);

  const updateData = async (id, token, table, userData) => {
    try {
      setLoading(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const UPDATE_USER_URL =
        process.env.REACT_APP_REST_URI + table + id;
      const updateResponse = await axios.put(UPDATE_USER_URL, userData, {
        headers: { Authorization: BEARER_TOKEN },
      });
      ConsoleHelper(updateResponse?.data);
      return {
        idData: updateResponse?.data?.id,
      };
    } catch (e) {
      ConsoleHelper(e.response, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, updateData };
}

export default useUpdateData;
