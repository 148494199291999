import { useState } from "react";
import axios from "axios";

function useResetPassword() {
  const [loadingReset, setLoadingReset] = useState(false);

  const resetPassword = async (resetData) => {
    try {
      setLoadingReset(true);
      const RESET_URL = process.env.REACT_APP_REST_URI + "/auth/reset-password";
      const resetResponse = await axios.post(RESET_URL, resetData);
      return resetResponse?.data?.jwt;
    } catch (error) {
      return "error";
    } finally {
      setLoadingReset(false);
    }
  };

  return { loadingReset, resetPassword };
}

export default useResetPassword;
