import React from "react";
import { useQuery } from "@apollo/client";

import style from "assets/css/Forms/FormAlt.module.css";

// import { Form, Formik } from "formik";
// import BtnPrincipal from "components/Buttons/BtnPrincipal";
import DataTable from "./DataTable";
// import InputIcon from "components/Inputs/InputIcon";
import Loading from "components/Loading";
import { GET_SPECIALTIES_CAREER } from "utils/graphql/querys";
import ErrorData from "components/Error/ErrorData";
import LinkPage from "components/LinkPage";

function ListReticles() {
  const { data, loading, error } = useQuery(GET_SPECIALTIES_CAREER);

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const transformData = () => {
    let newData = [];
    if (data && data.reticles) {
      data.reticles.forEach((element) => {
        newData.push({
          name: element.career.shortName,
          secondary: element.specialties[0]?.name,
          createdAt: element.createdAt,
          updatedAt: element.updatedAt
        });
      });
    }
    return newData;
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.contTitle}>
          <h1 className={style.title}>Lista de reticulas</h1>
        </div>
        <LinkPage text="Crear especialidad" link="/personal/createReticle" />
      </div>
      <div className={style.contTable}>
        <DataTable
          name="Carrera"
          data={transformData()}
          secondary="Especialidad"
        />
      </div>
    </div>
  );
}

export default ListReticles;
