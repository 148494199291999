import React from "react";

import style from "assets/css/components/ItemOption.module.css";
import classnames from "classnames";

function ItemOption({ title, status, ...rootDOMAttributes }) {
  return (
    <div className={style.contOption}>
      <h1 className={classnames(style.title, style.titleItem)}>{title}</h1>
      <button className={status ? classnames(style.btn, style.btnActive) : style.btn} {...rootDOMAttributes}>
        <i className="fas fa-check"></i>
      </button>
    </div>
  );
}

export default ItemOption;
