import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import axios from "axios";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
// import InputDate from "components/Inputs/InputDate";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputBtn from "components/Inputs/InputBtn";

import { addDataGeneralSchema } from "utils/schemas/aspirant.schemas";
import validateRFC from "utils/validateRFC";
import { error } from "components/Alerts";
import Loading from "components/Loading";
import { GET_OPTIONS_FORMGRAL } from "utils/graphql/querys";

// Data selects
import { estadoCivil } from "utils/dataSelectsAspirant";
import { zonaProcedencia } from "utils/dataSelectsAspirant";
import { siNo } from "utils/dataSelectsAspirant";
import useUpdateUser from "hooks/useUpdateUser";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";

function FormGral({ setPosition, token, idUser, data }) {
  const {
    loading,
    error: errorQuery,
    data: dataOptions,
  } = useQuery(GET_OPTIONS_FORMGRAL);
  const { loadingUser, updateUser } = useUpdateUser();
  const [nationsOptions, setNationsOption] = useState(null);
  const [student, setStudent] = useLocalStorage("student", null);

  useEffect(() => {
    let isMounted = true;
    async function getCountries() {
      try {
        const COUNTRIES_URL = process.env.REACT_APP_COUNTRIES_URL;
        const countriesResponse = await axios.get(COUNTRIES_URL);
        //ConsoleHelper(countriesResponse?.data);
        let countries = [];
        await countriesResponse?.data.map((country, index) => {
          countries.push({
            value: index,
            label: country?.translations?.spa?.common,
          });
          return null;
        });
        if (isMounted) {
          setNationsOption(countries);
        }
      } catch (e) {
        ConsoleHelper(e);
      }
    }

    getCountries();

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading || loadingUser) return <Loading />;
  if (errorQuery) {
    error("Esto no debio pasar", "Lo sentimos no pudimos traer los datos.");
    return null;
  }

  const handleSubmit = async (values) => {
    if (values.RFC !== "" && !validateRFC(values.RFC)) {
      error("Verificar", "Ingresa un rfc válido");
    } else {
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const element = values[key];
          if (element === "") {
            values = {
              ...values,
              [key]: null,
            };
          }
        }
      }
      if (!values.nationality) {
        values = {
          ...values,
          nationality: "México",
        };
      }
      delete values?.app;
      delete values?.apm;
      delete values?.name;
      delete values?.email;
      delete values?.curp;
      values = {
        ...values,
        dialects: [values.dialects],
        disabilities: [values.disabilities],
      };
      ConsoleHelper(values);
      ConsoleHelper(student);
      const response = await updateUser(idUser, token, values);
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          setStudent(response.idStudent);
          setPosition(1);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={addDataGeneralSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(props) => {
        const { errors, handleChange, touched } = props;
        return (
          <Form>
            <Collapse title="Datos del aspirante">
              <div className={classnames(style.formDate, style.threeColumn)}>
                <Input
                  name="app"
                  title="Apellido paterno"
                  type="text"
                  disabled
                />
                <Input
                  name="apm"
                  title="Apellido materno"
                  type="text"
                  disabled
                />
                <Input name="name" title="Nombre" type="text" disabled />
                <Input
                  name="dateOfBirth"
                  title="Fecha de nacimiento"
                  type="text"
                  disabled
                />
                <Input name="gender" title="Género" type="text" disabled />
                <InputSelect
                  error={touched.nationality && errors.nationality}
                  name="nationality"
                  title="Nacionalidad"
                  defaultValue={{ label: "México", value: 136 }}
                  options={nationsOptions}
                  onChange={(selectedOption) => {
                    handleChange("nationality")(selectedOption.label);
                  }}
                />
                <Input name="curp" title="CURP" type="text" disabled />
                <InputBtn
                  name="RFC"
                  title="RFC"
                  type="text"
                  subtitle="Opcional"
                  iconBtn="fas fa-search"
                  link
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                  onChange={(e) => {
                    handleChange("RFC")(e.target.value.toUpperCase());
                  }}
                  href="https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp"
                />
              </div>
            </Collapse>
            <Collapse title="Datos importantes">
              <div className={classnames(style.formDate, style.threeColumn)}>
                <Input
                  name="email"
                  title="Correo electrónico"
                  type="text"
                  disabled
                />
                <Input
                  name="phone"
                  title="Teléfono celular"
                  type="tel"
                  maxLength={10}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                />
                <Input
                  name="phoneHouse"
                  title="Teléfono de casa"
                  subtitle="Opcional"
                  type="tel"
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                  maxLength={10}
                />
                <InputSelect
                  error={touched.maritalStatus && errors.maritalStatus}
                  name="maritalStatus"
                  title="Estado civil"
                  options={estadoCivil}
                  onChange={(selectedOption) => {
                    handleChange("maritalStatus")(selectedOption.value);
                  }}
                />
                <InputSelect
                  error={touched.disabilities && errors.disabilities}
                  name="disabilities"
                  title="Discapacidad"
                  options={dataOptions?.disabilities}
                  onChange={(selectedOption) => {
                    handleChange("disabilities")(selectedOption.value);
                  }}
                />
                <InputSelect
                  error={touched.areaOfOrigin && errors.areaOfOrigin}
                  name="areaOfOrigin"
                  title="Zona de procedencia"
                  options={zonaProcedencia}
                  onChange={(selectedOption) => {
                    handleChange("areaOfOrigin")(selectedOption.label);
                  }}
                />
                <InputSelect
                  error={touched.dialects && errors.dialects}
                  name="dialects"
                  title="Hablante de lengua indígena"
                  options={dataOptions?.dialects}
                  onChange={(selectedOption) => {
                    handleChange("dialects")(selectedOption.value);
                  }}
                />
                <InputSelect
                  error={touched.hasChildren && errors.hasChildren}
                  name="hasChildren"
                  title="¿Tiene hijos?"
                  options={siNo}
                  onChange={(selectedOption) => {
                    handleChange("hasChildren")(selectedOption.value);
                  }}
                />
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormGral;
