import { useState } from "react";
import axios from "axios";

function useForgotPassword() {
  const [loadingForgot, setLoadingForgot] = useState(false);

  const forgotPassword = async (email) => {
    try {
      setLoadingForgot(true);
      const FORGOT_URL =
        process.env.REACT_APP_REST_URI + "/auth/forgot-password";
      const forgotResponse = await axios.post(FORGOT_URL, { email });
      return forgotResponse?.data?.ok;
    } catch (error) {
      return "error";
    } finally {
      setLoadingForgot(false);
    }
  };

  return { loadingForgot, forgotPassword };
}

export default useForgotPassword;
