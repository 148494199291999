import React, { useState } from "react";
import style from "assets/css/components/SubjectSelect.module.css";
import classNames from "classnames";
import ButtonGroupSelect from "components/Buttons/ButtonGroupSelect";

function SubjectSelect({ data }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={
        open && data.status
          ? classNames(style.item, style.itemFull)
          : style.item
      }
    >
      <div className={style.head} onClick={() => setOpen(!open)}>
        <div className={style.imgSubject}>
          <img className={style.image} src={data.image} alt="" />
        </div>
        <div className={style.nameSubject}>
          <p className={style.title}>Nombre de la materia</p>
          <p className={style.desc}>{data.name}</p>
        </div>
        <div className={style.statusSubject}>
          <p
            className={
              data.status
                ? classNames(style.status, style.select)
                : classNames(style.status, style.noSelect)
            }
          >
            {data.status ? "Disponible" : "No disponible"}
          </p>
        </div>
      </div>
      <div className={style.selection}>
        <div className={style.contGroups}>
          <div className={style.contTitle}>
            <p className={style.title}>Grupos disponibles</p>
          </div>
          <div className={style.groups}>
            <ButtonGroupSelect group={data.nameShort} />
            <ButtonGroupSelect group={data.nameShort} />
            <ButtonGroupSelect group={data.nameShort} />
            <ButtonGroupSelect group={data.nameShort} />
            <ButtonGroupSelect group={data.nameShort} />
          </div>
        </div>
        <div className={style.infoClasroom}>
          <div className={style.contTitle}>
            <p className={style.title}>Información</p>
          </div>
          <div className={style.members}>
            <div className={style.teacher}>
              <p className={style.title}>Docente</p>
              <p className={style.name}>juan raúl</p>
              <p className={style.lastname}>martinez lópez</p>
            </div>
            <div className={style.students}>
              <p className={style.title}>Miembros del grupo</p>
              <div className={style.member}>
                <Profile />
                <Profile position="secondProfile" />
                <Profile position="threeProfile" />
                <div className={style.more}>
                  <p className={style.number}>+25</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.groupSchedule}>
          <table className={style.table}>
            <HeadTable />
            <tbody className={style.bodyTable}>
              <DataBody />
            </tbody>
          </table>
        </div>
        <div className={style.contButton}>
          <button className={style.btnAdd}>
            <i class="fas fa-plus"></i>Agregar al horario
          </button>
        </div>
      </div>
    </div>
  );
}

const Profile = ({ position }) => (
  <div
    className={
      position ? classNames(style.profile, style[position]) : style.profile
    }
  >
    <img
      className={style.image}
      src="https://images.unsplash.com/photo-1507114845806-0347f6150324?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      alt=""
    />
  </div>
);

const HeadTable = () => (
  <thead className={style.headTable}>
    <tr className={style.contColumn}>
      <th className={style.column}>lun</th>
      <th className={style.column}>mar</th>
      <th className={style.column}>mie</th>
      <th className={style.column}>jue</th>
      <th className={style.column}>vie</th>
    </tr>
  </thead>
);

const DataBody = ({ index, data }) => (
  <tr>
    <td>08:00 am</td>
    <td>08:00 am</td>
    <td>08:00 am</td>
    <td>08:00 am</td>
    <td>08:00 am</td>
  </tr>
);

export default SubjectSelect;
