import React, { useState } from "react";

import style from "assets/css/Dashboard/HomeStudent.module.css";
import CardSemesterGrade from "components/Card/student/CardSemesterGrade";
import CardClass from "components/Card/student/CardClass";
import { Link } from "react-router-dom";
import classNames from "classnames";
import LinkPage from "components/LinkPage";

function HomeStudent() {
  const [subjects, SetSubjects] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.contPrincipal}>
        <div className={style.welcome}>
          <h1 className={style.greet}>
            Hola, <span className={style.name}>Juan Raul</span> 👋
          </h1>
          <p className={style.desc}>
            Bienvenido de nuevo, un placer verte de nuevo
          </p>
        </div>
        <div className={style.semesters}>
          <CardSemesterGrade />
        </div>
        <div className={style.contClass}>
          <div className={style.contHead}>
            <p className={style.title}>Mis clases</p>
            <Link
              className={style.desclink}
              to={"/personal/listAspirant"}
              // target="_blank"
              rel="noopener noreferrer"
            >
              Ver todas mis clases
              <span>
                <i className="fas fa-chevron-right"></i>
              </span>
            </Link>
          </div>
          <div className={subjects ? style.class : style.addClass}>
            {subjects ? (
              <>
                <CardClass
                  image="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  subject="Machine Learning"
                  teacher="Alexis regalado"
                  photoTeacher="https://images.unsplash.com/photo-1507114845806-0347f6150324?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  group="8x"
                  credit={3}
                  hours={4}
                />
                <CardClass
                  image="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  subject="Machine Learning"
                  teacher="Alexis regalado"
                  photoTeacher="https://images.unsplash.com/photo-1507114845806-0347f6150324?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  group="8x"
                  credit={3}
                  hours={4}
                />
                <CardClass
                  image="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  subject="Machine Learning"
                  teacher="Alexis regalado"
                  photoTeacher="https://images.unsplash.com/photo-1507114845806-0347f6150324?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  group="8x"
                  credit={3}
                  hours={4}
                />
              </>
            ) : (
              <LinkPage text="Registrar materias" link="/select-subject" />
            )}
          </div>
        </div>
        <div className={style.otherClass}>
          <div className={style.contHead}>
            <p className={style.title}>Otras clases</p>
          </div>
          <div className={style.class}>
            <ItemClass
              title="Act. Extraescolar"
              desc="básquetbol"
              image="https://images.unsplash.com/photo-1519766304817-4f37bda74a26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              adviser="Alexis regalado"
              site="gimnasio auditorio"
              hour="8:00 AM - 10:00  AM"
            />
            <ItemClass
              title="Act. Complementaria"
              desc="áreas verdes"
              image="https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1913&q=80"
              adviser="Alexis regalado"
              site="gimnasio auditorio"
              hour="8:00 AM - 10:00  AM"
            />
            <ItemClass
              title="Tutoría"
              desc="ing. juan raul"
              image="https://images.unsplash.com/photo-1542626991-cbc4e32524cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
              adviser="Alexis regalado"
              site="gimnasio auditorio"
              hour="8:00 AM - 10:00  AM"
            />
            <ItemClass
              title="Lengua Extranjera"
              desc="inglés"
              image="https://images.unsplash.com/photo-1527012878741-0b725a6c006f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1433&q=80"
              adviser="Alexis regalado"
              site="gimnasio auditorio"
              hour="8:00 AM - 10:00  AM"
            />
          </div>
        </div>
      </div>
      <div className={style.contSecondary}></div>
    </div>
  );
}

const ItemClass = ({ title, desc, image, adviser, site, hour }) => (
  <div className={style.itemClass}>
    <img className={style.image} src={image} alt="" />
    <div className={style.dataClass}>
      <div className={style.contLevel}>
        <p className={style.titleLevel}>Nivel</p>
        <p className={style.level}>1</p>
      </div>
      <div className={style.contTitle}>
        <p className={style.title}>{title}</p>
        <p className={style.desc}>{desc}</p>
      </div>
    </div>
    <div className={style.infoClass}>
      <p className={style.desc}>{adviser}</p>
      <p className={style.desc}>{site}</p>
      <p className={style.desc}>{hour}</p>
    </div>
    <div className={style.contDays}>
      <p className={style.title}>Día de clases</p>
      <div className={style.days}>
        <ItemDay text="D" active={false} />
        <ItemDay text="L" active={true} />
        <ItemDay text="M" active={false} />
        <ItemDay text="M" active={false} />
        <ItemDay text="J" active={false} />
        <ItemDay text="V" active={true} />
        <ItemDay text="S" active={false} />
      </div>
    </div>
  </div>
);

const ItemDay = ({ text, active }) => (
  <div className={active ? classNames(style.day, style.active) : style.day}>
    <p className={style.desc}>{text}</p>
  </div>
);

export default HomeStudent;
