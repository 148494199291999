import * as Yup from "yup";

export const specialtiesInitialState = {
  key: "",
  name: "",
  reticle: "",
  startPeriod: "",
  liquidationPeriod: "",
  credits: ""
};

export const specialtiesValidationSchema = Yup.object({
  key: Yup.string().required("Este campo es requerido"),
  name: Yup.string().required("Este campo es requerido"),
  reticle: Yup.string().required("Este campo es requerido"),
  startPeriod: Yup.string().required("Este campo es requerido"),
  liquidationPeriod: Yup.string().required("Este campo es requerido"),
  credits: Yup.string().required("Este campo es requerido")
});
