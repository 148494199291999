import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import style from "assets/css/Forms/PageLogin.module.css";
import classnames from "classnames";

import TemplateUser from "./components/TemplateUser";
import iconMicrosoft from "assets/img/theme/microsoft.png";
import { useHistory } from "react-router";
import { useLocalStorage } from "hooks/useLocalStorage";

function PageLogin() {
  const history = useRef(useHistory());
  const [jwt] = useLocalStorage("jwt", null);
  const [id] = useLocalStorage("id", null);
  const [role] = useLocalStorage("role", null);
  const [permissions] = useLocalStorage("permissions", null);

  useEffect(() => {
    if (jwt && id && role && permissions) {
      if (role === "Aspirante") {
        history.current.push("/aspirant/me");
      } else if (role === "División de estudios profesionales") {
        history.current.push("/personal/study-division");
      } else if (role === "Estudiante") {
        history.current.push("/student/me");
      } else {
        history.current.push("/personal/me");
      }
    }
    return () => {};
  });

  return (
    <TemplateUser>
      <div className={style.login}>
        <p className={style.title}>Iniciar Sesión</p>
        <Link
          className={classnames(style.btnOpc, style.btnAspirant)}
          to="/login"
        >
          <span className={style.icon}>
            <i className="fas fa-user"></i>
          </span>
          Cuenta de aspirante
        </Link>
        <Link className={style.btnOpc} to="/">
          <img
            className={style.img}
            src={iconMicrosoft}
            alt="Inicio con correo institucional"
          />
          Correo institucional
        </Link>
      </div>
    </TemplateUser>
  );
}

export default PageLogin;
