import React from "react";
import useGetPermissions from "hooks/useGetPermissions";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormReticle from "../forms/FormReticle";
import Loading from "components/Loading";

function Reticle() {
  const { avaible } = useGetPermissions("reticles", [
    "find",
    "findone",
    "update",
    "create",
  ]);

  if (avaible === null) return <Loading />;

  return (
    <DashboardAlt>
      <FormReticle />
    </DashboardAlt>
  );
}

export default Reticle;
