import * as Yup from "yup";

export const newDegreeSchema = Yup.object({
  name: Yup.string().required("Este campo es requerido."),
  shortName: Yup.string()
    .max(8, "Solo se admiten 8 carácteres.")
    .required("Este campo es requerido."),
  abbreviation: Yup.string().required("Este campo es requerido."),
  careerKey: Yup.string().required("Este campo es requerido."),
  school_level: Yup.string().required("Selecciona una opción.")
});
