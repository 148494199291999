import { Route, Redirect } from "react-router";

import { useLocalStorage } from "hooks/useLocalStorage";

const AuthRoute = ({ children, ...rest }) => {
  const [auth] = useLocalStorage("jwt", null);

  return (
    <Route
      {...rest}
      render={() => (auth ? children : <Redirect to="/404" />)}
    />
  );
};

export default AuthRoute;
