import React from "react";

import style from "assets/css/Card/CardAspirant.module.css";
import { useHistory } from "react-router";

function CardAspirant() {
  const history = useHistory();
  return (
    <div className={style.card} onClick={() => history.push("/register")}>
      <div className={style.contIcon}>
        <p className={style.icon}>
          <i className="fa fa-bell" />
        </p>
      </div>
      <div className={style.info}>
        <p className={style.title}>Forma parte de la familia TecNM</p>
        <p className={style.text}>Registrate como aspirante aquí</p>
      </div>
    </div>
  );
}

export default CardAspirant;
