import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormSubjectType from "../forms/FormSubjectType";

function CreateSubjectType() {
  return (
    <DashboardAlt>
      <FormSubjectType />
    </DashboardAlt>
  );
}

export default CreateSubjectType;
