const parsedLabel = (item, labelField) => {
  return !Array.isArray(labelField)
    ? item[labelField]
    : labelField.map((label) => item[label]).join(" / ");
};

const generateSelectList = ({ valueField, labelField, list = [] }) => {
  try {
    const parsedList = list.map((item) => ({
      value: item[valueField],
      label: parsedLabel(item, labelField).toUpperCase(),
    }));
    return parsedList;
  } catch (error) {
    return [];
  }
};

export default generateSelectList;
