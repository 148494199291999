import React from "react";

import styles from "../../assets/css/components/InputSearch.module.css";

function InputSearch({ value, onChange, onClickSearch }) {
  return (
    <div className={styles.container}>
      <input
        type="text"
        className={styles.input}
        placeholder="Buscar"
        value={value}
        onChange={onChange}
      />
      <div className={styles.contBtn}>
        <button className={styles.btnSearch} onClick={onClickSearch}>
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
}

export default InputSearch;
