import axios from "./config";
import { LISTS_FOR_ADD_ENGLISH_CLASSES } from "utils/graphql/englishQueries";
import generateSelectList from "utils/generateSelectList";

export const getListsForAddEnglishClasses = async () => {
  try {
    const { data } = await axios.post("/graphql", {
      query: LISTS_FOR_ADD_ENGLISH_CLASSES,
    });
    if (data?.data) {
      const { englishLevels, englishPeriods, teachers } = data.data;
      const englishLevelsList = generateSelectList({
        valueField: "_id",
        labelField: "name",
        list: englishLevels,
      });
      const englishPeriodsList = generateSelectList({
        valueField: "_id",
        labelField: ["startDate", "endDate", "key"],
        list: englishPeriods,
      });
      const teachersList = generateSelectList({
        valueField: "_id",
        labelField: "key",
        list: teachers,
      });
      return { englishLevelsList, englishPeriodsList, teachersList };
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const addEnglishLevel = async (values) => {
  try {
    await axios.post("/english-classes", {
      ...values,
      active: true,
      year: values.year.split("-")[0],
      hour: values.hour + ":00",
      english_level: values.englishLevel,
      english_period: values.englishPeriod,
    });
    return true;
  } catch (error) {
    return null;
  }
};
