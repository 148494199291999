import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const warning = (title, text, confirmText, action) => {
  MySwal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmText,
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const error = (title, text, footer) => {
  MySwal.fire({
    icon: "error",
    title,
    text,
    footer,
  });
};

export const info = (title, text, action) => {
  MySwal.fire({
    icon: "info",
    title,
    text,
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEscapeKey: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Aceptar",
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const success = (title, text, textConfirm, action, enter) => {
  MySwal.fire({
    icon: "success",
    title,
    text,
    confirmButtonColor: "#3085d6",
    confirmButtonText: textConfirm,
    allowOutsideClick: enter,
    allowEnterKey: enter,
    allowEscapeKey: enter,
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};
