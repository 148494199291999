import React from "react";
// import { Player } from "@lottiefiles/react-lottie-player";

import style from "assets/css/PageNotFound.module.css";

import image from "assets/img/theme/tecNMblue.svg";
import BtnNotFound from "components/Buttons/BtnNotFound";

function PageNotFound() {
  return (
    <div className={style.container}>
      {/* <Player
        autoplay
        loop
        src="https://assets6.lottiefiles.com/datafiles/OzG1c5GtuAvq10U/data.json"
        style={{ height: "100%", width: "100%" }}
      /> */}
      <div className={style.contError}>
        <div className={style.error}>
          <div className={style.contDesc}>
            <h1 className={style.text}>4</h1>
          </div>
          <div className={style.contDesc}>
            <img className={style.image} src={image} alt="" />
          </div>
          <div className={style.contDesc}>
            <h1 className={style.text}>4</h1>
          </div>
        </div>
        <p className={style.desc}>Lo sentimos, página no encontrada</p>
      </div>
      <div className={style.contBtn}>
        {/* <p className={style.desc}>No es la página que buscabas</p> */}
        <BtnNotFound />
      </div>
    </div>
  );
}

export default PageNotFound;
