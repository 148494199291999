import React, { useState } from "react";
import moment from "moment";
import axios from "axios";

import style from "assets/css/Card/NoteAspirant.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { error } from "components/Alerts";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";

function NoteAspirant({
  id,
  view,
  title,
  note,
  document,
  type,
  date,
  refetch,
}) {
  const [open, setOpen] = useState(false);
  const [jwt] = useLocalStorage("jwt", null);

  const handleClickCard = async () => {
    setOpen(!open);
    if (!view) {
      try {
        const BEARER_TOKEN = `Bearer ${jwt}`;
        const UPDATE_NOTIFICATION_URL =
          process.env.REACT_APP_REST_URI + "/notifications/" + id;
        const notificationResponse = await axios.put(
          UPDATE_NOTIFICATION_URL,
          {
            read: true,
          },
          {
            headers: { Authorization: BEARER_TOKEN },
          }
        );
        ConsoleHelper(notificationResponse?.data);
        refetch();
      } catch (e) {
        ConsoleHelper(e.response, "error");
        error(
          "Lo sentimos",
          "Ocurrió un error al actualizar el estado de la notificación"
        );
      }
    }
  };

  const TypeNotification = () => {
    if (type === "observaciones") return <i className="far fa-eye"></i>;
    if (type === "file") return <i className="far fa-file-alt"></i>;
    if (type === "info") return <i className="far fa-envelope-open"></i>;
  };

  return (
    <div
      // className={
      //   open ? classNames(style.contCard, style.cardComplete) : style.contCard
      // }
      // className={
      //   view
      //     ? open
      //       ? classNames(style.contCard, style.cardComplete, style.viewNoti)
      //       : classNames(style.contCard, style.viewNoti)
      //     : classNames(style.contCard, style.noviewNoti)
      // }
      className={
        open
          ? classNames(style.contCard, style.cardComplete, view && style.viewNoti)
          : classNames(style.contCard, view ? null : style.noviewNoti)
      }
    >
      <div className={style.header} onClick={handleClickCard}>
        <div className={style.contIcon}>
          <p className={style.icon}>{TypeNotification()}</p>
        </div>
        <div className={style.contInfo}>
          <h1 className={style.title}>{title}</h1>
        </div>
      </div>
      <div className={style.infoNote}>
        <p className={style.desc}>{note}</p>
      </div>
      <div className={style.contDocument}>
        {document && (
          <Link
            to={{ pathname: document.file?.url }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={style.document}>
              <p className={style.title}>Descargar</p>
              <p className={style.nameDoc}>{document.type}</p>
            </div>
          </Link>
        )}
      </div>
      <div className={style.date}>
        <p className={style.desc}>
          {moment(new Date(date)).format("MMMM DD, YYYY - h:mm a")}
        </p>
      </div>
    </div>
  );
}

export default NoteAspirant;
