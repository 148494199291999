import React from "react";

import style from "assets/css/components/Toggle.module.css";
import { useField } from "formik";

function Toggle({ invert, showError, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className={style.contSwitch}>
      <label className={style.switch}>
        <input {...field} {...props} type="checkbox" />
        <span className={style.sliderround}>{props.value ? "Si" : "No"}</span>
      </label>
      {showError && meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default Toggle;
