import React from "react";

import style from "assets/css/Login/Login.module.css";

import image from "assets/img/theme/tecNMWhite.svg";
import { Link } from "react-router-dom";

function TemplateUser({children}) {
  return (
    <div className={style.container}>
      <div className={style.contImage}>
        <img className={style.image} src={image} alt="" />
      </div>
      <div className={style.contForm}>
        <div className={style.contTitle}>
          <p className={style.title}>Tecnológico Nacional de México</p>
          <p className={style.desc}>Campus Istmo</p>
        </div>
        <div className={style.form}>{children}</div>
        <div className={style.contCaptcha}>
          <p className={style.descCaptcha}>
            This site is protected by reCAPTCHA and the Google{" "}
            <Link
              className={style.desclink}
              to={{ pathname: "https://policies.google.com/privacy" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              className={style.desclink}
              to={{ pathname: "https://policies.google.com/terms" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>{" "}
            apply.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TemplateUser;
