import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";

import useCropper from "hooks/useCropper";

import styles from "assets/css/components/Dropzone.module.css";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "components/modal/Modal";
import BtnPrincipal from "./Buttons/BtnPrincipal";

function fileSizeValidator(file) {
  const maxLength = 600000;
  if (file.size > maxLength) {
    return {
      code: "file-too-large",
      message: `El archivo debe ser menor o igual a 600 Kb`,
    };
  }

  return null;
}

const modalStates = { imageCut: false };

function Dropzone(props) {
  const [activeModals, setActiveModals] = useState(modalStates);

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  const { type, setFiles, src, setSrc } = props;
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1 / 1.2,
  });
  const [image, setImage] = useState(null);
  const { result, setResult, getCroppedImg } = useCropper();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: type || ".pdf",
      validator: fileSizeValidator,
      onDropAccepted: () => {
        if (type === "image/jpeg") {
          setActiveModals({ ...activeModals, imageCut: true });
        }
      },
      onDrop: (acceptedFiles) => {
        if (type === "image/jpeg") {
          setSrc(acceptedFiles);
          setResult(null);
          setPreview(acceptedFiles.map((file) => URL.createObjectURL(file)));
          changeModalState("imageCut");
        } else {
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      },
    });

  const filesAccept = acceptedFiles.map((file, i) => (
    <div className={styles.contPreview} key={file.path}>
      {type === "image/jpeg" && result ? (
        <div className={styles.contFile}>
          <div className={styles.iconFile}>
            <img className={styles.preview} src={result} alt="Imagen preview" />
          </div>
          <div className={styles.contInfo}>
            <p className={styles.nameFile}>{src.name}</p>
            <p className={styles.sizeFile}>{src.size} bytes</p>
          </div>
        </div>
      ) : (
        <IconFile type={file.type} file={file} icon="far fa-file-pdf" />
      )}
      <div className={styles.contBtn}>
        {props.crop && (
          <button
            className={styles.btnCut}
            type="button"
            onClick={changeModalState("imageCut")}
          >
            <i className="fas fa-crop-alt"></i>
          </button>
        )}
        <button
          className={styles.btnDelete}
          onClick={(e) => {
            e.preventDefault();
            if (type === "image/jpeg") {
              setSrc([]);
              setPreview([]);
              setResult(null);
            } else {
              setFiles([]);
            }
            remove(i);
          }}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  ));

  const remove = (file) => {
    acceptedFiles.splice(file, 1);
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className={styles.contPreview} key={file.path}>
      <IconFile type={type} file={file} icon="far fa-file-excel" />
      <div className={styles.contText}>
        {errors.map((e) => (
          <p className={styles.txtError} key={e.code}>
            {e.message.includes("File type")
              ? `El archivo es diferente de ${
                  type === "image/jpeg" ? ".jpg/.jpeg" : ".pdf"
                }`
              : e.message}
          </p>
        ))}
      </div>
    </div>
  ));

  return (
    <>
      <div className={styles.container} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className={styles.icon}>
          {type === "image/jpeg" ? (
            <i className="far fa-image"></i>
          ) : (
            <i className="far fa-file-pdf"></i>
          )}
        </p>
        <p className={styles.text}>
          Clic para seleccionar o suelta tu archivo aquí.
        </p>
        <p className={styles.desc}>
          Solo se aceptan archivos{" "}
          {type === "image/jpeg" ? ".jpg / .jpeg" : ".pdf"}
        </p>
      </div>
      <aside>
        {filesAccept.length > 0 && <ul>{filesAccept}</ul>}
        {fileRejectionItems}
      </aside>

      <Modal
        title="Recortar fotografía"
        show={activeModals.imageCut}
        close={result && changeModalState("imageCut")}
      >
        <div className={styles.contModal}>
          <ReactCrop
            src={preview}
            onImageLoaded={setImage}
            crop={crop}
            onChange={setCrop}
            className={styles.contImage}
            imageStyle={{ width: "100%", height: "25rem" }}
          />
          <BtnPrincipal
            type="button"
            text="Recortar"
            onClick={(e) => {
              getCroppedImg(e, image, crop, setSrc);
              setActiveModals({
                ...activeModals,
                imageCut: !activeModals["imageCut"],
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
}

const IconFile = ({ type, file, icon }) => (
  <div className={styles.contFile}>
    <div className={styles.iconFile}>
      <p className={styles.icon}>
        <i className={type === "image/jpeg" ? "far fa-file-image" : icon}></i>
      </p>
    </div>
    <div className={styles.contInfo}>
      <p className={styles.nameFile}>{file.path}</p>
      <p className={styles.sizeFile}>{file.size} bytes</p>
    </div>
  </div>
);

export default Dropzone;
