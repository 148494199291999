import React from "react";

import style from "assets/css/Card/CardGroup.module.css";
import classNames from "classnames";

function CardGroup({ ...rootDOMAttributes }) {
  

  return (
    <div className={style.card} {...rootDOMAttributes}>
      <div className={style.contDate}>
        <div className={classNames(style.day, style.contDesc)}>
          <p className={style.title}>Día</p>
          <p className={style.desc}>{rootDOMAttributes?.data?.day}</p>
        </div>
        <div className={classNames(style.classroom, style.contDesc)}>
          <p className={style.title}>Aula</p>
          <p className={style.desc}>
            {rootDOMAttributes?.data?.classroomLabel
              ? rootDOMAttributes?.data?.classroomLabel
              : "sin asignar"}
          </p>
        </div>
      </div>
      <div className={style.contHours}>
        <p className={style.txtTitle}>Horario</p>
        <div className={style.hours}>
          <div className={classNames(style.hrStart, style.contDesc)}>
            <p className={style.title}>Hora Inicial</p>
            <p className={style.desc}>
              {rootDOMAttributes?.data?.hourStart
                ? rootDOMAttributes?.data?.hourStart
                : "sin asignar"}
            </p>
          </div>
          <div className={classNames(style.hrEnd, style.contDesc)}>
            <p className={style.title}>Hora Final</p>
            <p className={style.desc}>
              {rootDOMAttributes?.data?.hourEnd
                ? rootDOMAttributes?.data?.hourEnd
                : "sin asignar"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardGroup;
