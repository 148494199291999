import React from "react";
import styles from "./styles.module.css";

const Aspirant = () => {
  return (
    <tr className={styles.container}>
      <td>
        <div className={styles.id} />
      </td>
      <td>
        <div className={styles.name} />
      </td>
      <td>
        <div className={styles.career} />
      </td>
      <td>
        <div className={styles.status} />
      </td>
      <td>
        <div className={styles.birthDate} />
      </td>
      <td>
        <div className={styles.genre} />
      </td>
    </tr>
  );
};

export default Aspirant;
