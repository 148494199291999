import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormNewDegree from "../forms/FormNewDegree";

function NewDegree() {
  return (
    <DashboardAlt>
      <FormNewDegree />
    </DashboardAlt>
  );
}

export default NewDegree;
