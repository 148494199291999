import React from "react";

import style from "assets/css/components/InputCheckTable.module.css";

function InputCheckTable({ name, value, onChange, checked, defaultChecked }) {
  return (
    <label className={style.contInput}>
      <input
        className={style.input}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <span className={style.checkmark}>
        <p className={style.icon}>
          <i className="fas fa-check" />
        </p>
      </span>
    </label>
  );
}

export default InputCheckTable;
