import React from "react";

import style from "assets/css/Charts/Charts.module.css";
import ReactApexChart from "react-apexcharts";

function ChartSemester() {
  const series = [
    {
      name: "Promedio",
      data: [
        {
          x: "Sem 1",
          y: 80,
        },
        {
          x: "Sem 2",
          y: 90,
        },
        {
          x: "Sem 3",
          y: 95,
        },
        {
          x: "Sem 4",
          y: 91,
        },
        {
          x: "Sem 5",
          y: 89,
        },
        {
          x: "Sem 6",
          y: 90,
        },
        {
          x: "Sem 7",
          y: 85,
        },
        {
          x: "Sem 8",
          y: 87,
        },
        {
          x: "Sem 9",
          y: 85,
        },
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Promedios semestrales",
      align: "left",
      offsetY: 16,
      floating: false,
      style: {
        fontSize: "0.9rem",
        fontWeight: "bold",
        fontFamily:  "Arial, Helvetica, sans-serif",
        color: "#1b396a",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 13,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      colors: ["#ff374b"],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#bbc3ec",
          fontSize: ".8rem",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#bbc3ec",
          fontSize: ".8rem",
        },
      },
    },
    tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands";
      //     },
      //   },
    },
  };

  return (
    <>
      <ReactApexChart
        className={style.chart}
        options={options}
        series={series}
        type="bar"
        width={"100%"}
        height={"100%"}
      />
    </>
  );
}

export default ChartSemester;
