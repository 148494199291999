import React from "react";

import styles from "../../assets/css/components/Input.module.css";
import Select from "react-select";
import SimpleValue from "../SelectSimpleValue";
import classnames from "classnames";

const optionsDefault = [
  { value: "1", label: "10" },
  { value: "2", label: "25" },
  { value: "3", label: "50" },
];

const customStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "#fff",

      color: "#000",

      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "#fff",
      },
    };
  },

  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),

  placeholder: (styles) => {
    return {
      ...styles,
      color: "#c0c0c0",
      fontSize: ".9rem",
    };
  },

  control: () => ({
    // none of react-select's styles are passed to <Control />
    // backgroundColor: "#f00",
    width: "90px",
    height: "40px",
    display: "flex",
    flexdirection: "row",
    borderRadius: "0.3rem",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },

  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#f8f9fe",
      borderRadius: "15px",
      padding: "0px 4px",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#1b396a",
    fontSize: ".8rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1b396a",
    fontSize: ".8rem",
    ":hover": {
      color: "#fd4a4a",
    },
  }),
};

function Filter({
  title,
  subtitle,
  value,
  options,
  multiple,
  onChange,
  ...props
}) {
  return (
    <div className={styles.container}>
      <div className={classnames(styles.input, styles.contFilter)}>
        <SimpleValue options={options ? options : optionsDefault} value={value}>
          {(simpleProps) => (
            <Select
              {...props}
              {...simpleProps}
              placeholder="Filtrar"
              styles={customStyles}
              isMulti={multiple}
              noOptionsMessage={() => "No se encontraron opciones"}
              onChange={onChange}
            />
          )}
        </SimpleValue>
      </div>
    </div>
  );
}

export default Filter;
