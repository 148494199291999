import React from "react";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router";

import style from "assets/css/Forms/FormLoginAspirant.module.css";

import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputBtnAction from "components/Inputs/InputBtnAction";

import { passwordInitialState } from "utils/states/password";
import { passwordSchema } from "utils/schemas/password.schemas";
import { error, warning, info, success } from "components/Alerts";
import useVerifyAccount from "hooks/useVerifyAccount";
import useResetPassword from "hooks/useResetPassword";
import Loading from "components/Loading";

function FormSettingPassword() {
  const params = useParams();
  const history = useHistory();
  const { loadingVerify, verify, sendEmailConfirmation } = useVerifyAccount();
  const { loadingReset, resetPassword } = useResetPassword();

  if (loadingVerify || loadingReset) return <Loading />;

  const handleSubmit = async (values) => {
    const { password, confirmPassword } = values;
    if (password.length >= 12 || confirmPassword.length >= 12) {
      if (password === confirmPassword) {
        if (params.email) {
          verifyAccount(password);
        } else {
          resetPasswordUser(values);
        }
      } else {
        error("Verificar", "Las contraseñas no coinciden");
      }
    } else {
      error("Verificar", "La contraseña debe tener minimo 12 carácteres");
    }
  };

  const resetPasswordUser = async (values) => {
    const { password, confirmPassword } = values;
    const response = await resetPassword({
      code: params.code,
      password,
      passwordConfirmation: confirmPassword,
    });
    if (response === "error") {
      error("Lo sentimos", "Hubo un error al intentar cambiar la contraseña");
    } else {
      success(
        "Nueva contraseña lista",
        "Se actualizó tu contraseña correctamente, ahora ve a iniciar sesión",
        "Aceptar",
        () => history.push("/login")
      );
    }
  };

  const verifyAccount = async (password) => {
    const response = await verify({ code: params.code, newPassword: password });
    if (response === "error") {
      warning(
        "Este codigo ya no es válido",
        "Este codigo ha expirado, te enviaremos un nuevo link para continuar",
        "Volver a enviar",
        () => reSendEmail()
      );
    } else {
      success(
        "Verificación exitosa",
        "Has confirmado tu cuenta correctamente, ahora ve a iniciar sesión",
        "Aceptar",
        () => history.push("/login")
      );
    }
  };

  const reSendEmail = async () => {
    const response = await sendEmailConfirmation({ email: params.email });
    if (response === "error" || !response) {
      error("Lo sentimos", "Ah ocurrido un error, intenta de nuevo más tarde");
    } else if (response === "confirmado") {
      warning(
        "Ya confirmado",
        "Este correo ya se encuentra confirmado, inicia sesión",
        "Aceptar",
        () => history.push("/login")
      );
    } else {
      info(
        "Ve a revisar tu correo",
        "Te hemos mandado un enlace a tu correo para confirmar tu cuenta, revisa tu bandeja de spam si no lo encuentras.",
        () => history.push("/")
      );
    }
  };

  return (
    <Formik
      initialValues={passwordInitialState}
      validationSchema={passwordSchema}
      onSubmit={handleSubmit}
    >
      <Form className={style.form}>
        <p className={style.title}>Introduce tu nueva contraseña</p>
        <InputBtnAction
          name="password"
          icon="fas fa-lock"
          password
          placeholder="Nueva contraseña"
        />
        <InputBtnAction
          name="confirmPassword"
          icon="fas fa-lock"
          password
          placeholder="Confirmar contraseña"
          onPaste={(e) => e.preventDefault()}
          autoComplete="new-password"
        />
        <BtnPrincipal text="Guardar" />
      </Form>
    </Formik>
  );
}

export default FormSettingPassword;
