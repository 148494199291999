import React, {useEffect, useRef} from "react";

import style from "assets/css/Navbars/NavAspirant.module.css";

function NavAspirant({ position, children }) {
  const mystyle = {
    transition: "all .5s ease",
    transform: "translateX(calc((8rem *" + position + ") + 1.5rem))",
  };

  const containerRef = useRef();

  useEffect(() => {
    containerRef.current.scrollLeft = 128 * position;
  }, [position]);

  return (
    <div className={style.contNav}>
      <div ref={containerRef} className={style.contOption}>
        {children}
        <div className={style.contSelect} style={mystyle}></div>
      </div>
    </div>
  );
}

export default NavAspirant;
