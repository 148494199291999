import React from "react";
import styles from "../../assets/css/components/SimpleToggle.module.css";

function SimpleToggle({ onClick = () => {}, status = false }) {
  return (
    <div onClick={onClick} className={styles.Toggle}>
      <div
        className={styles.Toggle__Circle}
        style={status ? { transform: "translateX(25px)" } : {}}
      />
    </div>
  );
}

export default SimpleToggle;
