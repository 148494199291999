import React from "react";
import style from "assets/css/tables/ScheduleStudent.module.css";
import classNames from "classnames";

function ScheduleStudent() {
  return (
    <div className={style.container}>
      <div className={style.contTable}>
        <table className={style.table}>
          <thead className={style.headTable}>
            <tr className={style.contColumn}>
              <th
                className={classNames(style.column, style.columnSubject)}
              ></th>
              <th className={style.column}></th>
              <th className={style.column}>lun</th>
              <th className={style.column}>mar</th>
              <th className={style.column}>mie</th>
              <th className={style.column}>jue</th>
              <th className={style.column}>vie</th>
              <th className={style.column}></th>
            </tr>
          </thead>
          <tbody className={style.bodyTable}>
            <Subject />
            <Subject />
            <Subject />
            <Subject />
            <Subject />
            <Subject />
            <Subject />
            <Subject />
          </tbody>
        </table>
      </div>
      <div className={style.contButton}>
        <button className={style.btnSave}>Guardar horario</button>
      </div>
    </div>
  );
}

const Subject = () => (
  <tr className={style.contColumn}>
    <td className={style.column}>
      <div className={style.infoGroup}>
        <div className={style.contImage}>
          <img
            className={style.image}
            src="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt=""
          />
        </div>
        <div className={style.contName}>
          <p className={style.name}>nombre de la materia</p>
          <p className={style.teacher}>nombre del docente</p>
        </div>
      </div>
    </td>
    <td className={style.column}>
      <p className={style.group}>X2</p>
    </td>
    <ItemDay hour="08:00 am" classroom="v1" />
    <ItemDay hour="08:00 am" classroom="v1" />
    <ItemDay hour="08:00 am" classroom="v1" />
    <ItemDay hour="08:00 am" classroom="v1" />
    <ItemDay hour="08:00 am" classroom="v1" />
    <td className={style.column}>
      <button className={style.btnDelete}>
        <i className="fas fa-times" />
      </button>
    </td>
  </tr>
);

const ItemDay = ({ hour, classroom }) => (
  <td className={style.column}>
    <div className={style.day}>
      <p className={style.hour}>{hour}</p>
      <span className={style.classroom}>{classroom}</span>
    </div>
  </td>
);

export default ScheduleStudent;
