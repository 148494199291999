import * as Yup from "yup";

export const configAdmisionSchema = Yup.object({
  startDate: Yup.date().required("Debes seleccionar una fecha."),
  endDate: Yup.date().required("Debes seleccionar una fecha."),
  testDate: Yup.date().required("Debes seleccionar una fecha."),
  testTime: Yup.string()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Debes seleccionar una hora"
    )
    .required("Debes seleccionar una hora."),
  testType: Yup.string().required("Selecciona una opción."),
  testMode: Yup.string().required("Selecciona una opción."),
  testUrl: Yup.string().required("Este campo es requerido."),
  urlGuiaExam: Yup.string().required("Este campo es requerido."),
  note: Yup.string(),
  bankPay: Yup.string().required("Este campo es requerido."),
  bankAccount: Yup.string().required("Este campo es requerido."),
  testCost: Yup.number()
    .min(1, "Debe tener algún costo.")
    .positive("Este campo solo admite números positivos.")
    .required("Este campo es requerido."),
  payUrl: Yup.string().required("Este campo es requerido."),
  userTelegram: Yup.string().required("Este campo es requerido."),
  linkSII: Yup.string().required("Este campo es requerido."),
  nameJefe: Yup.string().required("Este campo es requerido."),
  gradoJefe: Yup.string().required("Este campo es requerido."),
  emailJefe: Yup.string().required("Este campo es requerido."),
});
