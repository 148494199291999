import React from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputToggle from "components/Inputs/InputToggle";
import { economyInitialState } from "utils/states/aspirant";
import { addEconomySchema } from "utils/schemas/aspirant.schemas";
import { catalogoFamilia } from "utils/dataSelectsAspirant";
import { casaVives } from "utils/dataSelectsAspirant";
import { catalogosNumerosPersonas } from "utils/dataSelectsAspirant";
import useAddEconomicData from "hooks/useAddEconomicData";
import Loading from "components/Loading";
import { error } from "components/Alerts";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";

function FormEconomy({ setPosition, idStudent, token }) {
  const { loadingEconomic, addEconomic } = useAddEconomicData();
  const [student] = useLocalStorage("student", null);

  if (loadingEconomic) return <Loading />;

  const handleSubmit = async (values) => {
    let dataLive = [];
    let dataDepend = [];
    let newValues = values;
    values.currentlyLiveWith.forEach((option) => {
      dataLive.push(option.value);
    });
    values.dependsEconomically.forEach((option) => {
      dataDepend.push(option.value);
    });
    newValues = {
      ...newValues,
      currentlyLiveWith: { lives: dataLive },
      dependsEconomically: { depend: dataDepend },
      student: idStudent ? idStudent : student,
    };
    values.working && delete newValues.work;
    ConsoleHelper(newValues);
    const response = await addEconomic(token, newValues);
    if (response) {
      if (response === "error") {
        error(
          "Lo sentimos",
          "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
        );
      } else {
        setPosition(6);
      }
    }
  };

  return (
    <Formik
      initialValues={economyInitialState}
      validationSchema={addEconomySchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched, setFieldValue, values } = props;

        return (
          <Form>
            <Collapse title="Datos economicos">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <InputSelect
                  error={touched.currentlyLiveWith && errors.currentlyLiveWith}
                  name="currentlyLiveWith"
                  onChange={setFieldValue}
                  title="¿Con quien vives actualmente?"
                  options={catalogoFamilia}
                  subtitle="Puedes seleccionar multiples opciones"
                  multiple
                />
                <InputSelect
                  error={
                    touched.dependsEconomically && errors.dependsEconomically
                  }
                  name="dependsEconomically"
                  onChange={setFieldValue}
                  title="¿De quien dependes económicamente?"
                  options={catalogoFamilia}
                  subtitle="Puedes seleccionar multiples opciones"
                  multiple
                />
                <InputSelect
                  error={touched.typeHouse && errors.typeHouse}
                  name="typeHouse"
                  title="¿La casa donde vives es?"
                  options={casaVives}
                  onChange={(selectedOption) => {
                    handleChange("typeHouse")(selectedOption.value);
                  }}
                />
                <InputSelect
                  error={touched.countRooms && errors.countRooms}
                  name="countRooms"
                  title="¿Cuántos cuartos tiene la casa?"
                  options={catalogosNumerosPersonas}
                  subtitle="Sin contar baños ni pasillos"
                  onChange={(selectedOption) => {
                    handleChange("countRooms")(selectedOption.label);
                  }}
                />
                <InputSelect
                  error={touched.countPeopleLiving && errors.countPeopleLiving}
                  name="countPeopleLiving"
                  title="¿Cuántas personsas viven en la casa?"
                  options={catalogosNumerosPersonas}
                  onChange={(selectedOption) => {
                    handleChange("countPeopleLiving")(selectedOption.label);
                  }}
                />
                <InputSelect
                  error={
                    touched.countPeopleDependentEconomically &&
                    errors.countPeopleDependentEconomically
                  }
                  name="countPeopleDependentEconomically"
                  title="¿Incluyéndote, cuantos dependen económicamente del principal apoyo?"
                  options={catalogosNumerosPersonas}
                  onChange={(selectedOption) => {
                    handleChange("countPeopleDependentEconomically")(
                      selectedOption.label
                    );
                  }}
                />
                <InputToggle
                  nameToggle="working"
                  name="work"
                  valueToggle={values.working}
                  onChangeToggle={(e) => {
                    handleChange("working")(e);
                    setFieldValue("work", values.working ? "" : "NO", false);
                  }}
                  title="¿Trabajas?"
                  placeholder="Especificar empleo"
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="off"
                />
                <Input
                  name="monthlyIncome"
                  title="Ingresos familiares mensuales"
                  type="number"
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="off"
                />
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormEconomy;
