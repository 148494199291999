import React from "react";

import style from "assets/css/Card/CardChartData.module.css";
import ChartStudentsCareer from "components/Charts/ChartStudentsCareer";

function CardSStudentCareer({ data }) {
  return (
    <>
      <div className={style.chart}>
        <ChartStudentsCareer />
      </div>
      <div className={style.summary}>
        <div className={style.contTitle}>
          <p className={style.title}>Resumen</p>
        </div>
        <div className={style.contData}>
          <ItemData
            icon="fas fa-user"
            name="primary"
            data={data.estudiantes}
            desc="Total actual"
          />
          <ItemData
            icon="fas fa-users"
            name="secondary"
            data="1500"
            desc="Expectativa"
          />
          <ItemData
            icon="fas fa-home"
            name="tertiary"
            data={data.aulas}
            desc="Aulas disp."
          />
        </div>
      </div>
    </>
  );
}

const ItemData = ({ icon, name, data, desc }) => (
  <div className={style.item}>
    <div className={style.iconItem}>
      <p className={style[name]}>
        <i className={icon}></i>
      </p>
    </div>
    <div className={style.contData}>
      <p className={style.txtdata}>{data}</p>
      <p className={style.desc}>{desc}</p>
    </div>
  </div>
);

export default CardSStudentCareer;
