import React from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import InputSelect from "components/Inputs/InputSelect";
import Input from "components/Inputs/Input";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import { error } from "components/Alerts";

import { careerSelectionInitialState } from "utils/states/aspirant";
import { addCareerSelectionSchema } from "utils/schemas/aspirant.schemas";
import { useQuery } from "@apollo/client";
import { GET_CARRERS } from "utils/graphql/querys";
import Loading from "components/Loading";
import useAddUserCareer from "hooks/useAddUserCareer";
import TextArea from "components/Inputs/TextArea";
import { medios } from "utils/dataSelectsAspirant";
import { siNo } from "utils/dataSelectsAspirant";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";

function FormDegree({ setPosition, idStudent, token, idUser }) {
  const { loading, error: errorQuery, data } = useQuery(GET_CARRERS);
  const { loadingCareer, addUserCareer } = useAddUserCareer();
  const [student] = useLocalStorage("student", null);
  if (loading || loadingCareer) return <Loading />;
  if (errorQuery)
    return error(
      "Esto no debio pasar",
      "Lo sentimos no pudimos traer los datos."
    );

  const handleSubmit = async (values) => {
    const { optionOne, optionTwo } = values;
    if (optionOne === optionTwo) {
      error(
        "No puedes escoger la misma carrera",
        "Selecciona alguna de las demás carreras disponibles"
      );
    } else {
      ConsoleHelper(values);
      const careerData = [
        {
          firstOption: true,
          career: optionOne,
          student: idStudent ? idStudent : student,
        },
        {
          firstOption: false,
          career: optionTwo,
          student: idStudent ? idStudent : student,
        },
      ];
      const questionsData = [
        {
          question: "¿Por qué medio se entero de nosotros?",
          response: {
            data:
              values.found === "Otro medio"
                ? values.especifyFound
                : values.found,
          },
        },
        {
          question: "¿Has visto o escuchado algún anuncio del instituto?",
          response: {
            data: values.advertisement,
          },
        },
        {
          question:
            "¿La información presentada en el anuncio le fue de utilidad?",
          response: {
            data: values.utility,
          },
        },
        {
          question:
            "¿La información presentada lo motivo a ser parte del Instituto Tecnológico del Istmo?",
          response: {
            data: values.information,
          },
        },
        {
          question: "Ayúdanos a mejorar, comparte con nosotros tus comentarios",
          response: {
            data: values.comments,
          },
        },
      ];
      ConsoleHelper(questionsData);
      const response = await addUserCareer(
        idUser,
        token,
        careerData,
        questionsData
      );
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          setPosition(2);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={careerSelectionInitialState}
      validationSchema={addCareerSelectionSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched, values, setFieldValue } = props;
        return (
          <Form>
            <Collapse title="Carreras de preferencia">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <InputSelect
                  error={touched.optionOne && errors.optionOne}
                  name="optionOne"
                  title="Opción 1"
                  options={data?.careers}
                  onChange={(selectedOption) => {
                    handleChange("optionOne")(selectedOption.value);
                  }}
                />
                <InputSelect
                  error={touched.optionTwo && errors.optionTwo}
                  name="optionTwo"
                  title="Opción 2"
                  options={data?.careers}
                  onChange={(selectedOption) => {
                    handleChange("optionTwo")(selectedOption.value);
                  }}
                />
              </div>
            </Collapse>
            <Collapse title="Encuesta de promoción a nuevo ingreso 2022">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <InputSelect
                  error={touched.found && errors.found}
                  name="found"
                  title="¿Por qué medio se entero de nosotros?"
                  options={medios}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      "especifyFound",
                      selectedOption.value === "8" ? "" : "No es otro",
                      false
                    );
                    handleChange("found")(selectedOption.label);
                  }}
                />
                {values.found === "Otro medio" && (
                  <Input
                    name="especifyFound"
                    title="Especifica el medio"
                    type="text"
                  />
                )}
                <InputSelect
                  error={touched.advertisement && errors.advertisement}
                  name="advertisement"
                  title="¿Has visto o escuchado algún anuncio del instituto?"
                  options={siNo}
                  onChange={(selectedOption) => {
                    handleChange("advertisement")(selectedOption.label);
                  }}
                />
                <InputSelect
                  error={touched.utility && errors.utility}
                  name="utility"
                  title="¿La información presentada en el anuncio le fue de utilidad?"
                  options={siNo}
                  onChange={(selectedOption) => {
                    handleChange("utility")(selectedOption.label);
                  }}
                />
                <InputSelect
                  error={touched.information && errors.information}
                  name="information"
                  title="¿La información presentada lo motivo a ser parte del Instituto Tecnológico del Istmo?"
                  options={siNo}
                  onChange={(selectedOption) => {
                    handleChange("information")(selectedOption.label);
                  }}
                />
                <div className={style.inputLong}>
                  <TextArea
                    name="comments"
                    title="Ayúdanos a mejorar, comparte con nosotros tus comentarios"
                  />
                </div>
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormDegree;
