import React from "react";

import style from "assets/css/components/InputToggle.module.css";
import Toggle from "views/pages/components/Toggle";
import { useField } from "formik";

function InputToggle({
  onChangeToggle,
  nameToggle,
  valueToggle,
  title,
  subtitle,
  type,
  placeholder,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={style.container}>
      <p className={style.title}>
        {title}
        {subtitle ? <span className={style.subtitle}>({subtitle})</span> : null}
      </p>
      <div className={style.toggleInput}>
        <div className={style.contToggle}>
          <Toggle
            name={nameToggle}
            onChange={onChangeToggle}
            value={valueToggle}
          />
        </div>
        <input
          {...field}
          {...props}
          disabled={valueToggle}
          className={style.input}
          type={type}
          placeholder={placeholder ? placeholder : "Escribir"}
        />
      </div>
      {meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default InputToggle;
