import React from "react";

import style from "assets/css/components/InputBtn.module.css";

import { useField } from "formik";

function InputBtn({ link, href, title, subtitle, type, iconBtn, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className={style.container}>
      <p className={style.title}>
        {title}
        {subtitle ? <span className={style.subtitle}>({subtitle})</span> : null}
      </p>
      <div className={style.contInput}>
        <input
          {...field}
          {...props}
          className={style.input}
          type={type}
          placeholder="Escribir"
        />
        <button type="button" className={style.btn}>
          {link ? (
            <a
              className={style.icon}
              target="_blank"
              rel="noopener noreferrer"
              href={href}
            >
              <i className={iconBtn} />
            </a>
          ) : (
            <i className={iconBtn}></i>
          )}
        </button>
      </div>
      {meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default InputBtn;
