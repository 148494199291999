import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

// import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
// import AuthLayout from "layouts/Auth.js";
// import IndexView from "views/Index.js";
import Aspirants from "views/pages/escolares/ListAspirant";
import PrivacyPolicy from "views/pages/PrivacyPolicy";

import Login from "views/pages/PageLogin";
import PageRegister from "views/pages/PageRegister";
import ConfigInitialAdmission from "views/pages/escolares/ConfigInitialAdmission";
import NewDegree from "views/pages/escolares/NewDegree";
import AddSubject from "views/pages/escolares/AddSubject";
import Reticle from "views/pages/escolares/Reticle";
import SchoolPeriods from "views/pages/escolares/SchoolPeriods";
import AddClassroom from "views/pages/academico/AddClassroom";
import AspirantStatistics from "views/pages/academico/estadisticas";
import FindCredentials from "views/pages/academico/credenciales/FindCredentials";

import PageNotFound from "views/pages/PageNotFound";
import AuthRoute from "components/AuthRoute";
import LoginAspirant from "views/pages/LoginAspirant";
import apolloSetup from "apolloSetup";
import SettingPassword from "views/pages/SettingPassword";
import DashboardAspirant from "views/pages/dashboards/DashboardAspirant";
import DashboardDefault from "views/pages/dashboards/DashboardDefault";
import Groups from "views/pages/divEstudios/Groups";
import CreateClassroom from "views/pages/divEstudios/CreateClassroom";
import CreateSpecialties from "views/pages/divEstudios/CreateSpecialties";
import CreateAcademicArea from "views/pages/divEstudios/CreateAcademicArea";
import CreateSchoolLevel from "views/pages/divEstudios/CreateSchoolLevel";
import CreateSubjectType from "views/pages/divEstudios/CreateSubjectType";
import CreateReticle from "views/pages/divEstudios/CreateReticle";
import DashboardStudent from "views/pages/dashboards/DashboardStudent";
import Subject from "views/pages/divEstudios/Subject";
import CreateDegree from "views/pages/divEstudios/CreateDegree";
import Specialties from "views/pages/divEstudios/Specialties";
import Reticles from "views/pages/divEstudios/Reticles";
import DashboardDivision from "views/pages/dashboards/DashboardDivisión";
import Classroom from "views/pages/divEstudios/Classroom";
import FormUploadPhoto from "views/pages/forms/FormUploadPhoto";
import ConfigEnglish from "views/pages/english/ConfigEnglish";
import SelectSubject from "views/pages/student/SelectSubject";
import AcceptAspirant from "views/pages/divEstudios/AcceptAspirant";
import AspirantToStudent from "views/pages/divEstudios/AspirantToStudent";
import ListGroups from "views/pages/divEstudios/ListGroups";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

ReactDOM.render(
  <ApolloProvider client={apolloSetup}>
    <BrowserRouter>
      <Switch>
        {/* Public pages */}
        {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          exact
          path="/example"
          render={(props) => <IndexView {...props} />}
        /> */}
        <Route
          exact
          path="/privacy"
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          exact
          path="/login"
          render={(props) => <LoginAspirant {...props} />}
        />
        <Route
          exact
          path="/register"
          render={(props) => <PageRegister {...props} />}
        />
        <Route
          exact
          path="/verify/:code/:email"
          render={(props) => <SettingPassword {...props} />}
        />
        <Route
          exact
          path="/password/:code"
          render={(props) => <SettingPassword {...props} />}
        />
        <Route exact path="/" render={() => <Login />} />
        {/* Private pages */}
        <AuthRoute exact path="/aspirant/me">
          <DashboardAspirant />
        </AuthRoute>
        <AuthRoute exact path="/personal/me">
          <DashboardDefault />
        </AuthRoute>
        <AuthRoute exact path="/personal/admision">
          <ConfigInitialAdmission />
        </AuthRoute>
        <AuthRoute exact path="/personal/groups">
          <Groups />
        </AuthRoute>
        <AuthRoute exact path="/personal/listAspirant">
          <Aspirants />
        </AuthRoute>
        <AuthRoute exact path="/personal/AddClassroom">
          <AddClassroom />
        </AuthRoute>
        <AuthRoute exact path="/personal/SchoolPeriods">
          <SchoolPeriods />
        </AuthRoute>

        <AuthRoute exact path="/personal/createAcademicArea">
          <CreateAcademicArea />
        </AuthRoute>
        <AuthRoute exact path="/personal/newDegree">
          <NewDegree />
        </AuthRoute>
        <AuthRoute exact path="/personal/createSchoolLevel">
          <CreateSchoolLevel />
        </AuthRoute>
        <AuthRoute exact path="/personal/specialties">
          <Specialties />
        </AuthRoute>
        <AuthRoute exact path="/personal/createSpecialties">
          <CreateSpecialties />
        </AuthRoute>
        <AuthRoute exact path="/personal/classroom">
          <Classroom />
        </AuthRoute>
        <AuthRoute exact path="/personal/createClassroom">
          <CreateClassroom />
        </AuthRoute>
        <AuthRoute exact path="/personal/subject">
          <Subject />
        </AuthRoute>
        <AuthRoute exact path="/personal/createSubjectType">
          <CreateSubjectType />
        </AuthRoute>
        <AuthRoute exact path="/personal/AddSubject">
          <AddSubject />
        </AuthRoute>
        <AuthRoute exact path="/personal/reticle">
          <Reticle />
        </AuthRoute>
        <AuthRoute exact path="/personal/listReticle">
          <Reticles />
        </AuthRoute>
        <AuthRoute exact path="/personal/createReticle">
          <CreateReticle />
        </AuthRoute>
        <AuthRoute exact path="/personal/createDegree">
          <CreateDegree />
        </AuthRoute>
        <AuthRoute exact path="/personal/study-division">
          <DashboardDivision />
        </AuthRoute>
        <AuthRoute exact path="/personal/aspirant-statistics">
          <AspirantStatistics />
        </AuthRoute>
        <AuthRoute exact path="/personal/find-credentials">
          <FindCredentials />
        </AuthRoute>
        <AuthRoute exact path="/upload-photo">
          <FormUploadPhoto />
        </AuthRoute>
        <AuthRoute exact path="/personal/english">
          <ConfigEnglish />
        </AuthRoute>
        <AuthRoute exact path="/student/me">
          <DashboardStudent />
        </AuthRoute>
        <AuthRoute exact path="/select-subject">
          <SelectSubject />
        </AuthRoute>
        <AuthRoute exact path="/personal/accept-aspirant">
          <AcceptAspirant />
        </AuthRoute>
        <AuthRoute exact path="/personal/aspirant-to-student">
          <AspirantToStudent />
        </AuthRoute>
        <AuthRoute exact path="/personal/list-groups">
          <ListGroups />
        </AuthRoute>
        <Route path="*" render={() => <PageNotFound />} />
      </Switch>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
