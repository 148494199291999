import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import axios from "axios";

import styles from "assets/css/Forms/FormAddClassroom.module.css";

import CardClassRoom from "components/Card/CardClassRoom";
import InputSearch from "components/Inputs/InputSearch";
import Classroom from "components/Charts/Classroom";
import Loading from "components/Loading";
import { generateListAspirantClass } from "../../../api/aspirants";

import {
  GET_CLASSROOMS,
  GET_CLASSROOM_ADMISIONS,
} from "../../../utils/graphql/querys";
import BtnNotFound from "components/Buttons/BtnNotFound";
import useGetPermissions from "hooks/useGetPermissions";
import { warning, error, success } from "components/Alerts";
import ConsoleHelper from "ConsoleHelper";

const filterClassroom = (value) => (item) => {
  const { key } = item;
  if (key.toLowerCase().trim().includes(value.toLowerCase().trim())) {
    return true;
  }
  return false;
};

function FormAddClassroom() {
  const { avaible, permiss } = useGetPermissions("classroom-admision", [
    "find",
    "findone",
  ]);
  const { loading, error, data } = useQuery(GET_CLASSROOMS);
  const classroomAdmisions = useQuery(GET_CLASSROOM_ADMISIONS);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");

  const memoizedClassrooms = useMemo(
    () => (data ? data.classrooms.filter(filterClassroom(searchedValue)) : []),
    [searchedValue, data]
  );

  if (avaible === null) return <Loading />;

  if (loading || classroomAdmisions.loading || loadingDelete)
    return <Loading />;

  if (error || classroomAdmisions.error) {
    return (
      <div>
        <p>Lo sentimos a ocurrido un error al traer los datos</p>
        <BtnNotFound />
      </div>
    );
  }

  const handleChangeSearchedValue = (e) => {
    const value = e.target.value;
    setSearchedValue(value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Aulas para el examen de admisión</h1>
      <div className={styles.classroom}>
        <div className={styles.searchClassroom}>
          <div className={styles.headSection}>
            <h1 className={styles.title}>Selección de aulas disponibles</h1>
            <div className={styles.contSearch}>
              <InputSearch
                onChange={handleChangeSearchedValue}
                value={searchedValue}
              />
            </div>
          </div>
          <div className={styles.contClassroom}>
            {data &&
              memoizedClassrooms.map(({ _id, key, capacity }) => {
                return (
                  <CardClassRoom
                    key={_id}
                    id={_id}
                    classroom={key}
                    capacity={capacity}
                    refetch={classroomAdmisions.refetch}
                  />
                );
              })}
          </div>
        </div>
        <div className={styles.assigned}>
          <h1 className={styles.title}>Aulas asignadas</h1>
          <div className={styles.classroomAssigned}>
            {classroomAdmisions.data &&
              classroomAdmisions.data.classroomAdmisions.map((item) => {
                return (
                  <AssignedClassroom
                    key={item._id}
                    id={item._id}
                    classroom={item.classroom?.key}
                    assigned={item.count}
                    available={item.capacity - item.count}
                    name={moment(item.dateExam + " " + item.hourExam).format(
                      "DD MMM YYYY, h:mm a"
                    )}
                    setLoading={setLoadingDelete}
                    refetch={classroomAdmisions.refetch}
                    permiss={permiss?.delete?.enabled}
                  />
                );
              })}
          </div>
        </div>
        {/* <ClassroomTest /> */}
      </div>
    </div>
  );
}

const AssignedClassroom = ({
  id,
  assigned,
  available,
  classroom,
  name,
  setLoading,
  refetch,
  permiss,
}) => {
  const handleClickAssigned = () => {
    warning(
      "¿Estás seguro que deseas eliminar este aula?",
      "Al confirmar se eliminará la asignación de este aula para uso en el examen de admisión.",
      "Confirmar",
      () => deleteClassroomAdmision(id)
    );
  };

  let onClickGenerateList = async () => {
    setLoading(true);
    await generateListAspirantClass(id);
    setLoading(false);
  };

  const deleteClassroomAdmision = async (id) => {
    try {
      setLoading(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/classroom-admisions/" + id;
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.delete(API_URL, { headers });
      if (response.status === 200) {
        setLoading(false);
        success(
          "Salón eliminado correctamente",
          "El aula para el examen de admisión ha sido eliminado correctamente.",
          "Aceptar",
          () => refetch()
        );
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      error(
        "No se pudo eliminar el aula",
        "Lo sentimos esto no debió parar, estamos trabajando en eso."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.contAssigned}>
      <div className={styles.contClassroom}>
        <div className={styles.nameClassroom}>
          <p className={styles.txtClassroom}>{classroom}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.titleCard}>Fecha de examen</p>
          <p className={styles.department}>{name}</p>
        </div>
        <div className={styles.contBtn}>
          {permiss && assigned === 0 && (
            <button className={styles.btnStatus} onClick={handleClickAssigned}>
              <i className="fas fa-times"></i>
            </button>
          )}
        </div>
      </div>
      <div className={styles.contGraph}>
        <Classroom assigned={assigned} available={available} />
      </div>
      <div>
        <button className="btn" onClick={onClickGenerateList}>
          <i className="fas fa-file-pdf"> Lista aspirantes</i>
        </button>
      </div>
    </div>
  );
};

export default FormAddClassroom;
