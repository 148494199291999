import React from "react";

import style from "assets/css/divEstudios/Classroom.module.css";
import DashboardAlt from "../dashboards/DashboardAlt";
import LinkPage from "components/LinkPage";
import InputSearch from "components/Inputs/InputSearch";
import ClassroomCard from "components/Card/ClassroomCard";

function Classroom() {
  return (
    <DashboardAlt>
      <div className={style.header}>
        <h1 className={style.title}>Aulas registradas</h1>
        <div className={style.action}>
          <LinkPage text="Crear aula" link="/personal/createClassroom" />
          <div className={style.search}>
            <InputSearch />
          </div>
        </div>
      </div>
      <div className={style.content}>
        {/* <div className={style.cards}> */}
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        <ClassroomCard position={[16.445133650511583, -95.01629492062168]} />
        {/* </div> */}
      </div>
    </DashboardAlt>
  );
}

export default Classroom;
