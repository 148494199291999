import React from "react";
import style from "assets/css/components/ButtonGroupSelect.module.css";

function ButtonGroupSelect({ group }) {
  return (
    <label className={style.option}>
      <input type="radio" name={group} />
      <div className={style.checkmark}>
        <div className={style.nameGroup}>
          <p className={style.name}>B2</p>
        </div>
        <div className={style.countGroup}>
          <p className={style.desc}>Inscritos</p>
          <p className={style.count}>30</p>
        </div>
      </div>
    </label>
  );
}

export default ButtonGroupSelect;
