import axios from "./config";
import { getCareers } from "./career";

export const getAspirantsCountByStatus = async ({ statusList = [] } = {}) => {
  const careers = await getCareers();
  const aspirantCounter = await Promise.all(
    careers.map(async (career) => {
      const aspirantStatistics = await Promise.all(
        statusList.map((status) =>
          axios
            .get(`/students/count-carrera/${career._id}/${status}`)
            .then(({ data }) => ({ status, aspirantCount: data.count }))
        )
      );
      return {
        id: career._id,
        abbreviation: career.abbreviation,
        name: career.name,
        officialKey: career.officialKey,
        aspirantStatistics,
      };
    })
  );

  const total = aspirantCounter.reduce((prev, current) => {
    return (
      prev +
      current.aspirantStatistics.reduce((prev, current) => {
        return prev + current.aspirantCount;
      }, 0)
    );
  }, 0);

  return { totalElements: total, aspirantCounter };
};

export const getAspirantByIdentifier = async (identifier = "") => {
  const graphqlQuery = `query($where: JSON) {
    users(where: $where) {
      name
      firstLastName
      secondLastName
      CURP
      student {
        matricula
        photography {
          _id
          url
        }
        career {
          name
        }
      }
    }
  }
  `;

  const graphqlVariables = {
    where: {
      _or: [
        {
          CURP_contains: identifier,
        },
        {
          student: {
            matricula: identifier,
          },
        },
      ],
    },
  };

  try {
    const query = await axios.post("/graphql", {
      query: graphqlQuery,
      variables: graphqlVariables,
    });
    const aspirant = query.data.data.users[0];
    return { aspirantFinded: Boolean(aspirant), aspirant };
  } catch (error) {
    // console.log(error);
  }
};

export const generateCredential = async (ncontrol = "") => {
  try {
    const query = await axios.get(`/students/new-credential/${ncontrol}`, {
      responseType: "blob",
    });
    if (query.data) {
      
      const file = new Blob([query.data], { type: "application/pdf" });
      const fileUrl = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileUrl;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const generateListAspirantClass = async (classroom = "") => {
  try {
    const query = await axios.get(`/classroom-list-aspirante/${classroom}`, {
      responseType: "blob",
    });
    if (query.data) {
      const file = new Blob([query.data], { type: "application/pdf" });
      const fileUrl = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileUrl;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const rejectPhotography = async (photographyId = "") => {
  try {
    await axios.delete(`/upload/files/${photographyId}`);
  } catch (error) {
    // console.log(error);
  }
};
