import React from "react";

import styles from "../../assets/css/components/Input.module.css";

import { useField } from "formik";

function Input({ title, subtitle, placeholder, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {title}
        {subtitle ? <span className={styles.subtitle}>({subtitle})</span> : null}
      </p>
      <input
        {...field}
        {...props}
        className={styles.input}
        placeholder={placeholder ? placeholder : "Escribir"}
      />
      {meta.touched && meta.error && (
        <p className={styles.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default Input;
