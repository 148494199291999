import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormCreateClassroom from "../forms/FormCreateClassroom";

function CreateClassroom() {
  return (
    <DashboardAlt>
      <FormCreateClassroom />
    </DashboardAlt>
  );
}

export default CreateClassroom;
