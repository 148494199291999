import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useUpdateUser() {
  const [loadingUser, setLoadingUser] = useState(false);

  const updateUser = async (id, token, userData) => {
    try {
      setLoadingUser(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const REGISTER_STUDENT_URL = process.env.REACT_APP_REST_URI + "/students";
      const UPDATE_USER_URL = process.env.REACT_APP_REST_URI + "/users/" + id;
      const studentResponse = await axios.post(
        REGISTER_STUDENT_URL,
        {
          user: id,
          candidate: true,
          processStatus: "formularios",
        },
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      if (studentResponse?.data?.id) {
        userData = {
          ...userData,
          student: studentResponse.data.id,
        };
        const updateResponse = await axios.put(UPDATE_USER_URL, userData, {
          headers: { Authorization: BEARER_TOKEN },
        });
        ConsoleHelper(updateResponse?.data);
        return {
          idUser: updateResponse?.data?.id,
          idStudent: studentResponse?.data?.id,
        };
      } else {
        return "error";
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingUser(false);
    }
  };

  return { loadingUser, updateUser };
}

export default useUpdateUser;
