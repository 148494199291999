import React from "react";

import style from "assets/css/components/ShowSubject.module.css";

function ShowSubject({ subject }) {
  return (
    <div className={style.contAddMateria}>
      <div className={style.itemPrincipal}>
        <InfoPrincipal
          title="Nombre de la matería"
          desc={subject?.school_subject?.name}
        />
        <InfoPrincipal
          title="Clave oficial de la materia"
          desc={subject?.school_subject?.subjectKey}
        />
        <InfoPrincipal title="Especialidad" desc="Sistemas computacionales" />
        <InfoPrincipal
          title="Matería pre-requisito"
          desc="Nombre de la matería"
        />
      </div>
      <div className={style.contItem}>
        <Item
          subtitle="Horas"
          title="teóricas"
          desc={subject?.theoreticalHours}
          text={false}
          icon="fas fa-clock"
        />
        <Item
          subtitle="Horas"
          title="practicas"
          desc={subject?.practicalHours}
          text={false}
          icon="fas fa-clock"
        />
        <Item
          subtitle="Créditos"
          title="totales"
          desc={subject?.creditsNeeded}
          text={false}
          icon="fab fa-slack-hash"
        />
        <Item
          subtitle="Créditos"
          title="prerrequisito"
          desc={subject?.school_subject?.creditPrerrequisit}
          text={false}
          icon="fab fa-slack-hash"
        />
        <Item
          subtitle="Órden en"
          title="certificado"
          desc={subject?.orderInCertificate}
          text={false}
          icon="fas fa-stream"
        />
        <Item
          subtitle="Estatus de"
          title="materia"
          desc={subject?.school_subject?.status ? "Activa" : "Desactivada"}
          text={subject?.school_subject?.status ? false : true}
          icon="fas fa-check"
        />
      </div>
    </div>
  );
}

const InfoPrincipal = ({ title, desc }) => {
  return (
    <div className={style.infoPrincipal}>
      <p className={style.title}>{title}</p>
      <p className={style.desc}>{desc}</p>
    </div>
  );
};

const Item = ({ title, desc, subtitle, text, icon }) => {
  return (
    <div className={style.item}>
      <div className={style.contDesc}>
        <p className={text ? style.descText : style.desc}>{desc}</p>
      </div>
      <div className={style.contTitle}>
        <p className={style.subtitle}>{subtitle}</p>
        <p className={style.title}>{title}</p>
      </div>
      <div className={style.contIcon}>
        <p className={style.icon}>
          <i className={icon}></i>
        </p>
      </div>
    </div>
  );
};

export default ShowSubject;
