import React from "react";

import styles from "../../assets/css/components/Button.module.css";
import classnames from "classnames";

function BtnPrincipal({ text, type, secondary, danger, ...rootDOMAttributes }) {
  return (
    <button
      type={type ? type : "submit"}
      className={
        secondary
          ? classnames(styles.btn, styles.btnSecondary)
          : danger
          ? classnames(styles.btn, styles.btnDanger)
          : classnames(styles.btn, styles.btnPrincipal)
      }
      {...rootDOMAttributes}
    >
      {text}
    </button>
  );
}

export default BtnPrincipal;
