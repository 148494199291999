export const createClassroomInitialState = {
  classroom: "",
  capacity: "",
  carrer: "",
};

export const createClassroom = {
  key: "",
  capacity: "",
  ubication: "",
  timeCrossing: false,
  observations: "",
};
