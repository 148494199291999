import { useState } from "react";
import axios from "axios";

function useVerifyAccount() {
  const [loadingVerify, setLoadingVerify] = useState(false);

  const sendEmailConfirmation = async (email) => {
    try {
      setLoadingVerify(true);
      const SEND_CONFIRMATION_URL =
        process.env.REACT_APP_REST_URI + "/auth/send-email-confirmation";
      const confirmationResponse = await axios.post(
        SEND_CONFIRMATION_URL,
        email
      );
      return confirmationResponse?.data?.sent;
    } catch (e) {
      if (e?.response?.data?.message === "already.confirmed")
        return "confirmado";
      return "error";
    } finally {
      setLoadingVerify(false);
    }
  };

  const verify = async (verifyData) => {
    try {
      setLoadingVerify(true);
      const VERIFY_ACCOUNT_URL =
        process.env.REACT_APP_REST_URI + "/auth/verify-account";
      const verifyResponse = await axios.post(VERIFY_ACCOUNT_URL, verifyData);
      return verifyResponse?.data?.jwt;
    } catch (e) {
      return "error";
    } finally {
      setLoadingVerify(false);
    }
  };

  return { loadingVerify, verify, sendEmailConfirmation };
}

export default useVerifyAccount;
