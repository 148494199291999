import React from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputDate from "components/Inputs/InputDate";
import BtnPrincipal from "components/Buttons/BtnPrincipal";

import { highSchoolInitialState } from "utils/states/aspirant";
import { addHighSchoolSchema } from "utils/schemas/aspirant.schemas";
import formatDate from "utils/formatDate";
import useAddHighSchool from "hooks/useAddHighSchool";
import Loading from "components/Loading";
import { error } from "components/Alerts";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";
import useHighSchools from "hooks/useHighSchools";

function FormPreparatory({ idStudent, token, setPosition }) {
  const { loadingHighSchool, addHighSchool } = useAddHighSchool();
  const [student] = useLocalStorage("student", null);
  const { getHighSchool } = useHighSchools();

  if (loadingHighSchool) return <Loading />;

  const handleSubmit = async (values) => {
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element === "") {
          values = {
            ...values,
            [key]: null,
          };
        }
      }
    }
    values = {
      ...values,
      students: [idStudent ? idStudent : student],
    };
    ConsoleHelper(values);
    if (values.average !== null && values.average < 60) {
      error(
        "Verificar",
        "Tu promedio debe estar en el rango de 60 - 100, en caso de no contar con él favor de no rellenarlo"
      );
    } else {
      const response = await addHighSchool(token, values);
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          setPosition(3);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={highSchoolInitialState}
      validationSchema={addHighSchoolSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched, setFieldValue } = props;

        const handleChangeKey = async (e) => {
          let key = e.target.value.replace(" ", "").toUpperCase();
          handleChange("code")(key);
          setFieldValue("name", "", false);
          setFieldValue("federalEntity", "", false);
          setFieldValue("municipality", "", false);
          if (key.length === 10) {
            try {
              const response = await getHighSchool(key);
              setFieldValue("name", response.name, false);
              setFieldValue("federalEntity", response.entity, false);
              setFieldValue("municipality", response.town, false);
            } catch (e) {
              ConsoleHelper(e, "error");
              error(
                "Verifica si escribiste bien",
                "No pudimos encontrar una preparatoria con esa clave"
              );
              setFieldValue("name", "", false);
              setFieldValue("federalEntity", "", false);
              setFieldValue("municipality", "", false);
            }
          }
        };

        return (
          <Form>
            <Collapse title="Preparatoria o bachillerato de procedencia">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <Input
                  name="code"
                  title="Clave oficial de la preparatoria"
                  type="text"
                  placeholder="Escribe la clave de tu prepa"
                  onChange={handleChangeKey}
                  autoComplete="off"
                  maxLength={10}
                />
                <Input
                  name="name"
                  title="Nombre de la preparatoria"
                  placeholder="Escribe la clave de tu prepa en el campo clave"
                  disabled
                />
                <Input
                  name="federalEntity"
                  title="Entidad federativa de la preparatoria"
                  placeholder="Escribe la clave de tu prepa en el campo clave"
                  disabled
                />
                <Input
                  name="municipality"
                  title="Municipio de la preparatoria"
                  placeholder="Escribe la clave de tu prepa en el campo clave"
                  disabled
                />
                <InputDate
                  error={touched.graduationDate && errors.graduationDate}
                  name="graduationDate"
                  title="Fecha de egreso de la preparatoria"
                  subtitle="Opcional"
                  time={false}
                  date={true}
                  onChange={(dateFromValue) => {
                    handleChange("graduationDate")(formatDate(dateFromValue));
                  }}
                />
                <Input
                  name="average"
                  title="Promedio general"
                  subtitle="Opcional"
                  type="number"
                  maxLength={4}
                  placeholder="Promedio de 60 - 100"
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="off"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 4) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormPreparatory;
