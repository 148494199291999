import { useLocalStorage } from "hooks/useLocalStorage";
import { dashboards } from "utils/rolesDashboard";

function useGetRoutes() {
  const [data] = useLocalStorage("permissions", null);
  const [role] = useLocalStorage("role", null);
  const resultado = dashboards.find((dash) => dash.role === role);
  const permissions = JSON.parse(data);
  const routes = [
    // {
    //   path: "/personal/me",
    //   name: "Inicio",
    //   icon: "fas fa-home",
    //   enabled: true
    // },

    {
      path: resultado ? resultado.dashboard : "/personal/me",
      name: "Inicio",
      icon: "fas fa-home",
      enabled: true
    },
    {
      path: "/personal/admision",
      name: "Admisión",
      icon: "fas fa-user-clock",
      enabled:
        permissions["setting-candidates"]?.create?.enabled &&
        permissions["setting-candidates"]?.update?.enabled
    },
    {
      path: "/personal/listAspirant",
      name: "Aspirantes",
      icon: "fas fa-users",
      enabled:
        permissions?.student?.find?.enabled &&
        permissions?.student?.findone?.enabled
    },
    {
      path: "/personal/AddClassroom",
      name: "Salones examen",
      icon: "fas fa-house-user",
      enabled:
        permissions["classroom-admision"]?.find?.enabled &&
        permissions["classroom-admision"]?.findone?.enabled
    },
    {
      path: "/personal/createDegree",
      name: "Carreras",
      icon: "fas fa-user-graduate",
      enabled: permissions?.career?.create?.enabled
    },
    {
      path: "/personal/reticle",
      name: "Retícula",
      icon: "fas fa-border-all",
      enabled:
        permissions?.reticles?.find?.enabled &&
        permissions?.reticles?.findone?.enabled &&
        permissions?.reticles?.create?.enabled &&
        permissions?.reticles?.update?.enabled
    },
    // {
    //   path: "/personal/createReticle",
    //   name: "Crear reticula",
    //   icon: "fas fa-th",
    //   enabled:
    //     permissions["reticles"]?.create?.enabled &&
    //     permissions["reticles"]?.find?.enabled &&
    //     permissions["reticles"]?.update?.enabled,
    // },
    {
      path: "/personal/subject",
      name: "Materias",
      icon: "fas fa-book-reader",
      enabled:
        permissions["school-subject"]?.create?.enabled &&
        permissions["school-subject"]?.update?.enabled
    },
    {
      path: "/personal/SchoolPeriods",
      name: "Periodos escolares",
      icon: "far fa-calendar-alt",
      enabled:
        permissions["school-period"]?.create?.enabled &&
        permissions["school-period"]?.update?.enabled
    },
    {
      path: "/personal/groups",
      name: "Grupos",
      icon: "fas fa-object-group",
      enabled:
        permissions["group"]?.create?.enabled &&
        permissions["group"]?.update?.enabled
    },
    {
      path: "/personal/aspirant-statistics",
      name: "Estadisticas",
      icon: "fas fa-border-all",
      enabled: permissions["student"]?.countcarrera?.enabled
    },
    {
      path: "/personal/find-credentials",
      name: "Credenciales",
      icon: "fas fa-id-card-alt",
      enabled: permissions["student"]?.newcredential?.enabled
    },
    {
      path: "/personal/createSchoolLevel",
      name: "Nivel escolar",
      icon: "fas fa-sort-amount-up-alt",
      enabled:
        permissions["school-period"]?.create?.enabled &&
        permissions["school-period"]?.update?.enabled
    },
    {
      path: "/personal/specialties",
      name: "Especialidades",
      icon: "fas fa-hard-hat",
      enabled:
        permissions["school-level"]?.create?.enabled &&
        permissions["school-level"]?.update?.enabled
    },
    {
      path: "/personal/createAcademicArea",
      name: "Áreas académicas",
      icon: "far fa-building",
      enabled:
        permissions["academic-area"]?.create?.enabled &&
        permissions["academic-area"]?.update?.enabled
    },
    {
      path: "/personal/classroom",
      name: "Aulas",
      icon: "fas fa-house-user",
      enabled:
        permissions["classroom"]?.create?.enabled &&
        permissions["classroom"]?.update?.enabled
    },
    {
      path: "/personal/createSubjectType",
      name: "Tipo de materia",
      icon: "fas fa-layer-group",
      enabled:
        permissions["subject-type"]?.create?.enabled &&
        permissions["subject-type"]?.update?.enabled
    }
  ];
  return routes;
}

export default useGetRoutes;
