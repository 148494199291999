import React from "react";
import "assets/css/views/pages/academico/CareerCard.css";

const CareerCard = ({ career, children }) => {
  return (
    <div key={career.id} className="CareerCard">
      <h3 className="CareerCard-title">{career.name}</h3>
      <div className="CareerCard-info">
        <p>{career.officialKey}</p>
        <p>{career.abbreviation}</p>
      </div>
      {children}
    </div>
  );
};

export default CareerCard;
