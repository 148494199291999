import React from "react";

import styles from "assets/css/Modal/Modal.module.css";

function Modal({
  title,
  children,
  show,
  close,
  width,
  height,
  ...rootDOMAttributes
}) {
  if (show) {
    return (
      <div className={styles.contModal}>
        <div className={styles.modal}>
          <div className={styles.contHeader}>
            <h1 className={styles.titleHeader}>{title}</h1>
            <button type="button" className={styles.btnClose} onClick={close}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className={styles.contBody}>{children}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Modal;
