import React from "react";

import style from "assets/css/Card/CardWelcome.module.css";

function CardWelcome({user, completeTitle, image, children }) {
  return (
    <>
      <div className={style.contDesc}>
        <h1 className={style.title}>
          Hola <span>{user}</span>, {completeTitle}
        </h1>
        <h1 className={style.desc}>
          {children}
        </h1>
      </div>
      <div className={style.contImg}>
        <img className={style.image} src={image} alt="" />
      </div>
    </>
  );
}

export default CardWelcome;
