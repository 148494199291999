import React, { useEffect, useState } from "react";
import "assets/css/views/pages/academico/AspirantStatistics.css";
import { getAspirantsCountByStatus } from "api/aspirants";

import DashboardAlt from "views/pages/dashboards/DashboardAlt";
import Loading from "components/Loading";

import CareerCard from "./CareerCard";
import InputSearch from "components/Inputs/InputSearch";

function filterByName(searchedValue = "") {
  return (career) => {
    return career.name.toLowerCase().trim().includes(searchedValue);
  };
}

const AspirantStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [aspirantStatistics, setAspirantStatistics] = useState([]);
  const [totalAspirants, setTotalAspirants] = useState(0);
  const [searchedCareer, setSearchedCareer] = useState("");

  useEffect(() => {
    getAspirants();
  }, []);

  const getAspirants = async () => {
    const statusList = [
      "registrado",
      "formularios",
      "enviado",
      "revisado",
      "observaciones",
      "aceptado",
      "rechazado",
      "aspirante",
      "pagar",
      "pagado",
      "corregido",
    ];
    try {
      setLoading(true);
      const response = await getAspirantsCountByStatus({
        statusList,
      });
      setTotalAspirants(response.totalElements);
      setAspirantStatistics(response.aspirantCounter);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearchedCareer = (evt) => {
    setSearchedCareer(evt.target.value);
  };

  if (loading) return <Loading />;

  return (
    <DashboardAlt>
      <h1 className="AspirantStatistics-title">Estadisticas de aspirantes</h1>
      <div className="AspirantStatistics-header">
        <div className="AspirantStatistics-searchBar">
          <InputSearch
            value={searchedCareer}
            onChange={onChangeSearchedCareer}
          />
        </div>
        <div className="AspirantStatistics-total">
          <p className="AspirantStatistics-aspirants">
            Total de solicitudes: {totalAspirants}
          </p>
        </div>
      </div>
      <div className="AspirantStatistics-list">
        {aspirantStatistics
          .filter(filterByName(searchedCareer))
          .map((career) => (
            <CareerCard key={career.id} career={career}>
              <>
                <h4 className="AspirantStatistics-totalAspirants">
                  Total de solicitudes:{" "}
                  {career.aspirantStatistics
                    .map((status) => status.aspirantCount)
                    .reduce((a, b) => {
                      return a + b;
                    })}
                </h4>
                {career.aspirantStatistics.map((status, index) => (
                  <p
                    className="AspirantStatistics-aspirantCount"
                    key={`CareerCard-Status-${index}`}
                  >
                    <span className="AspirantStatistics-careerStatusTitle">
                      {status.status}
                    </span>
                    : {status.aspirantCount}
                  </p>
                ))}
              </>
            </CareerCard>
          ))}
      </div>
    </DashboardAlt>
  );
};

export default AspirantStatistics;
