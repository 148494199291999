import React from 'react';
import HomeStudent from '../student/HomeStudent';
import DashboardAlt from './DashboardAlt';

function DashboardStudent() {
    return ( 
        <DashboardAlt>
            <HomeStudent />
        </DashboardAlt>
     );
}

export default DashboardStudent;