import React, { useState, useEffect } from "react";
import DashboardAlt from "../dashboards/DashboardAlt";
import style from "assets/css/student/SelectSubject.module.css";
import SubjectSelect from "../components/SubjectSelect";
import classNames from "classnames";
import ScheduleStudent from "../tables/ScheduleStudent";
import { useLocalStorage } from "hooks/useLocalStorage";


const subjects = [
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name1",
    status: true,
  },
  {
    image:
      "https://images.unsplash.com/photo-1519766304817-4f37bda74a26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    name: "name subject",
    nameShort: "name2",
    status: false,
  },
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name3",
    status: true,
  },
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name4",
    status: true,
  },
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name5",
    status: true,
  },
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name6",
    status: true,
  },
  {
    image:
      "https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    name: "name subject",
    nameShort: "name1",
    status: true,
  },
];

function SelectSubject() {
//   const [id] = useLocalStorage("id", null);
  const [openSchedule, setOpenSchedule] = useState(false);
//   // const [subjects, setSubjects] = useState([])
// const [semester, setSemester] = useState([])
// const [student, setEstudent] = useState([])

// useEffect(() => {
//   const getUser = async() => {
//     const response = await 
//   }
// },[])


//obtener las materias disponibles a seleccionar del alumno
//dependiendo del semestre que va a cursar y su reticula

//obtener el semestre actual del alumno

  return (
    <DashboardAlt noPadding>
      <div className={style.container}>
        <div className={style.subjects}>
          {subjects.map((subject, index) => (
            <SubjectSelect key={index} data={subject} />
          ))}
        </div>
        <div
          className={
            openSchedule
              ? classNames(style.contSchedule, style.openSchedule)
              : style.contSchedule
          }
        >
          <div className={style.schedule}>
            <div className={style.headSchedule}>
              <button
                className={style.btnFullSchedule}
                onClick={() => setOpenSchedule(!openSchedule)}
              >
                <p className={openSchedule ? style.iconOpen : style.iconClose}>
                  <i className="fas fa-chevron-left" />
                </p>
              </button>
              <div className={style.contTitle}>
                <p className={style.title}>Tu horario</p>
                <p className={style.desc}>
                  Antes de confirmar verifica tu horario
                </p>
              </div>
            </div>
            <div className={style.tableSchedule}>
              <ScheduleStudent />
            </div>
          </div>
        </div>
      </div>
    </DashboardAlt>
  );
}

export default SelectSubject;
