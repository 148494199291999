import React from "react";

import style from "assets/css/Card/CardChartData.module.css";
import ChartSemester from "components/Charts/ChartSemester";

function CardSemesterGrade() {
  return (
    <>
      <div className={style.chart}>
        <ChartSemester />
      </div>
      <div className={style.summary}>
        <div className={style.contTitle}>
          <p className={style.title}>Resumen</p>
        </div>
        <div className={style.contData}>
          <ItemData
            icon="fas fa-street-view"
            name="primary"
            data="8"
            desc="Sem. actual"
          />
          <ItemData
            icon="fas fa-star"
            name="secondary"
            data="85"
            desc="Promedio gral."
          />
          <ItemData
            icon="fas fa-coins"
            name="tertiary"
            data="85"
            desc="Total credito"
          />
        </div>
      </div>
    </>
  );
}

const ItemData = ({ icon, name, data, desc }) => (
  <div className={style.item}>
    <div className={style.iconItem}>
      <p className={style[name]}>
        <i className={icon}></i>
      </p>
    </div>
    <div className={style.contData}>
      <p className={style.txtdata}>{data}</p>
      <p className={style.desc}>{desc}</p>
    </div>
  </div>
);

export default CardSemesterGrade;
