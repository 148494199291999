import { gql } from "@apollo/client";

export const GET_USER_ME = gql`
  query UserMe {
    me {
      id
    }
  }
`;

export const GET_ROLE = gql`
  query getUserRole($Id: ID!, $State: PublicationState!) {
    user(id: $Id, publicationState: $State) {
      id
      name
      firstLastName
      secondLastName
      profilePhoto {
        _id
        url
      }
      role {
        id
        name
        permissions {
          id
          type
          controller
          action
          enabled
        }
      }
      student {
        id
        photography {
          id
          url
        }
      }
    }
  }
`;

export const GET_USER_ROLE = gql`
  query getUserRole($Id: ID!, $State: PublicationState!) {
    user(id: $Id, publicationState: $State) {
      id
      name
      firstLastName
      secondLastName
      role {
        id
        name
        permissions {
          id
          type
          controller
          action
          enabled
        }
      }
    }
  }
`;

export const GET_OPTIONS_FORMGRAL = gql`
  query GetOptions {
    disabilities(start: 0) {
      value: id
      label: name
    }
    dialects(start: 0) {
      value: id
      label: name
    }
  }
`;

export const GET_CARRERS = gql`
  query GetCarrers {
    careers(start: 0) {
      value: id
      label: name
      reticles {
        id
        specialties {
          value: id
          label: name
        }
      }
    }
  }
`;

export const GET_RETICLE = gql`
  query Reticle($reticleId: ID!) {
    reticle(id: $reticleId) {
      _id
      liquidationDate
      maximumLoad
      minimunLoad
      officialKey
      startDate
      tecnmKey
      totalCredits
      reticleSemesters {
        _id
        semester
        reticleSubjects {
          _id
          order
          officialKey
          creditsNeeded
          orderInCertificate
          totalCredits
          school_subject {
            _id
            name
            creditPrerrequisit
            totalCredit
            status
            subjectKey
            shortName
          }
        }
      }
    }
  }
`;

export const GET_RETICLES_SPECIALTIES = gql`
  query GetReticles {
    reticles {
      value: _id
      label: officialKey
      career {
        _id
        label: name
      }
      specialties {
        value: _id
        label: name
      }
    }
  }
`;

export const GET_USER_ID = gql`
  query GetUserId($IdUser: ID!, $State: PublicationState!) {
    user(id: $IdUser, publicationState: $State) {
      id
      name
      firstLastName
      secondLastName
      dateOfBirth
      gender
      CURP
      email
      addresses {
        id
      }
      parents {
        id
      }
      emergencyData {
        id
      }
      notifications {
        id
        type
        name
        text
        read
        createdAt
        file {
          id
          type
          file {
            id
            url
          }
        }
      }
      role {
        id
        name
        permissions {
          id
          controller
          action
          enabled
        }
      }
      student {
        id
        processStatus
        photography {
          id
          url
        }
        highSchool {
          id
        }
        economicData {
          id
        }
        careerOptions {
          id
        }
      }
    }
  }
`;

export const GET_PARENTS_USER = gql`
  query GetParents($Where: JSON!) {
    parents(start: 0, where: $Where) {
      value: id
      label: name
    }
  }
`;

export const TABLE_ASPIRANTS = gql`
  {
    users(where: { role: { name: "Aspirante" } }) {
      id
      name
      firstLastName
      secondLastName
      dateOfBirth
      gender
      student {
        id
        processStatus
        careerOptions {
          career {
            shortName
          }
        }
      }
    }
  }
`;

export const PROFILE_USER = gql`
  query Profile($IdUser: ID!, $State: PublicationState!) {
    user(id: $IdUser, publicationState: $State) {
      id
      name
      firstLastName
      secondLastName
      role {
        id
        name
      }
      archivos {
        type
        file {
          url
        }
      }
      CURP
      phone
      email
      parents {
        id
        type
        name
        firstLastName
        secondLastName
        occupation
        degreeStudies
      }
      nationality
      gender
      dateOfBirth
      RFC
      phoneHouse
      maritalStatus
      addresses {
        id
        postalCode
        colony
        municipaly
        country
        street
        number
        placeOrigin
      }
      emergencyData {
        id
        medicalService
        medicalServiceNumber
        bloodType
        organDonor
        phoneContactEmergency
        nameContactEmergency {
          id
          name
          firstLastName
          secondLastName
          occupation
          degreeStudies
          address {
            postalCode
            country
            municipaly
            colony
            number
            street
          }
        }
      }
      hasChildren
      areaOfOrigin
      dialects {
        id
        name
      }
      disabilities {
        id
        name
      }
      student {
        id
        photography {
          id
          url
        }
        careerOptions {
          id
          career {
            id
            name
          }
        }
        highSchool {
          id
          name
          code
          graduationDate
          average
          federalEntity
          municipality
        }
        economicData {
          id
          currentlyLiveWith
          dependsEconomically
          typeHouse
          countRooms
          countPeopleLiving
          countPeopleDependentEconomically
          working
          work
          monthlyIncome
        }
        certificate {
          id
          url
        }
        birthCertificate {
          id
          url
        }
        curp {
          id
          url
        }
      }
    }
  }
`;

export const GET_SUBJECT_TYPE_LEVEL_AREA = gql`
  {
    schoolLevels {
      value: id
      label: name
      createdAt
      updatedAt
    }
    subjectTypes {
      value: id
      label: name
      createdAt
      updatedAt
    }
    academicAreas {
      value: id
      label: name
      createdAt
      updatedAt
    }
  }
`;

export const GET_STUDENTS_LIST = gql`
  query GetStudentsList($start: Int!, $where: JSON!) {
    students(start: $start, where: $where) {
      id
      processStatus
      user {
        id
        name
        firstLastName
        secondLastName
        dateOfBirth
        gender
      }
      careerOptions {
        career {
          shortName
        }
      }
    }
  }
`;

export const GET_CLASSROOMS = gql`
  query {
    classrooms {
      _id
      capacity
      key
      timeCrossing
    }
  }
`;

export const GET_CLASSROOM_ADMISIONS = gql`
  query {
    classroomAdmisions {
      _id
      capacity
      count
      full
      hourExam
      dateExam
      classroom {
        id
        key
        capacity
      }
    }
  }
`;

export const GET_RETICLES = gql`
  {
    reticles {
      value: id
      label: officialKey
    }
  }
`;

export const GET_SPECIALTIES = gql`
  {
    specialties {
      id
      name
      createdAt
      updatedAt
      credits
      startPeriod
      liquidationPeriod
      reticle {
        id
        career {
          id
          name
          shortName
        }
      }
    }
  }
`;

export const GET_SPECIALTIES_CAREER = gql`
  {
    specialties {
      value: id
      label: name
      reticle {
        id
      }
    }
    careers {
      value: id
      label: name
    }
    reticles {
      id
      createdAt
      updatedAt
      career {
        id
        shortName
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const GET_LIST_CAREERS = gql`
  {
    careers {
      id
      name
      shortName
      abbreviation
      careerKey
      createdAt
      updatedAt
    }
  }
`;

export const GET_SUBJECTS = gql`
  query Query {
    schoolSubjects {
      _id
      academic_area {
        _id
        name
      }
      accreditationClassification
      hoursPractic
      hoursTeoric
      name
      school_level {
        _id
        name
      }
      shortName
      subject_type {
        _id
        name
      }
      totalCredit
      subjectKey
    }
  }
`;


