import * as Yup from "yup";

export const selectReticleInitialState = {
  reticula: "",
  especialidad: "",
};

export const selectReticleValidationSchema = Yup.object({
  reticula: Yup.string().required("Este campo es requerido."),
  especialidad: Yup.string().required("Este campo es requerido."),
});
