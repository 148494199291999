import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useAddEmergencyData() {
  const [loadingEmergency, setLoadingEmergency] = useState(false);

  const addAddress = async (token, addressData) => {
    try {
      setLoadingEmergency(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_CONTACT_ADDRESS_URL =
        process.env.REACT_APP_REST_URI + "/addresses";
      const addressResponse = await axios.post(
        ADD_CONTACT_ADDRESS_URL,
        addressData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(addressResponse?.data);
      return addressResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingEmergency(false);
    }
  };

  const updateParent = async (token, idParent, idAddress) => {
    try {
      setLoadingEmergency(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const UPDATE_PARENT_URL =
        process.env.REACT_APP_REST_URI + "/parents/" + idParent;
      const parentResponse = await axios.put(
        UPDATE_PARENT_URL,
        { contactEmergency: true, address: idAddress },
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(parentResponse?.data);
      return parentResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingEmergency(false);
    }
  };

  const addEmergencyData = async (token, emergencyData) => {
    try {
      setLoadingEmergency(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_EMERGENCY_URL =
        process.env.REACT_APP_REST_URI + "/emergency-data";
      const emergencyResponse = await axios.post(
        ADD_EMERGENCY_URL,
        emergencyData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(emergencyResponse?.data);
      return emergencyResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingEmergency(false);
    }
  };

  const addEmergency = async (token, emergencyData, addressData) => {
    const idAddress = await addAddress(token, addressData);
    if (idAddress) {
      const idParent = await updateParent(
        token,
        emergencyData.nameContactEmergency,
        idAddress
      );
      if (idParent) {
        const idEmergency = await addEmergencyData(token, emergencyData);
        if (idEmergency) {
          return idEmergency;
        }
      }
    }
    return "error";
  };

  return { loadingEmergency, addEmergency };
}

export default useAddEmergencyData;
