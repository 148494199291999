import React from 'react';

import DashboardAlt from "../dashboards/DashboardAlt";
import AcceptAspirantTable from '../tables/AcceptApirantTable';

function AcceptAspirant() {
  return (
    <DashboardAlt noPadding={true}>
      <AcceptAspirantTable />
    </DashboardAlt>
  );
}

export default AcceptAspirant;
