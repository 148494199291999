import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";
import * as Yup from "yup";

import style from "assets/css/Forms/FormAlt.module.css";

import { Form, Formik } from "formik";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import DataTable from "../tables/DataTable";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";
import ConsoleHelper from "ConsoleHelper";
import { error as errorAlert, success } from "../../../components/Alerts";
import { GET_SUBJECT_TYPE_LEVEL_AREA } from "utils/graphql/querys";
import Modal from "components/modal/Modal";
import Input from "components/Inputs/Input";
import useUpdateData from "hooks/useUpdateData";
import Swal from "sweetalert2";

const modalStates = { addData: false };

function FormSubjectType() {
  const [activeModals, setActiveModals] = useState(modalStates);
  const [item, setItem] = useState();
  const { updateData } = useUpdateData();
  const { data, loading, error, refetch } = useQuery(
    GET_SUBJECT_TYPE_LEVEL_AREA
  );
  const [loadingAdd, setLoadingAdd] = useState(false);

  if (loadingAdd || loading) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );
  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  const handleSubmit = async (values) => {
    try {
      setLoadingAdd(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/subject-types";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      if (response.status) {
        success(
          "Agregado correctamente",
          "El tipo de materia ha sido creado correctamente.",
          "Aceptar",
          () => refetch(),
          true
        );
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      errorAlert(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleUpdate = async (values) => {
    Swal.fire({
      title: "Editar nombre",
      text: "Confirmar actualización de tipo de materia",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, editar!",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = window.localStorage.getItem("jwt");
        const table = "/subject-types/";
        ConsoleHelper(item);
        ConsoleHelper(values);
        const response = await updateData(item.id, token, table, {
          name: values.name
        });
        ConsoleHelper(response);
        if (response?.idData) {
          success(
            "Actualizado correctamente",
            "Se actualizó el tipo de materia",
            "Aceptar",
            () => {},
            true
          );
        } else {
          errorAlert(
            "Ocurrió un error al actualizar",
            "No se pudo actualizar el tipo de materia",
            () => {}
          );
        }
        refetch();
      }
    });
  };

  const transformData = () => {
    let newData = [];
    if (data && data.subjectTypes) {
      data.subjectTypes.forEach((element) => {
        newData.push({
          id: element.value,
          name: element.label,
          createdAt: element.createdAt,
          updatedAt: element.updatedAt
        });
      });
    }
    return newData;
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.contTitle}>
          <h1 className={style.title}>Lista de tipo de materia</h1>
        </div>
      </div>
      <div className={style.contTable}>
        <DataTable
          data={transformData()}
          clickModal={changeModalState("addData")}
          setItem={setItem}
        >
          <div className={style.editModal}>
            <Formik
              initialValues={{ name: item?.name }}
              validationSchema={Yup.object({
                name: Yup.string().required("Este campo es requerido.")
              })}
              onSubmit={handleUpdate}
            >
              {({ values }) => (
                <Form className={style.formEdit}>
                  <Input
                    name="name"
                    title="Editar nombre"
                    value={values.name}
                  />
                  <BtnPrincipal text="Guardar" />
                </Form>
              )}
            </Formik>
          </div>
        </DataTable>
      </div>
      <Modal
        title="Crear tipo de materia"
        show={activeModals.addData}
        close={changeModalState("addData")}
      >
        <div className={style.modalAdd}>
          <Formik
            initialValues={{ name: "" }}
            validationSchema={Yup.object({
              name: Yup.string().required("Este campo es requerido.")
            })}
            onSubmit={handleSubmit}
          >
            <Form className={style.formInput}>
              <div className={style.inputalt}>
                <Input name="name" icon="fas fa-plus" type="text" />
              </div>
              <BtnPrincipal text="Guardar" />
            </Form>
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

export default FormSubjectType;
