import React from "react";
import style from "assets/css/Dashboard/HomeDivision.module.css";
import CardSStudentCareer from "components/Card/division/CardSStudentCareer";
import classNames from "classnames";
import ProgressBar from "@ramonak/react-progress-bar";

import photo from "assets/img/theme/fondoTecnm.jpeg";
import { Link } from "react-router-dom";

function HomeDivision({ data, classrooms, students }) {
  return (
    <div className={style.container}>
      <div className={style.contPrincipal}>
        <div className={style.welcome}>
          <h1 className={style.greet}>
            Hola, <span className={style.name}>{data?.user?.name}</span> 👋
          </h1>
          <p className={style.desc}>
            Bienvenido de nuevo, un placer tenerte de vuelta
          </p>
        </div>
        <div className={style.career}>
          <CardSStudentCareer
            data={{
              aulas: classrooms.classrooms.length,
              estudiantes: students
            }}
          />
        </div>
        <div className={style.relevantData}>
          <div className={classNames(style.groups, style.cardRelevant)}>
            <HeaderCard title="Grupos" link="/personal/subject" />
            <div className={style.contGroups}>
              <ItemGroup
                group="X1"
                subject="Fundamentos de programación"
                capacity={35}
                students={28}
                period="enero - junio 2022"
              />
              <ItemGroup
                group="X1"
                subject="Fundamentos de programación"
                capacity={32}
                students={22}
                period="enero - junio 2022"
              />
              <ItemGroup
                group="X1"
                subject="Fundamentos de programación"
                capacity={30}
                students={20}
                period="enero - junio 2022"
              />
              <ItemGroup
                group="X1"
                subject="Fundamentos de programación"
                capacity={35}
                students={30}
                period="enero - junio 2022"
              />
              <ItemGroup
                group="X1"
                subject="Fundamentos de programación"
                capacity={31}
                students={19}
                period="enero - junio 2022"
              />
            </div>
          </div>
          <div className={classNames(style.academicArea, style.cardRelevant)}>
            <HeaderCard
              title="Áreas académicas"
              link="/personal/createAcademicArea"
            />
            <div className={style.contAcademicArea}></div>
          </div>
          <div className={classNames(style.classroom, style.cardRelevant)}>
            <HeaderCard title="Aulas" link="/personal/subject" />
            <div className={style.contClassroom}>
              {classrooms.classrooms.slice(0, 5).map((item) => (
                <ItemClassroom
                  key={item._id}
                  name={item.key}
                  photo={photo}
                  timeCrossing={item.timeCrossing}
                />
              ))}
              {/* <ItemClassroom name="Aula D1" photo={photo} timeCrossing />
              <ItemClassroom name="Aula D2" photo={photo} timeCrossing />
              <ItemClassroom name="Aula D3" photo={photo} timeCrossing />
              <ItemClassroom name="Aula D4" photo={photo} />
              <ItemClassroom name="Aula D5" photo={photo} timeCrossing /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={style.contSchoolPeriods}></div>
    </div>
  );
}

const HeaderCard = ({ title, link }) => (
  <div className={style.headerCard}>
    <p className={style.title}>{title}</p>
    <Link to={link} className={style.btn}>
      Todos
    </Link>
  </div>
);

const ItemGroup = ({ group, subject, capacity, students, period }) => (
  <div className={style.itemGroup}>
    <div className={style.group}>
      <p className={style.text}>{group}</p>
    </div>
    <div className={style.dataGroup}>
      <p className={style.subject}>{subject}</p>
      <p className={style.period}>{period}</p>
    </div>
    <div className={style.progress}>
      <p className={style.text}>{`${students} / ${capacity}`}</p>
      <ProgressBar
        completed={students ? students : 0}
        maxCompleted={capacity}
        baseBgColor="#bbc3ec"
        height=".3rem"
        isLabelVisible={false}
        bgColor="#084de2"
        animateOnRender={true}
      />
    </div>
  </div>
);

const ItemClassroom = ({ name, photo, timeCrossing }) => (
  <div className={style.itemClassroom}>
    <div className={style.contPhoto}>
      <img className={style.photo} src={photo} alt="" />
      <div
        className={
          timeCrossing
            ? classNames(style.status, style.correctly)
            : style.status
        }
      ></div>
    </div>
    <p className={style.name}>{name}</p>
  </div>
);

export default HomeDivision;
