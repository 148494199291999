import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useAddEconomicData() {
  const [loadingEconomic, setLoadingEconomic] = useState(false);

  const addEconomic = async (token, economicData) => {
    try {
      setLoadingEconomic(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_ECONOMIC_URL =
        process.env.REACT_APP_REST_URI + "/economic-data";
      const economicResponse = await axios.post(
        ADD_ECONOMIC_URL,
        economicData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(economicResponse?.data);
      return economicResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response);
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingEconomic(false);
    }
  };

  return { loadingEconomic, addEconomic };
}

export default useAddEconomicData;
