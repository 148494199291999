import * as Yup from "yup";

import validateCurp from "utils/validateCurp";

export const registerSchema = Yup.object({
  lastname: Yup.string().required("Este campo es requerido."),
  lastnameTwo: Yup.string().required("Este campo es requerido."),
  name: Yup.string().required("Este campo es requerido."),
  curp: Yup.string()
    .required("Este campo es requerido.")
    .test(
      "curp-valida",
      "Ingresa una curp válida",
      (curp = "default") => validateCurp(curp) === "Válido"
    ),
  email: Yup.string()
    .email("Ingresa un correo válido.")
    .required("Este campo es requerido."),
  confirmEmail: Yup.string()
    .email("Ingresa un correo válido.")
    .required("Este campo es requerido."),
  privacy: Yup.boolean()
    .required("Debes aceptar la política de privacidad.")
    .oneOf([true], "Debes aceptar la política de privacidad."),
});
