import React from "react";

import style from "assets/css/components/ButtonNav.module.css";
import classnames from "classnames";

function ButtonNav({ id, position, text, ...rootDOMAttributes }) {
  return (
    <button
      className={
        position === id
          ? classnames(style.btnOption, style.active)
          : style.btnOption
      }
      disabled={position !== id}
      {...rootDOMAttributes}
    >
      {text}
    </button>
  );
}

export default ButtonNav;
