export const dashboards = [
  {
    role: "Aspirante",
    dashboard: "/aspirant/me"
  },
  {
    role: "División de estudios profesionales",
    dashboard: "/personal/study-division"
  },
  {
    role: "Estudiante",
    dashboard: "/student/me"
  }
];
