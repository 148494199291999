import React from "react";

import styles from "assets/css/PrivacyPolicy/PrivacyPolicy.module.css";
import imageTec from "assets/img/theme/tecNMWhite.svg";

function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.contImage}>
          <img className={styles.image} src={imageTec} alt="" />
        </div>
        <div className={styles.headerTitle}>
          <p className={styles.title}>Tecnologico Nacional de México</p>
          <p className={styles.desc}>Campus istmo</p>
        </div>
      </div>
      <div className={styles.contText}>
        <div className={styles.contTitle}>
          <h1 className={styles.title}>Políticas de privacidad</h1>
        </div>
        <p className={styles.text}>
          En cumplimiento con lo establecido por la Ley Federal de Transparencia
          y Acceso a la Información Pública Gubernamental, se informa de la
          política de privacidad y manejo de datos personales de este sitio Web
          y/o Aplicaciones Móviles (en lo sucesivo “Sistemas”).
        </p>
        <p className={styles.text}>
          Los datos solicitados en el módulo de contacto o cualquier otro tipo
          de registro colocado en los Sistemas, únicamente serán utilizados para
          establecer comunicación con el usuario como respuesta a la petición o
          comentario realizado.
        </p>
        <p className={styles.text}>
          Asimismo, los datos que ingresen a los Sistemas no serán difundidos,
          distribuidos o comercializados salvo lo estrictamente señalado en el
          art. 22 de la Ley Federal de Transparencia y Acceso a la Información
          Pública Gubernamental. En caso de que el usuario desee anular la
          información proporcionada a la base de datos, podrán solicitar la baja
          a través del correo electrónico:
          <span> comunicacion@itistmo.edu.mx</span> o por escrito a la Dirección
          del Centro de Computo, ubicada en Carretera Panamericana km. 821, Hca.
          Juchitán de Zaragoza Oax. C.P. 70000. Los comentarios o peticiones
          serán incluidos exclusivamente para informes estadísticos y dar
          seguimiento a los avances institucionales, sin permitir identificar al
          individuo o usuario.
        </p>
        <p className={styles.text}>
          Los servicios y el contenido de los Sistemas están protegidos por la
          Ley de Derechos de Autor y no se puede copiar, modificar, interpretar,
          desplegar o vender cualquier contenido que aparezca en o por medio de
          este sitio Web sin la autorización del Instituto Tecnológico del
          Istmo.
        </p>
        <p className={styles.text}>
          El sitio Pagos ITI se reserva el derecho de modificar las políticas de
          privacidad, con la finalidad de adaptar nuevos requerimientos
          legislativos, jurisprudenciales, técnicos o por cualquier otro motivo
          que permita brindar mejores servicios y contenidos a los usuarios sin
          contravenir lo establecido en la Ley de Transparencia y Acceso a la
          Información Pública.
        </p>
        <p className={styles.text}>
          Las presentes políticas de privacidad son parte de uso de los
          Servicios y, en consecuencia, la utilidad que el usuario pudiera tener
          de los Servicios implica haber leído, entendido y aceptado los
          términos antes expuestos. Si el usuario no estuviera de acuerdo con
          tales políticas, tendrá la opción de no proporcionar ninguna
          información personal.
        </p>
        <p className={styles.text}>
          Para mayor información puede escribir a{" "}
          <span>comunicacion@itistmo.edu.mx</span>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
