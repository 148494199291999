import { useEffect, useState } from 'react';
import axios from 'axios';
import { error, success } from 'components/Alerts';

const useGetAcademicArea = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const token = window.localStorage.getItem('jwt');
      if (!token) return;
      const { REACT_APP_GRAPHQL_URI: uri } = process.env;
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.post(
        uri,
        {
          query: `{
            academicAreas{
              value: id
              label: name
              school_subjects {
                value: _id
                label: name
              }
            }
          }
          `,
        },
        { headers },
      );
      console.log(data.data.academicAreas);

      setList(data.data.academicAreas);
    } catch (error) {
      setErr(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return { list, loading, err };
};

export default useGetAcademicArea;
