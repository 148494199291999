import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Collapse from "components/Collapse/Collapse";

import { Formik, Form } from "formik";
import { GET_SPECIALTIES_CAREER } from "utils/graphql/querys";
import ErrorData from "components/Error/ErrorData";
import Loading from "components/Loading";
import { error as errorAlert, success } from "../../../components/Alerts";
import ConsoleHelper from "ConsoleHelper";
import Input from "components/Inputs/Input";
import InputDate from "components/Inputs/InputDate";
import formatDate from "utils/formatDate";
import {
  reticlesInitialState,
  reticlesValidationSchema
} from "utils/schemas/reticles.schemas";

function FormCreateReticle() {
  const { data, loading, error, refetch } = useQuery(GET_SPECIALTIES_CAREER);
  // const [specialties, setSpecialties] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);

  useEffect(() => {
    let newData = [];
    data?.specialties?.forEach((element) => {
      if (element.reticle && element.reticle.length === 0) {
        newData.push({ value: element.value, label: element.label });
      }
    });
    // setSpecialties(newData);
  }, [data]);

  if (loading || loadingAdd) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleSubmit = async (values) => {
    try {
      setLoadingAdd(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/reticles";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      if (response) {
        success(
          "Agregado correctamente",
          "La reticula ha sido creado correctamente.",
          "Aceptar",
          () => refetch(),
          true
        );
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      errorAlert(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoadingAdd(false);
    }
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Crear reticula</h1>
      <div className={style.contForm}>
        <Formik
          initialValues={reticlesInitialState}
          validationSchema={reticlesValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { handleChange, touched, errors } = props;

            return (
              <Form>
                <Collapse title="Crear nueva reticula">
                  <div className={classnames(style.formDate, style.twoColumn)}>
                    <InputSelect
                      error={touched.career && errors.career}
                      options={data.careers}
                      name="career"
                      title="Carrera"
                      onChange={(selectedOption) => {
                        handleChange("career")(selectedOption.value);
                      }}
                    />
                    <Input
                      name="officialKey"
                      title="Clave oficial"
                      type="text"
                    />
                    <Input name="tecnmKey" title="Clave tecNM" type="text" />
                  </div>
                </Collapse>
                <Collapse title="Creditos, carga y perido">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <Input
                      name="totalCredits"
                      title="Total de creditos"
                      type="number"
                    />
                    <Input
                      name="minimunLoad"
                      title="Carga minima"
                      type="number"
                    />
                    <Input
                      name="maximumLoad"
                      title="Carga maxima"
                      type="number"
                    />
                    <InputDate
                      error={touched.startDate && errors.startDate}
                      name="startDate"
                      title="Periodo de inicio"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("startDate")(formatDate(dateFromValue));
                      }}
                    />
                    <InputDate
                      error={touched.liquidationDate && errors.liquidationDate}
                      name="liquidationDate"
                      title="Periodo final"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("liquidationDate")(
                          formatDate(dateFromValue)
                        );
                      }}
                    />
                  </div>
                </Collapse>
                <div className={style.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormCreateReticle;
