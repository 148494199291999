import React from "react";

import style from "assets/css/components/InputCheck.module.css";

import { useField } from "formik";

function InputCheck({ text, link, TxtLink, ...props }) {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div className={style.container}>
      <label className={style.contInput}>
        <input {...field} {...props} className={style.input} type="checkbox" />
        <span className={style.checkmark}>
          <p className={style.icon}>
            <i className="fas fa-check" />
          </p>
        </span>
        {link ? (
          <>
            {text}
            <a
              className={style.txtLink}
              target="_blank"
              rel="noopener noreferrer"
              href="/privacy"
            >
              {TxtLink}
            </a>
          </>
        ) : (
          text
        )}
      </label>
      {meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default InputCheck;
