import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";

import HomeAspirant from "../aspirant/HomeAspirant";
import DashboardAlt from "./DashboardAlt";
import Loading from "components/Loading";

import { GET_USER_ID } from "utils/graphql/querys";
import Profile from "components/Profile/Profile";
import { useLocalStorage } from "hooks/useLocalStorage";
import FormAspirante from "../forms/FormAspirante";
import ErrorData from "components/Error/ErrorData";

function DashboardAspirant() {
  const [id] = useLocalStorage("id", null);
  const [role] = useLocalStorage("role", null);
  const history = useRef(useHistory());

  useEffect(() => {
    if (role && role !== "Aspirante") {
      history.current.push("/404");
    }
  });

  const [children, setChildren] = useState(null);
  const [noti, setNoti] = useState(false);
  const [active, setActive] = useState({
    inicio: true,
    perfil: false,
    formularios: false,
  });
  const { loading, data, error, refetch } = useQuery(GET_USER_ID, {
    variables: {
      IdUser: id,
      State: "LIVE",
    },
  });

  if (loading) return <Loading />;

  if (error) {
    return <ErrorData btn />;
  }

  const showProfile = () => {
    setChildren(<Profile idUser={id} />);
  };

  const getObservations = () => {
    if (data && data.user?.student?.processStatus === "observaciones") {
      return true;
    }
    return false;
  };

  const handleClickProgress = () => {
    const position = getPositionFormsAspirant(data);
    if (position !== 8) {
      setActive({
        inicio: false,
        perfil: false,
        formularios: true,
      });
      setChildren(
        <FormAspirante
          formPosition={position}
          idUser={id}
          idStudent={data?.user?.student?.id}
          dataUser={data?.user}
          edit={getObservations()}
        />
      );
    }
  };

  const getPositionFormsAspirant = (data) => {
    if (data && data.user && data.user.student) {
      const student = data.user.student;
      const user = data.user;
      if (
        student.processStatus === "enviado" ||
        student.processStatus === "aspirante" ||
        student.processStatus === "pagado" ||
        student.processStatus === "pagar" ||
        student.processStatus === "corregido"
      )
        return 8;
      if (student.processStatus === "formularios") {
        if (user.emergencyData) return 7;
        if (student.economicData) return 6;
        if (user.parents?.length) return 5;
        if (user.addresses?.length) return 4;
        if (student.highSchool) return 3;
        if (student.careerOptions?.length) return 2;
        return 1;
      }
      if (student.processStatus === "observaciones") return 7;
      if (student.processStatus === "registrado") return 0;
    }
    return 0;
  };

  const getLengthNotificationsViews = () => {
    let count = 0;
    if (data && data.user.notifications) {
      const notifications = data.user.notifications;
      notifications.forEach((noti) => {
        if (!noti.read) {
          count++;
        }
      });
      return count;
    }
    return count;
  };

  const routes = [
    {
      name: "Inicio",
      icon: "fas fa-home",
      onClick: () => {
        setActive({
          inicio: true,
          perfil: false,
          formularios: false,
        });
        setChildren(
          <HomeAspirant
            setChildren={setChildren}
            data={data}
            refetch={refetch}
            handleClickProgress={handleClickProgress}
            getPositionFormsAspirant={getPositionFormsAspirant}
            countNoti={getLengthNotificationsViews()}
            noti={noti}
            observations={getObservations()}
          />
        );
      },
      active: active.inicio,
    },
    {
      name: "Perfil",
      icon: "fas fa-user",
      onClick: () => {
        setActive({
          inicio: false,
          perfil: true,
          formularios: false,
        });
        setChildren(<Profile idUser={id} />);
      },
      active: active.perfil,
    },
    {
      name: "Formularios",
      icon: "fas fa-user-edit",
      onClick: handleClickProgress,
      active: active.formularios,
    },
  ];

  return (
    <DashboardAlt
      onClickPhoto={showProfile}
      routesAspirant={routes}
      noti={noti}
      setNoti={setNoti}
      countNoti={getLengthNotificationsViews()}
    >
      {children ? (
        children
      ) : (
        <HomeAspirant
          data={data}
          refetch={refetch}
          handleClickProgress={handleClickProgress}
          getPositionFormsAspirant={getPositionFormsAspirant}
          countNoti={getLengthNotificationsViews()}
          noti={noti}
          observations={getObservations()}
        />
      )}
    </DashboardAlt>
  );
}

export default DashboardAspirant;
