import React from "react";

import style from "assets/css/Card/CardClass.module.css";
import classNames from "classnames";

function CardClass({
  image,
  subject,
  teacher,
  photoTeacher,
  group,
  credit,
  hours,
}) {
  return (
    <div className={style.card}>
      <div className={style.subject}>
        <div className={style.contImage}>
          <img className={style.image} src={image} alt="" />
        </div>
        <div className={style.info}>
          <p className={style.name}>{subject}</p>
          <div className={style.contTeacher}>
            <img className={style.photo} src={photoTeacher} alt="" />
            <p className={style.teacher}>{teacher}</p>
          </div>
        </div>
      </div>
      <div className={style.subjectData}>
        <p className={style.desc}>
          <span className={style.icon}>
            <i className="fas fa-laptop-house"></i>
          </span>
          Grupo {group}
        </p>
        <p className={style.desc}>
          <span className={style.icon}>
            <i className="fas fa-coins"></i>
          </span>
          {credit} Creditos
        </p>
        <p className={style.desc}>
          <span className={style.icon}>
            <i className="far fa-clock"></i>
          </span>
          {hours} Horas/Semana
        </p>
        <div className={style.students}>
          <img className={style.photoStudent} src={image} alt="" />
          <img
            className={classNames(style.photoStudent, style.second)}
            src={image}
            alt=""
          />
          <img
            className={classNames(style.photoStudent, style.third)}
            src={image}
            alt=""
          />
          <div className={style.count}>+30</div>
        </div>
      </div>
    </div>
  );
}

export default CardClass;
