import { useState } from "react";

export default function useReCaptcha() {
  const [token, setToken] = useState(null);

  const getToken = async () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY, {
          action: "submit",
        })
        .then((token) => {
          setToken(token);
        });
    });
  };

  return { token, getToken };
}
