import React from "react";

import style from "assets/css/components/InputIcon.module.css";

import { useField } from "formik";

function InputIcon({ icon, placeholder, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className={style.container}>
      <div className={style.contInput}>
        <div className={style.contIcon}>
          <p className={style.icon}>
            <i className={icon}></i>
          </p>
        </div>
        <input
          {...field}
          {...props}
          className={style.input}
          placeholder={placeholder}
        />
      </div>
      {meta.touched && meta.error && (
        <p className={style.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default InputIcon;
