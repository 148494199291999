import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormAcademicArea from "../forms/FormAcademicArea";

function CreateAcademicArea() {
  return (
    <DashboardAlt>
      <FormAcademicArea />
    </DashboardAlt>
  );
}

export default CreateAcademicArea;
