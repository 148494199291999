import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import style from "assets/css/Forms/Form.module.css";

import Dropzone from "components/Dropzone";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import { error, success } from "components/Alerts";
import Loading from "components/Loading";
import InfoPhoto from "components/InfoPhoto";
import ConsoleHelper from "ConsoleHelper";
import { useLocalStorage } from "hooks/useLocalStorage";
import DashboardAlt from "../dashboards/DashboardAlt";

function FormUploadPhoto() {
  const [picture, setPicture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user] = useLocalStorage("id", null);
  const [token] = useLocalStorage("jwt", null);

  if (loading) return <Loading />;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (picture.length === 0) {
      error("Verificar", "Verifica que has subido tu fotografía");
    } else {
      ConsoleHelper(picture);
      const response = await fillFormData();
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          success(
            "Fotografía subida correctamente",
            "Se ha actualizado tu fotografía correctamente",
            "Aceptar",
            () => window.location.reload(true)
          );
        }
      }
    }
  };

  const fillFormData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", picture, "photo_" + uuidv4() + ".jpg");
      formData.append("refId", user);
      formData.append("ref", "user");
      formData.append("source", "users-permissions");
      formData.append("field", "profilePhoto");
      const BEARER_TOKEN = `Bearer ${token}`;
      const UPLOAD_PHOTO_URL = `${process.env.REACT_APP_REST_URI}/upload`;
      const responseUpload = await axios.post(UPLOAD_PHOTO_URL, formData, {
        headers: { Authorization: BEARER_TOKEN }
      });
      ConsoleHelper(responseUpload);
      const uploaded = responseUpload?.data;
      if (uploaded) {
        return uploaded;
      }
      return "error";
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardAlt>
      <form onSubmit={handleSubmit}>
        <div className={style.oneColumn}>
          <InfoPhoto />
          <Dropzone crop type="image/jpeg" src={picture} setSrc={setPicture} />
        </div>
        <div className={style.contBtn}>
          <BtnPrincipal text="Subir fotografía" />
        </div>
      </form>
    </DashboardAlt>
  );
}

export default FormUploadPhoto;
