import React, { useState, useEffect } from "react";
import moment from "moment";

import style from "assets/css/Forms/FormAspirante.module.css";
import NavAspirant from "components/Navbars/NavAspirant";
import FormGral from "../aspirant/FormGral";
import FormDegree from "../aspirant/FormDegree";
import FormPreparatory from "../aspirant/FormPreparatory";
import FormAddress from "../aspirant/FormAddress";
import FormTutor from "../aspirant/FormTutor";
import FormEconomy from "../aspirant/FormEconomy";
import FormEmergency from "../aspirant/FormEmergency";
import FormDocumentation from "../aspirant/FormDocumentation";
import ButtonNav from "components/Buttons/ButtonNav";

import { useLocalStorage } from "hooks/useLocalStorage";
import { dataGeneralInitialState } from "utils/states/aspirant";
import getDateBirth from "utils/getDateBirth";

function FormAspirante({ formPosition, idUser, idStudent, dataUser, edit }) {
  const [token] = useLocalStorage("jwt", null);
  const [dataGeneral, setDataGeneral] = useState(dataGeneralInitialState);
  const [position, setPosition] = useState(formPosition);

  useEffect(() => {
    let isMounted = true;
    let newInitialState = {
      ...dataGeneralInitialState,
      app: dataUser?.firstLastName,
      apm: dataUser?.secondLastName,
      name: dataUser?.name,
      dateOfBirth: moment(
        getDateBirth(dataUser?.CURP?.substring(4, 10))
      ).format("YYYY-MM-DD"),
      gender: dataUser?.CURP?.substring(10, 11) === "H" ? "Hombre" : "Mujer",
      curp: dataUser?.CURP,
      email: dataUser?.email,
    };

    if (isMounted) setDataGeneral(newInitialState);

    return () => {
      isMounted = false;
    };
  }, [dataUser]);

  const showFormPosition = () => {
    if (position === 0)
      return (
        <FormGral
          setPosition={setPosition}
          idUser={idUser}
          token={token}
          data={dataGeneral}
        />
      );
    if (position === 1)
      return (
        <FormDegree
          setPosition={setPosition}
          idStudent={idStudent}
          idUser={idUser}
          token={token}
        />
      );
    if (position === 2)
      return (
        <FormPreparatory
          setPosition={setPosition}
          idStudent={idStudent}
          token={token}
        />
      );
    if (position === 3)
      return (
        <FormAddress setPosition={setPosition} idUser={idUser} token={token} />
      );
    if (position === 4)
      return (
        <FormTutor setPosition={setPosition} idUser={idUser} token={token} />
      );
    if (position === 5)
      return (
        <FormEconomy
          setPosition={setPosition}
          idStudent={idStudent}
          token={token}
        />
      );
    if (position === 6)
      return (
        <FormEmergency
          setPosition={setPosition}
          idUser={idUser}
          token={token}
        />
      );
    if (position === 7)
      return (
        <FormDocumentation
          idStudent={idStudent}
          idUser={idUser}
          token={token}
          edit={edit}
        />
      );
  };

  return (
    <div className={style.container}>
      <NavAspirant position={position}>
        <ButtonNav id={0} text="Datos generales" position={position} />
        <ButtonNav id={1} text="Selección de carrera" position={position} />
        <ButtonNav id={2} text="Preparatoria" position={position} />
        <ButtonNav id={3} text="Domicilio" position={position} />
        <ButtonNav id={4} text="Padre / tutor" position={position} />
        <ButtonNav id={5} text="Económico" position={position} />
        <ButtonNav id={6} text="Emergencia" position={position} />
        <ButtonNav id={7} text="Documentación" position={position} />
      </NavAspirant>
      <div className={style.contForm}>{showFormPosition()}</div>
    </div>
  );
}

export default FormAspirante;
