import React, { useState } from "react";

import style from "assets/css/Forms/FormAlt.module.css";
import DataTable from "../tables/DataTable";
import { useQuery } from "@apollo/client";
import { GET_LIST_CAREERS } from "utils/graphql/querys";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";
import useUpdateData from "hooks/useUpdateData";
import Swal from "sweetalert2";
import { error as errorAlert, success } from "../../../components/Alerts";
import ConsoleHelper from "ConsoleHelper";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "components/Inputs/Input";
import BtnPrincipal from "components/Buttons/BtnPrincipal";

function FormCreateDegree() {
  const { data, loading, error, refetch } = useQuery(GET_LIST_CAREERS);
  const [item, setItem] = useState();
  const { updateData } = useUpdateData();

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleUpdate = async (values) => {
    Swal.fire({
      title: "Editar nombre",
      text: "Confirmar nuevo nombre del nivel",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, editar!",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = window.localStorage.getItem("jwt");
        const table = "/careers/";
        ConsoleHelper(item);
        ConsoleHelper(values);
        const response = await updateData(item.id, token, table, {
          name: values.name,
          careerKey: values.careerKey
        });
        ConsoleHelper(response);
        if (response?.idData) {
          success(
            "Actualizado correctamente",
            "Se actualizó la carrera",
            "Aceptar",
            () => {},
            true
          );
        } else {
          errorAlert(
            "Ocurrió un error al actualizar",
            "No se pudo actualizar la carrera",
            () => {}
          );
        }
        refetch();
      }
    });
  };

  const transformData = () => {
    let newData = [];
    if (data && data.careers) {
      data.careers.forEach((element) => {
        newData.push({
          id: element.id,
          name: element.name,
          secondary: element.careerKey
        });
      });
    }
    return newData;
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h1 className={style.title}>Carreras registradas</h1>
      </div>
      <div className={style.contTable}>
        {/* <DataTable data={transformData()} secondary="Clave Carrera" /> */}
        <DataTable
          data={transformData()}
          secondary="Clave Carrera"
          setItem={setItem}
          textLink="Crear carrera"
          formcreate="/personal/newDegree"
        >
          <div className={style.editModal}>
            <Formik
              initialValues={{ name: item?.name, careerKey: item?.secondary }}
              validationSchema={Yup.object({
                name: Yup.string().required("Este campo es requerido.")
              })}
              onSubmit={handleUpdate}
            >
              {({ values }) => (
                <Form className={style.formEdit}>
                  <Input
                    name="name"
                    title="Nombre"
                    value={values.name.toLowerCase()}
                  />
                  <Input
                    name="careerKey"
                    title="Clave de carrera"
                    value={values?.careerKey}
                  />
                  <BtnPrincipal text="Guardar" />
                </Form>
              )}
            </Formik>
          </div>
        </DataTable>
      </div>
    </div>
  );
}

export default FormCreateDegree;
