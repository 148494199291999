import React, { useState } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";

import style from "../../assets/css/Card/CardClassRoom.module.css";

import Modal from "components/modal/Modal";
import Input from "components/Inputs/Input";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import { success, error } from "components/Alerts";
import InputDate from "components/Inputs/InputDate";
import Loading from "components/Loading";
import ConsoleHelper from "ConsoleHelper";

function CardClassRoom({ refetch, classroom, capacity, id, ...rest }) {
  const [isHover, setIsHover] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (formValues) => {
    ConsoleHelper(formValues);
    try {
      setIsLoading(true);
      if (formValues.capacity > capacity) {
        error(
          "Verifique su información",
          "La cantidad que intentas ingresar es mayor a la capacidad soportada por el aula."
        );
      } else {
        const values = { ...formValues, classroom: id };
        ConsoleHelper(values);
        const token = window.localStorage.getItem("jwt");
        if (!token) return;
        const { REACT_APP_REST_URI: uri } = process.env;
        const API_URL = uri + "/classroom-admisions";
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.post(API_URL, values, { headers });
        if (response.status === 200) {
          setModalIsOpen(false);
          setIsLoading(false);
          success(
            "Agregado correctamente",
            "El aula para el examen de admisión ha sido creado correctamente.",
            "Aceptar",
            () => refetch()
          );
        }
      }
    } catch {
      error(
        "Error inesperado",
        "Lo sentimos esto no debió parar, estamos trabajando en eso."
      );
    } finally {
      setModalIsOpen(false);
      setIsLoading(false);
    }
  };

  const showCapacity = () => {
    setIsHover(!isHover);
  };

  const onToggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div
        className={style.contAula}
        onMouseEnter={showCapacity}
        onMouseLeave={showCapacity}
        onClick={onToggleModal}
        {...rest}
      >
        <div className={style.aula}>
          <p className={style.desc}>{classroom}</p>
        </div>
        <div className={style.contText}>
          <p className={style.title}>{isHover ? capacity : "Aula"}</p>
        </div>
      </div>
      <Modal
        title="Asignación de aula"
        show={modalIsOpen}
        close={onToggleModal}
      >
        <Formik
          initialValues={{
            capacity: "",
            dateExam: "",
            hourExam: "",
          }}
          validationSchema={Yup.object({
            capacity: Yup.string().required("Este campo es requerido."),
            dateExam: Yup.date().required("Debes seleccionar una fecha."),
            hourExam: Yup.string().required("Debes seleccionar una hora."),
          })}
          onSubmit={onSubmitForm}
        >
          {(props) => {
            return (
              <Form className={style.contModal}>
                <InputDate
                  error={props.touched.dateExam && props.errors.dateExam}
                  name="dateExam"
                  title="Fecha de examen en aula"
                  time={false}
                  date={true}
                  onChange={(date) => {
                    if (date.format) {
                      props.handleChange("dateExam")(date.format("YYYY-MM-DD"));
                    }
                  }}
                />
                <InputDate
                  error={props.touched.hourExam && props.errors.hourExam}
                  name="hourExam"
                  title="Hora de examen en aula"
                  time={true}
                  date={false}
                  onChange={(date) => {
                    if (date.format) {
                      props.handleChange("hourExam")(date.format("HH:mm:ss"));
                    }
                  }}
                />
                <Input
                  name="capacity"
                  title="Cantidad de alumnos"
                  type="number"
                  subtitle={`El aula tiene un cupo de ${capacity}`}
                  placeholder="Cantidad de alumnos"
                />
                <BtnPrincipal text="Guardar" />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

export default CardClassRoom;
