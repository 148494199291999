import React from "react";

import style from "assets/css/components/ToggleText.module.css";
import Toggle from "views/pages/components/Toggle";
import ToggleNew from "views/pages/components/ToggleNew";

function ToggleText({ title, subtitle, other, ...props }) {
  return (
    <div className={style.container}>
      <p className={style.title}>
        {title}
        {subtitle && <span className={style.subtitle}>({subtitle})</span>}
      </p>
      <div className={style.contToggle}>
        {other ? <ToggleNew {...props} /> : <Toggle invert {...props} />}
      </div>
    </div>
  );
}

export default ToggleText;
