import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useZipCode() {
  const [loading, setLoading] = useState(false);

  const getZipCode = async (zipCode) => {
    let data = [];
    let entity = "";
    let town = "";
    try {
      setLoading(true);
      const ZIPCODE_URL = process.env.REACT_APP_CP_URL;
      const response = await axios.post(ZIPCODE_URL, { postal_code: zipCode });
      const zipCodes = response?.data;
      zipCodes.map((cp) => {
        data.push({
          value: cp.id,
          label: cp.d_tipo_asenta + " " + cp.d_asenta,
        });
        entity = cp.d_estado;
        town = cp.D_mnpio;
        return false;
      });
      const objCp = { data, entity, town };
      return objCp;
    } catch (e) {
      ConsoleHelper(e.response.data, "error");
    } finally {
      setLoading(false);
    }
  };

  return { loading, getZipCode };
}

export default useZipCode;
