import React from "react";

// import CardAspirant from "components/Card/CardAspirant";
import TemplateUser from "./components/TemplateUser";
import FormLoginAspirant from "./forms/FormLoginAspirant";

function LoginAspirant() {
  return (
    <TemplateUser>
      {/* <CardAspirant /> */}
      <FormLoginAspirant />
    </TemplateUser>
  );
}

export default LoginAspirant;
