import React from "react";

import styles from "../../assets/css/components/Input.module.css";
import ReactDatetime from "react-datetime";
import "moment/locale/es-mx";

function InputDate({ error, title, subtitle, time, date, format, ...props }) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {title}
        {subtitle ? (
          <span className={styles.subtitle}>({subtitle})</span>
        ) : null}
      </p>
      <ReactDatetime
        {...props}
        inputProps={{
          placeholder: "Seleccionar",
        }}
        locale="sp"
        timeFormat={time}
        dateFormat={format ? format : date}
        // dateFormat="YYYY"
      />
      {error && <p className={styles.txtWarning}>{error}</p>}
    </div>
  );
}

export default InputDate;
