import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormAddSubject from "../forms/FormAddSubject";

function AddSubject() {
  return (
    <DashboardAlt>
      <FormAddSubject />
    </DashboardAlt>
  );
}

export default AddSubject;
