import React, { useState, useEffect } from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";
import axios from "axios";

import { Form, Formik } from "formik";
import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Loading from "components/Loading";
import ToggleText from "components/Inputs/ToggleText";
import Modal from "components/modal/Modal";
import ItemOption from "components/ItemOption";
import CardGroup from "components/Card/CardGroup";
import InputDate from "components/Inputs/InputDate";
import { createGroupInitialState } from "utils/states/group";
import formatTime from "utils/formatTime";
import { useQuery } from "@apollo/client";
import { GET_USER_ID } from "../../../utils/graphql/querys";

import {
  error as errorAlert,
  success,
  warning,
  info,
} from "../../../components/Alerts";

import { addGroupSchema } from "utils/schemas/groups.schemas";
// import useGetPermissions from "hooks/useGetPermissions";

const modalStates = { days: false, schedule: false };

function FormGroups() {
  const [activeModals, setActiveModals] = useState();

  // const [days, setDays] = useState({
  //   monday: true,
  //   tuesday: true,
  //   wednesday: true,
  //   thursday: true,
  //   friday: true,
  //   // saturday: true,
  //   // sunday: false
  // });

  const days = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo",
  ];

  const [days2, setDays2] = useState([
    { day: "lunes", active: true },
    { day: "martes", active: true },
    { day: "miercoles", active: true },
    { day: "jueves", active: true },
    { day: "viernes", active: true },
    { day: "sabado", active: false },
    { day: "domingo", active: false },
  ]);
  const [hourStart, setHourStart] = useState();
  const [hourEnd, setHourEnd] = useState();
  const [classroom, setClassroom] = useState();
  const [career, setCareer] = useState();
  const [schoolPeriod, setSchoolPeriod] = useState();
  const [schoolSubject, setSchoolSubject] = useState();
  const [key, setKey] = useState();
  const [capacity, setCapacity] = useState();
  const [semester, setSemester] = useState();

  const [careers, setCareers] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [reticle, setReticle] = useState();
  const [first, setFirts] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [modalDays, setModalDays] = useState();

  const semesters = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
  ];

  const { REACT_APP_REST_URI: uri } = process.env;
  const PERIODS_API = uri + "/school-periods?status=true";
  const CLASSROOMS_API = uri + "/classrooms";
  const GET_PERSONAL_API =
    uri + `/personals?user=${window.localStorage.getItem("id")}`;

  const token = window.localStorage.getItem("jwt");
  const headers = { Authorization: `Bearer ${token}` };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const getPeriods = await axios.get(PERIODS_API, { headers });
        const getClassrooms = await axios.get(CLASSROOMS_API, { headers });
        //se requiere el permiso a find en tabla personal para poder funcionar
        const getPersonal = await axios.get(GET_PERSONAL_API, { headers });

        if (getPersonal.data[0].permisoCareers) {
          setCareers(
            getPersonal.data[0].permisoCareers.map((career) => ({
              value: career.id,
              label: career.name,
              createdAt: career.createdAt,
              updatedAt: career.updatedAt,
            }))
          );
        } else {
          setCareers([]);
        }

        setClassrooms(
          getClassrooms.data.map((classroom) => ({
            value: classroom.id,
            label: classroom.key,
            createdAt: classroom.createdAt,
            updatedAt: classroom.updatedAt,
          }))
        );
        let periodAct = getPeriods.data[getPeriods.data.length - 1];

        setPeriods([
          {
            value: periodAct.id,
            label: periodAct.name,
            createdAt: periodAct.createdAt,
            updatedAt: periodAct.updatedAt,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleModalDays = async () => {};

  const handleModal = async (event) => {
    event.preventDefault();
    try {
      if (
        !activeModals.hourStart ||
        !activeModals.hourEnd ||
        !activeModals.classroom
      ) {
        errorAlert("Campos incompletos", "Favor de ingresar todos los datos");
      }

      if (activeModals.hourStart >= activeModals.hourEnd) {
        errorAlert(
          "Error al validad",
          "La hora de inicio no debe ser mayor o igual a la hora de termino"
        );
      }

      if (activeModals.day === "lunes" && first === true) {
        setDays2(
          days2.map((day) => {
            return {
              ...day,
              hourStart: activeModals.hourStart,
              hourEnd: activeModals.hourEnd,
              classroom: activeModals.classroom,
              classroomLabel: activeModals.classroomLabel,
            };
          })
        );
        setFirts(false);
      } else {
        setDays2(
          days2.map((day) => {
            if (day.day === activeModals.day) {
              return {
                ...day,
                hourStart: activeModals.hourStart,
                hourEnd: activeModals.hourEnd,
                classroom: activeModals.classroom,
                classroomLabel: activeModals.classroomLabel,
              };
            } else {
              return day;
            }
          })
        );
      }

      setActiveModals();
      setHourStart();
      setHourEnd();
      setClassroom();
    } catch (e) {}
  };

  const handleSubmit = async (values) => {
    try {
      let data = {
        key,
        capacity,
        semester,
        schoolPeriod,
        career,
        schoolSubject,
        hours: days2.filter((day) => day.active === true),
        exclusiveCareer: values.checkExclusive,
      };
      console.log(data);
      if (!data.key) {
        errorAlert(
          "Campos incompletos",
          "Favor de ingresar la clave del grupo"
        );
      }
      if (!data.capacity) {
        errorAlert(
          "Campos incompletos",
          "Favor de ingresar la capacidad del grupo"
        );
      }
      if (!data.semester) {
        errorAlert(
          "Campos incompletos",
          "Favor de ingresar el semestre del grupo"
        );
      }

      for (let i = 0; i < days2.length; i++) {
        if (!days2[i].day || !days2[i].hourStart || !days2[i].hourEnd) {
          errorAlert(
            "Campos incompletos",
            "Favor de llenar todos los horarios"
          );
        }
      }

      setLoadingAdd(true);

      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/groups";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, data, { headers });

      if (response.status === 200) {
        success(
          "Agregado correctamente",
          "El grupo ha sido creado correctamente.",
          "Aceptar",
          () => {}
        );
        setLoadingAdd();
      }
    } catch (e) {
      if (e.response.data && e.response.data.statusCode === 400) {
        errorAlert("Error al crear el grupo", `${e.response.data.message}`);
        setLoadingAdd();
      }
    }
  };

  const changeModalState = (day) => async () => {
    setActiveModals(day);
  };

  return (
    <div className={style.container}>
      {loadingAdd ? <Loading /> : <div></div>}
      <h1 className={style.title}>Asignación de horarios - grupos</h1>
      <div className={style.contForm}>
        <Formik
          initialValues={createGroupInitialState}
          onSubmit={handleSubmit}
          validationSchema={addGroupSchema}
        >
          {(props) => {
            const { handleChange, touched, errors, values } = props;

            return (
              <Form>
                <Collapse title="Asignar grupo">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <InputSelect
                      error={touched.career && errors.career}
                      name="career"
                      title="Carrera"
                      options={careers}
                      onChange={async (value) => {
                        handleChange("career")(value.value);
                        setCareer(value.value);
                        const RETICLES_API =
                          uri + `/reticles?career=${value.value}`;
                        const getReticles = await axios.get(RETICLES_API, {
                          headers,
                        });
                        setReticle(
                          getReticles.data[getReticles.data.length - 1]
                        );
                      }}
                    />
                    <InputSelect
                      error={touched.period && errors.period}
                      name="period"
                      title="Periodo"
                      options={periods}
                      onChange={(value) => {
                        handleChange("period")(value.value);
                        setSchoolPeriod(value.value);
                      }}
                    />
                    <Input
                      error={touched.semester && errors.semester}
                      name="semester"
                      title="Semestre"
                      type="number"
                      onChange={(value) => {
                        handleChange("semester")(value.target.value);
                        setSemester(value.target.value);

                        if (reticle.reticleSemesters) {
                          let reticleSubjec = reticle.reticleSemesters.filter(
                            (sem) => sem.semester == value.target.value
                          )[0];
                          if (reticleSubjec && reticleSubjec.reticleSubjects) {
                            let subjects = reticleSubjec.reticleSubjects.map(
                              (sub) => {
                                return {
                                  label: sub.school_subject.name,
                                  value: sub.school_subject.id,
                                };
                              }
                            );
                            setSubjects(subjects);
                          } else {
                            setSubjects([]);
                          }
                        } else {
                          setSubjects([]);
                        }
                      }}
                    />
                  </div>
                </Collapse>
                <Collapse title="Información del grupo">
                  <div
                    className={classnames(style.formDate, style.threeColumn)}
                  >
                    <InputSelect
                      error={touched.subject && errors.subject}
                      name="subject"
                      title="Materia"
                      options={subjects}
                      onChange={(value) => {
                        handleChange("subject")(value.value);
                        setSchoolSubject(value.value);
                      }}
                    />
                    <Input
                      value={key}
                      error={touched.name && errors.name}
                      name="name"
                      title="Grupo"
                      type="text"
                      onChange={(value) => {
                        handleChange("name")(value.target.value);
                        setKey(value.target.value);
                      }}
                    />
                    <Input
                      value={capacity}
                      error={touched.capacity && errors.capacity}
                      name="capacity"
                      title="Capacidad"
                      type="number"
                      onChange={(value) => {
                        handleChange("capacity")(value.target.value);
                        setCapacity(value.target.value);
                      }}
                    />
                    <ToggleText
                      name="checkExclusive"
                      title="Exclusivo carrera"
                      other
                      value={values.checkExclusive}
                    />
                  </div>
                </Collapse>
                <div className={style.contDate}>
                  <h1 className={style.title}>Registro de horario</h1>
                  <div className={style.cards}>
                    {days2.map((day) => {
                      if (day.active === true) {
                        return (
                          <CardGroup
                            onClick={changeModalState(day)}
                            data={day}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
                <div className={style.contButtons}>
                  <BtnPrincipal
                    type="button"
                    text="Días"
                    secondary={true}
                    onClick={() => setModalDays(true)}
                  />
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Modal title="Agregar días" show={modalDays} close={() => setModalDays()}>
        <div className={classnames(style.contModal, style.modalDay)}>
          {days.map((dia) => (
            <ItemOption
              title={dia}
              status={days2.find((day) => day.day === dia).active}
              onClick={() => {
                let daysUpdate = days2.map((day) => {
                  if (day.day === dia) {
                    return { ...day, active: !day.active };
                  } else {
                    return day;
                  }
                });
                setDays2(daysUpdate);
              }}
            />
          ))}
        </div>
      </Modal>
      <Modal
        title={`Dia: ${activeModals?.day}`}
        show={activeModals}
        close={changeModalState()}
      >
        <Formik
        // validationSchema={addGroupModalSchema}
        >
          <Form onSubmit={handleModal}>
            <div className={classnames(style.modalSchedule)}>
              <div className={classnames(style.formDate, style.oneColumn)}>
                <InputDate
                  name="startDate"
                  title="Hora inicial"
                  time={true}
                  date={false}
                  value={activeModals?.hourStart}
                  onChange={(dateFromValue) => {
                    setHourStart(formatTime(dateFromValue));
                    setActiveModals({
                      ...activeModals,
                      hourStart: formatTime(dateFromValue),
                    });
                  }}
                />
                <InputDate
                  name="startDate"
                  title="Hora fin"
                  time={true}
                  date={false}
                  value={activeModals?.hourEnd}
                  onChange={(dateFromValue) => {
                    setHourEnd(formatTime(dateFromValue));
                    setActiveModals({
                      ...activeModals,
                      hourEnd: formatTime(dateFromValue),
                    });
                  }}
                />
                <div className={style.inputLong}>
                  <InputSelect
                    name="classroom"
                    title="Aula"
                    defaultValue={{
                      value: activeModals?.classroom,
                      label: activeModals?.classroomLabel,
                    }}
                    options={classrooms}
                    onChange={(value) => {
                      setClassroom({ label: value.label, value: value.value });
                      setActiveModals({
                        ...activeModals,
                        classroomLabel: value.label,
                        classroom: value.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className={style.contBtn}>
                <BtnPrincipal text="Guardar" />
              </div>
              
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}

export default FormGroups;
