import React, { useState, useEffect } from 'react';

import styles from 'assets/css/Forms/Form.module.css';
import classnames from 'classnames';

import Collapse from 'components/Collapse/Collapse';
import Input from 'components/Inputs/Input';
import InputSelect from 'components/Inputs/InputSelect';
import BtnPrincipal from 'components/Buttons/BtnPrincipal';

import { createMatterSchema } from 'utils/schemas/matter.schemas';
import { createMatterInitialState } from 'utils/states/matter';

import { Formik, Form } from 'formik';
import ConsoleHelper from 'ConsoleHelper';
import { error as errorAlert, success } from '../../../components/Alerts';
import axios from 'axios';
import useGetAcademicArea from 'hooks/useGetAcademicArea';
import { getListsForAddEnglishClasses } from 'api/english';

function FormMateriaReticle({ position }) {
  const [loadingReticle, setLoadingReticle] = useState(false);
  const [optionsAcademic, setOptionsAcademic] = useState([]);
  const [optionsMaterias, setOptionsMaterias] = useState([]);
  const [options, setOptions] = useState([]);
  const [MatterData, setMatterData] = useState({
    theoreticalHours: '',
    practicalHours: '',
    totalCredits: '',
    requestCredits: '',
    officialKey: '',
    certificateOrder: '',
  });
  /*   const [hoursTeoric, setHoursTeoric] = useState(0);
  const [hoursPractic, setHoursPractic] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [preCredits, setPreCredits] = useState(0);
  const [officialKey, setOfficialKey] = useState('');
  const [certificatedOrder, setCertificatedOrder] = useState(''); */

  const { list, err } = useGetAcademicArea();

  const getData = async () => {
    console.log(MatterData);
  };

  const handleSubmit = async (values) => {
    try {
      ConsoleHelper(values);
      setLoadingReticle(true);
      const token = window.localStorage.getItem('jwt');
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + '/reticle-subjects';
      const headers = { Authorization: `Bearer ${token}` };
      // const response = await axios.post(API_URL, values, { headers });
      // console.log(response);
      // if (response) {
      //   success(
      //     "Agregado correctamente",
      //     "La carrera ha sido creado correctamente.",
      //     "Aceptar",
      //     () => {}
      //   );
      // }
    } catch (e) {
      ConsoleHelper(e.response);
      errorAlert('Error inesperado', 'Lo sentimos esto no debió pasar, estamos trabajando en eso.');
    } finally {
      setLoadingReticle(false);
    }
  };
  const handleChangeAcademic = (e) => {
    setOptionsMaterias(null);
    let optionsMatter = [];
    e.school_subjects?.forEach((optionsMaterias) => optionsMatter.push(optionsMaterias));
    setOptions(optionsMatter);
    setOptionsAcademic(e.value);
  };

  const handleChangeMaterias = (value) => {
    setOptionsMaterias(value.value);
  };
  const handleInputChange = (event) => setMatterData({ ...MatterData, [event.target.name]: event.target.value });

  return (
    <>
      <div className={styles.contForm}>
        <Formik initialValues={createMatterInitialState} validationSchema={createMatterSchema} onSubmit={handleSubmit}>
          {(props) => {
            const { errors, handleChange, touched } = props;
            return (
              <Form>
                <Collapse title='Selección de materia'>
                  <div className={classnames(styles.formDate, styles.twoColumn)}>
                    {/* <Input
                      name="theoreticalHours"
                      title="Nombre de la materia"
                      type="text"
                    /> */}
                    <InputSelect
                      error={touched.academicArea && errors.academicArea}
                      name='academicArea'
                      title='Área academica'
                      options={list}
                      onChange={handleChangeAcademic}
                    />
                    <InputSelect
                      error={touched.matter && errors.matter}
                      name='matter'
                      title='Materia'
                      options={options}
                      onChange={handleChangeMaterias}
                    />
                  </div>
                </Collapse>
                <Collapse title='Horas y créditos'>
                  <div className={classnames(styles.formDate, styles.twoColumn)}>
                    <Input
                      name='theoreticalHours'
                      value={MatterData.theoreticalHours}
                      title='Horas teóricas'
                      type='number'
                      onChange={handleInputChange}
                    />
                    <Input
                      name='practicalHours'
                      value={MatterData.practicalHours}
                      title='Horas prácticas'
                      type='number'
                      onChange={handleInputChange}
                    />
                    <Input
                      name='totalCredits'
                      value={MatterData.totalCredits}
                      title='Créditos totales'
                      type='number'
                      onChange={handleInputChange}
                    />
                    <Input
                      name='requestCredits'
                      value={MatterData.requestCredits}
                      title='Créditos prerrequisito'
                      type='number'
                      onChange={handleInputChange}
                    />
                  </div>
                </Collapse>
                <Collapse title='Otros datos'>
                  <div className={classnames(styles.formDate, styles.twoColumn)}>
                    <Input
                      name='officialKey'
                      value={MatterData.officialKey}
                      title='Clave oficial'
                      type='text'
                      onChange={handleInputChange}
                    />
                    <InputSelect
                      error={touched.specialty && errors.specialty}
                      name='specialty'
                      title='Especialidad a la que pertenece'
                      onChange={(selectedOption) => {
                        handleChange('specialty')(selectedOption.value);
                      }}
                    />
                    <Input
                      name='certificateOrder'
                      value={MatterData.certificateOrder}
                      title='Órden en certificado'
                      type='number'
                      onChange={handleInputChange}
                    />
                    <InputSelect
                      error={touched.matterStatus && errors.matterStatus}
                      name='matterStatus'
                      title='Estatus de la materia'
                      onChange={(selectedOption) => {
                        handleChange('matterStatus')(selectedOption.value);
                      }}
                    />
                  </div>
                </Collapse>
                <div className={styles.contBtn}>
                  <BtnPrincipal onClick={getData} text='Guardar' />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default FormMateriaReticle;
