import React from "react";

import style from "assets/css/components/CheckData.module.css";

function CheckData({text}) {
  return (
    <div className={style.contCheck}>
      <div className={style.contIcon}>
        <p className={style.icon}>
          <i className="fas fa-check"></i>
        </p>
      </div>
      <p className={style.text}>{text}</p>
    </div>
  );
}

export default CheckData;
