import * as Yup from "yup";

export const configEnglishSchema = Yup.object({
  englishPeriod: Yup.string().required("Este campo es requerido."),
  englishLevel: Yup.string().required("Este campo es requerido."),
  limit: Yup.string().required("Este campo es requerido."),
  day: Yup.array()
    .min(1, "Selecciona al menos una opción.")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .nullable(),
  hour: Yup.string().required("Selecciona una opción."),
  year: Yup.string().required("Selecciona una opción."),
  teacher: Yup.string().required("Selecciona una opción."),
});
