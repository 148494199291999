import React from "react";

import style from "assets/css/Loading.module.css";
import image from "assets/img/theme/tecNMblue.svg";

function Loading() {
  return (
    <div className={style.container}>
      <img className={style.image} src={image} alt="" />
    </div>
  );
}

export default Loading;
