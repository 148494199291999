import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useQuery, useLazyQuery } from '@apollo/client';

import InputSelect from 'components/Inputs/InputSelect';
import BtnPrincipal from 'components/Buttons/BtnPrincipal';
import Modal from 'components/modal/Modal';
import Collapse from 'components/Collapse/Collapse';
import ShowSubject from 'components/Reticle/ShowSubject';
import ErrorData from 'components/Error/ErrorData';
import Loading from 'components/Loading';
import FormMateriaReticle from '../forms/FormMateriaReticle';
import { GET_RETICLES_SPECIALTIES, GET_RETICLE } from 'utils/graphql/querys';
import transformToRows from 'utils/transformToRows';
import { selectReticleInitialState, selectReticleValidationSchema } from 'utils/states/reticle';
import style from 'assets/css/tables/Reticula.module.css';
import classnames from 'classnames';

const modalStates = { showMateria: false, addMateria: false };

const Reticula = () => {
  const [activeModals, setActiveModals] = useState(modalStates);
  const [options, setOptions] = useState([]);
  const [specialty, setSpecialty] = useState(null);
  const [reticle, setReticle] = useState(null);
  const [dataSubject, setDataSubject] = useState(null);
  const [matriz, setMatriz] = useState([]);
  const [position, setPosition] = useState(null);

  const { data, loading, error } = useQuery(GET_RETICLES_SPECIALTIES);
  const [loadReticle, { called, data: dataReticle, error: errorReticle, refetch }] = useLazyQuery(GET_RETICLE);

  if (loading) return <Loading />;

  if (error || (called && errorReticle)) {
    return (
      <ErrorData
        title='Ocurrió un error con los datos'
        desc='Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde'
      />
    );
  }

  const changeModalState = (modal) => async () => setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });

  const Materia = ({ materia, position }) => (
    <td>
      <div
        className={materia ? classnames(style.item, style.activo) : style.item}
        onClick={
          materia
            ? () => {
                console.log(position);
                setDataSubject(materia);
                setActiveModals({
                  ...activeModals,
                  showMateria: !activeModals['showMateria'],
                });
              }
            : () => {
                console.log(position);
                setPosition(position);
                setActiveModals({
                  ...activeModals,
                  addMateria: !activeModals['addMateria'],
                });
              }
        }
      >
        {materia ? (
          <>
            <div className={style.contName}>
              <p className={style.name}>{materia?.school_subject?.shortName}</p>
            </div>
            <div className={style.contNumber}>
              <p className={style.number}>{materia?.school_subject?.totalCredit}</p>
              <p className={style.number}>{materia?.school_subject?.creditPrerrequisit}</p>
            </div>
          </>
        ) : (
          <p className={style.desc}>Asignar Materias</p>
        )}
      </div>
    </td>
  );

  const handleSearch = async () => {
    if (reticle && specialty) {
      const { data } = await loadReticle({
        variables: { reticleId: reticle },
      });

      const { reticleSemesters } = data?.reticle;
      const rows = transformToRows({ input: reticleSemesters });
      setMatriz(rows);
    }
  };

  const handleChangeReticle = (e) => {
    console.log(e);
    setSpecialty(null);

    let optionsSpecialties = [];
    e.specialties?.forEach((specialty) => optionsSpecialties.push(specialty));

    setOptions(optionsSpecialties);
    setReticle(e.value);
  };

  const handleChangeSpecialty = (e) => setSpecialty(e.value);

  return (
    <div className={style.container}>
      <Formik
        initialValues={selectReticleInitialState}
        validationSchema={selectReticleValidationSchema}
        onSubmit={handleSearch}
      >
        {(props) => {
          const { errors, touched, handleChange } = props;

          return (
            <Form>
              <Collapse title='Seleccionar retícula'>
                <div className={classnames(style.formDate, style.contSelect)}>
                  <InputSelect
                    error={touched.reticula && errors.reticula}
                    name='reticula'
                    title='Retícula'
                    options={data.reticles}
                    onChange={(e) => {
                      handleChange('reticula')(e.value);
                      handleChangeReticle(e);
                    }}
                  />
                  <InputSelect
                    error={touched.especialidad && errors.especialidad}
                    name='especialidad'
                    title='Especialidad'
                    options={options}
                    onChange={(e) => {
                      handleChange('especialidad')(e.value);
                      handleChangeSpecialty(e);
                    }}
                  />
                </div>
                <div className={style.contButton}>
                  <BtnPrincipal text='Buscar' />
                </div>
              </Collapse>
            </Form>
          );
        }}
      </Formik>
      {dataReticle && (
        <div className={style.contTable}>
          <table className={style.table}>
            <thead className={style.headTable}>
              <tr className={style.contColumn}>
                <th className={style.columnFirst}></th>
                <th className={style.column}>Semestre 1</th>
                <th className={style.column}>Semestre 2</th>
                <th className={style.column}>Semestre 3</th>
                <th className={style.column}>Semestre 4</th>
                <th className={style.column}>Semestre 5</th>
                <th className={style.column}>Semestre 6</th>
                <th className={style.column}>Semestre 7</th>
                <th className={style.column}>Semestre 8</th>
                <th className={style.column}>Semestre 9</th>
                <th className={style.column}>Semestre 10</th>
              </tr>
            </thead>
            <tbody className={style.bodyTable}>
              {matriz.map((data, index) => (
                <tr key={index}>
                  <td className={style.numRow}>{index + 1}</td>
                  {data.map((subject, i) => (
                    <Materia key={i} materia={subject} position={{ semester: i + 1, order: index + 1 }} />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        title='Información de la materia'
        width='80%'
        height='90%'
        show={activeModals.showMateria}
        close={changeModalState('showMateria')}
      >
        <div className={style.contModal}>
          <ShowSubject subject={dataSubject} />
        </div>
      </Modal>
      <Modal
        title='Agregar materia a la reticula'
        width='80%'
        height='90%'
        show={activeModals.addMateria}
        close={changeModalState('addMateria')}
      >
        <div className={style.contModal}>
          <FormMateriaReticle position={position} />
        </div>
      </Modal>
    </div>
  );
};

export default Reticula;
