import React from "react";

import styles from "../../assets/css/components/Input.module.css";
import Select from "react-select";
import SimpleValue from "../SelectSimpleValue";
import classnames from "classnames";

const optionsDefault = [
  { value: "opcion 1", label: "opcion 1" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 2", label: "opcion 2" },
  { value: "opcion 3", label: "opcion fin" },
];

const customStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "#fff",

      color: "#000",

      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "#fff",
      },
    };
  },

  menu: (provided) => ({ ...provided, zIndex: 9999 }),

  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),

  placeholder: (styles) => {
    return {
      ...styles,
      color: "#c0c0c0",
      fontSize: ".9rem",
    };
  },

  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    height: "46px",
    display: "flex",
    flexdirection: "row",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },

  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#f8f9fe",
      borderRadius: "15px",
      padding: "0px 4px",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#1b396a",
    fontSize: ".8rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1b396a",
    fontSize: ".8rem",
    ":hover": {
      color: "#fd4a4a",
    },
  }),
};

function InputSelect({
  error,
  title,
  subtitle,
  value,
  options,
  multiple,
  defaultValue,
  placeholder,
  isLoading,
  ...props
}) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {title}
        {subtitle ? (
          <span className={styles.subtitle}>({subtitle})</span>
        ) : null}
      </p>
      <div className={classnames(styles.input, styles.contSelect)}>
        {multiple ? (
          <Select
            {...props}
            onChange={(value) => {
              props.onChange(props.name, value);
            }}
            isMulti={true}
            options={options ? options : optionsDefault}
            placeholder="Seleccionar"
            styles={customStyles}
            noOptionsMessage={() => "No se encontraron opciones"}
          />
        ) : (
          <SimpleValue
            options={options ? options : optionsDefault}
            value={value}
          >
            {(simpleProps) => (
              <Select
                {...props}
                {...simpleProps}
                placeholder={placeholder ? placeholder : "Seleccionar"}
                styles={customStyles}
                defaultValue={defaultValue}
                isLoading={isLoading}
                isMulti={multiple}
                noOptionsMessage={() => "No se encontraron opciones"}
              />
            )}
          </SimpleValue>
        )}
      </div>
      {error && <p className={styles.txtWarning}>{error}</p>}
    </div>
  );
}

export default InputSelect;
