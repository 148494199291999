import React from "react";
import * as Yup from "yup";

import styles from "../../../assets/css/Forms/Form.module.css";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import TextArea from "components/Inputs/TextArea";

import { Formik, Form } from "formik";
import ConsoleHelper from "ConsoleHelper";

function FormNoteAspirant({ method }) {
  const handleSubmit = (values) => {
    ConsoleHelper(values);
    method(values.note);
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          note: "",
        }}
        validationSchema={Yup.object({
          note: Yup.string().required("Debes ingresar un mensaje."),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className={styles.contTxtArea}>
            <TextArea name="note" title="Escribir observaciones" />
          </div>
          <div className={styles.contBtn}>
            <BtnPrincipal text="Enviar" />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default FormNoteAspirant;
