import React, { useState } from "react";

import styles from "../../assets/css/components/Collapse.module.css";
import classnames from "classnames";

function Collapse({ title, children, deletebtn, ...rootDOMAttributes }) {
  const [collapse, setCollapse] = useState(true);

  return (
    <div className={collapse ? styles.container : classnames(styles.container, styles.clsCollapse)}>
      <div className={styles.header} onClick={() => setCollapse(!collapse)}>
        <p className={styles.text}>{title}</p>
        <div className={styles.contAction}>
          <p
            className={
              collapse
                ? classnames(styles.text, styles.open)
                : classnames(styles.text, styles.close)
            }
          >
            <i className="fas fa-angle-down"></i>
          </p>
          {deletebtn ? (
            <button type="button" className={styles.btn} {...rootDOMAttributes}>
              <i className="far fa-trash-alt"></i>
            </button>
          ) : null}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
      {/* {collapse ? <div className={styles.content}>{children}</div> : null} */}
    </div>
  );
}

export default Collapse;
