import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormSchoolPeriods from "views/pages/forms/FormSchoolPeriods";
import useGetPermissions from "hooks/useGetPermissions";
import Loading from "components/Loading";

function SchoolPeriods() {
  const { avaible } = useGetPermissions("school-period", ["create", "update"]);

  if (avaible === null) return <Loading />;

  return (
    <DashboardAlt>
      <FormSchoolPeriods />
    </DashboardAlt>
  );
}

export default SchoolPeriods;
