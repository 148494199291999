export const configEnglishInitialState = {
  englishPeriod: "",
  englishLevel: "",
  limit: "",
  day: [],
  hour: "",
  teacher: "",
  year: "",
};

export const ENGLISH_DAYS = [
  { value: 1, label: "Lunes" },
  { value: 2, label: "Martes" },
  { value: 3, label: "Miercoles" },
  { value: 4, label: "Jueves" },
  { value: 5, label: "Viernes" },
  { value: 6, label: "Sabado" },
];
