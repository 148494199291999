import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useLocalStorage } from "./useLocalStorage";
import ConsoleHelper from "ConsoleHelper";

function useGetPermissions(name, actions) {
  const history = useRef(useHistory());
  const [data] = useLocalStorage("permissions", null);
  const [avaible, setAvaible] = useState(null);
  const [permiss, setPermiss] = useState(null);
  const newName = useRef(name);
  const newActions = useRef(actions);

  useEffect(() => {
    let isOk = true;
    async function getPermissionsRole() {
      const permissions = JSON.parse(data);
      if (permissions) {
        ConsoleHelper(permissions);
        const controller = permissions[newName.current];
        if (controller) {
          ConsoleHelper(controller);
          newActions.current.forEach((action) => {
            ConsoleHelper(action);
            if (!controller[action]?.enabled) {
              isOk = false;
            }
          });
          if (isOk) {
            setAvaible(true);
            setPermiss(controller);
          } else {
            history.current.push("/404");
          }
        }
      }
    }
    getPermissionsRole();
  }, [data]);

  return { avaible, permiss };
}

export default useGetPermissions;
