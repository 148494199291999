import React, { useState, useEffect } from "react";

import style from "assets/css/Dashboard/HomeAspirant.module.css";
import NoteAspirant from "components/Card/NoteAspirant";
import RegisterAspirant from "components/Charts/RegisterAspirant";
import image from "assets/img/theme/imgBanner.svg";
import classNames from "classnames";

import { Link } from "react-router-dom";
import CardWelcome from "components/Card/CardWelcome";

function HomeAspirant({
  data,
  handleClickProgress,
  getPositionFormsAspirant,
  refetch,
  noti,
  countNoti,
  observations
}) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (data) {
      const notis = data?.user?.notifications;
      const notificationsReverse = [...notis];
      setNotifications(notificationsReverse.reverse());
    }
  }, [data, setNotifications]);

  const showMessageStatus = (state) => {
    if (state === "registrado")
      return "Gracias por registrarte, por favor continua con el registro de tus datos";
    if (state === "formularios")
      return "Tienes datos pendientes por registrar, por favor continua con el registro";
    if (state === "enviado") return "Tus datos están en espera de revisión";
    if (state === "revisado")
      return "Algún encargado ya revisó los datos que mandaste";
    if (state === "observaciones")
      return "Por favor revisa las notificaciones para checar las correcciones que tienes";
    if (state === "aceptado") return "¡Felicidades haz sido aceptado!";
    if (state === "pagar")
      return "Tu pago aún no ha sido reflejado en el sistema";
    if (state === "pagado") return "Tus datos están en espera de revisión";
    if (state === "corregido")
      return "Gracias por corregir tus datos, están en espera de revisión";
    if (state === "aspirante")
      return "¡Felicidades tus datos están correctos! descarga tu ficha oficial por correo o en las notificaciones";
    if (state === "rechazado") return "Lo sentimos han rechazado tu solicitud";
    if (state === "aspirante")
      return "Revisa tus notificaciones te hemos enviado tu ficha oficial de aspirante";
    return "Gracias por registrarte, por favor continua con el registro de tus datos";
  };

  const calculatePercentProgress = (data) => {
    const position = getPositionFormsAspirant(data);
    if (position === 0) {
      return 0;
    }
    const progress = [12.5 * position];
    return progress;
  };

  return (
    <div className={style.container}>
      <div className={style.contItems}>
        <div className={style.welcome}>
          <CardWelcome
            user={data.user.name}
            completeTitle="bienvenido a la familia TecNM"
            image={image}
          >
            Estamos felices de que estés aquí, queremos{" "}
            <span>crecer contigo</span>, acompañarte hacia tus metas y
            objetivos.
          </CardWelcome>
        </div>
        <div className={style.aspirantInfo}>
          <div className={style.status}>
            <div className={style.headStatus}>
              <div
                className={classNames(
                  style.contIcon,
                  style[data.user.student?.processStatus]
                )}
              >
                <p className={style.icon}>
                  <i className="fas fa-lightbulb"></i>
                </p>
              </div>

              <div className={style.contTitle}>
                <p className={style.title}>Estado</p>
                <p className={style.desc}>{data.user.student?.processStatus}</p>
              </div>
            </div>
            <div className={style.infoStatus}>
              <p className={style.desc}>
                {showMessageStatus(data.user.student?.processStatus)}
              </p>
            </div>
          </div>
          <div className={style.contVideo}>
            <iframe
              title="Video ITI"
              className={style.video}
              src="https://www.youtube.com/embed/hUaMK0S-4kE"
            ></iframe>
          </div>
        </div>
        <div className={style.register}>
          <div className={style.contTitle}>
            <p className={style.title}>Progreso de registro</p>
            <button className={style.btnForm} onClick={handleClickProgress}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
          <div className={style.contGraph} onClick={handleClickProgress}>
            {observations ? (
              <div className={style.contText}>
                <p className={style.title}>Tienes observaciones en tus datos</p>
                <br />
                <p className={style.desc}>
                  Da click aquí para revisar y corregir lo que se te indica.
                </p>
              </div>
            ) : (
              <RegisterAspirant
                // observations={observations}
                progress={calculatePercentProgress(data)}
              />
            )}
          </div>
        </div>
        <div className={style.info}>
          <div className={style.contact}>
            <div className={style.contTitle}>
              <p className={style.title}>Contacto</p>
            </div>
            <div className={style.contInfo}>
              <div className={classNames(style.mail, style.itemContact)}>
                <p className={style.title}>Correo</p>
                <p className={style.desc}>flavio.rc@istmo.tecnm.mx</p>
              </div>
              <div className={classNames(style.phone, style.itemContact)}>
                <p className={style.title}>Teléfono</p>
                <p className={style.desc}>971 711 1042</p>
              </div>
              <div className={classNames(style.address, style.itemContact)}>
                <p className={style.title}>Dirección</p>
                <p className={style.desc}>
                  Panamericana 821, 2da., 70000 Juchitán de Zaragoza, Oax.
                </p>
              </div>
            </div>
          </div>
          <div className={style.social}>
            <div className={style.contTitle}>
              <p className={style.title}>Redes sociales</p>
            </div>
            <div className={style.contItem}>
              <ItemSocial
                icon="fas fa-globe"
                url="https://istmo.tecnm.mx/"
                color="#000"
              />
              <ItemSocial
                icon="fab fa-facebook-f"
                url="https://www.facebook.com/tecnm.campus.istmo/"
                color="#3b5998"
              />
              <ItemSocial
                icon="fab fa-twitter"
                url="https://twitter.com/difusion_iti"
                color="#55acee"
              />
              <ItemSocial
                icon="fab fa-youtube"
                url="https://www.youtube.com/user/ComunicacionITI"
                color="#bb0000"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={noti ? style.notes : classNames(style.notes, style.noNotes)}
      >
        <div className={style.headerNote}>
          <p className={style.title}>Notificaciones</p>
          <div className={style.iconNoti}>
            <>
              <p className={style.icon}>
                <i className="far fa-bell"></i>
              </p>
              {countNoti > 0 && <p className={style.count}>{countNoti}</p>}
            </>
          </div>
        </div>
        <div className={style.cardsNote}>
          {notifications &&
            notifications.map((notification) => (
              <NoteAspirant
                key={notification.id}
                id={notification.id}
                type={notification.type}
                view={notification.read}
                title={notification.name}
                note={notification.text}
                document={notification.file && notification.file}
                date={notification.createdAt}
                refetch={refetch}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

const ItemSocial = ({ icon, url, color }) => (
  <Link
    className={style.itemSocial}
    to={{ pathname: url }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <p className={style.icon} style={(color = { color })}>
      <i className={icon}></i>
    </p>
  </Link>
);

export default HomeAspirant;
