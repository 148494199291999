import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import styles from "../../../assets/css/Dashboard/DashboardAlt.module.css";
import Menu from "components/Menu/Menu";
import HeaderAlt from "components/Headers/HeaderAlt";
import { useLocalStorage } from "hooks/useLocalStorage";
import { GET_ROLE } from "utils/graphql/querys";
import classNames from "classnames";

function DashboardAlt({
  children,
  onClickPhoto,
  routesAspirant,
  setNoti,
  noti,
  countNoti,
  noPadding,
}) {
  const [open, setOpen] = useState(false);
  const [id] = useLocalStorage("id", null);
  const { data } = useQuery(GET_ROLE, {
    variables: {
      Id: id,
      State: "LIVE",
    },
  });

  const getPhoto = () => {
    if (data) {
      if (
        data.user?.role?.name !== "Aspirante" &&
        data.user?.role?.name !== "Estudiante"
      ) {
        return process.env.REACT_APP_REST_URI + data.user?.profilePhoto?.url;
      } else {
        return data.user?.student?.photography?.url;
      }
    }
    return null;
  };

  return (
    <div className={open ? styles.contFixed : styles.container}>
      <HeaderAlt
        fullName={
          data &&
          `${data.user.name} ${data.user.firstLastName} ${data.user.secondLastName}`
        }
        role={data && data.user.role.name}
        photo={getPhoto()}
        noti={noti}
        setNoti={setNoti}
        countNoti={countNoti}
        onClick={
          data && data.user?.role?.name === "Aspirante"
            ? onClickPhoto
            : undefined
        }
      >
        <button className={styles.btnMenu} onClick={() => setOpen(!open)}>
          <i className="fas fa-bars"></i>
        </button>
      </HeaderAlt>
      <div className={styles.main}>
        <Menu
          open={open}
          routesAspirant={
            data?.user?.role?.name === "Aspirante" && routesAspirant
          }
        />
        <div
          className={
            noPadding
              ? classNames(styles.contActivity, styles.contentFull)
              : styles.contActivity
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default DashboardAlt;
