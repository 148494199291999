import React, { useState } from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import ToggleText from "components/Inputs/ToggleText";
import InputToggle from "components/Inputs/InputToggle";

import { addressInitialState } from "utils/states/aspirant";
import { addAddressSchema } from "utils/schemas/aspirant.schemas";
import useZipCode from "hooks/useZipCode";
import useAddAddress from "hooks/useAddAddress";
import { error } from "components/Alerts";
import Loading from "components/Loading";
import ConsoleHelper from "ConsoleHelper";

function FormAddress({ setPosition, idUser, token }) {
  const { loading, getZipCode } = useZipCode();
  const [optionZipCode, setOptionsZipCode] = useState([]);
  const [optionZipCodeCu, setOptionsZipCodeCu] = useState([]);
  const { loadingAddress, addUserAddress } = useAddAddress();

  if (loadingAddress) return <Loading />;

  const handleSubmit = async (values) => {
    const {
      checkNumberHouse,
      currentCp,
      currentEntity,
      currentLocale,
      currentTown,
      currentStreet,
      numberHouse,
      procedenceCp,
      procedenceEntity,
      procedenceLocale,
      procedenceTown,
    } = values;
    ConsoleHelper(values);
    const addressData = [
      {
        postalCode: procedenceCp,
        country: procedenceEntity,
        municipaly: procedenceTown,
        colony: procedenceLocale,
        number: checkNumberHouse ? 0 : numberHouse,
        placeOrigin: false,
        user: idUser,
      },
      {
        postalCode: currentCp,
        country: currentEntity,
        municipaly: currentTown,
        colony: currentLocale,
        number: checkNumberHouse ? 0 : numberHouse,
        placeOrigin: true,
        user: idUser,
        street: currentStreet,
      },
    ];
    const response = await addUserAddress(token, addressData);
    if (response) {
      if (response === "error") {
        error(
          "Lo sentimos",
          "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
        );
      } else {
        setPosition(4);
      }
    }
  };

  return (
    <Formik
      initialValues={addressInitialState}
      validationSchema={addAddressSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched, setFieldValue, values } = props;
        const onChangeZipCode = async (e, op) => {
          handleChange(e);
          let cp = e.target.value.replace(" ", "");
          if (cp.length === 5) {
            const objCp = await getZipCode(cp);
            setFieldValue(
              op ? "procedenceEntity" : "currentEntity",
              objCp.entity,
              false
            );
            setFieldValue(
              op ? "procedenceTown" : "currentTown",
              objCp.town,
              false
            );
            op
              ? setOptionsZipCode(objCp.data)
              : setOptionsZipCodeCu(objCp.data);
          }
        };

        const onChangeCheckCurrent = (e) => {
          handleChange(e);
          setFieldValue(
            "currentCp",
            values.checkCurrent ? "" : values.procedenceCp,
            false
          );
          setFieldValue(
            "currentEntity",
            values.checkCurrent ? "" : values.procedenceEntity,
            false
          );
          setFieldValue(
            "currentTown",
            values.checkCurrent ? "" : values.procedenceTown,
            false
          );
          setFieldValue(
            "currentLocale",
            values.checkCurrent ? "" : values.procedenceLocale,
            false
          );
        };

        return (
          <Form>
            <Collapse title="Entidad de procedencia">
              <div className={classnames(style.formDate, style.twoColumn)}>
                <Input
                  name="procedenceCp"
                  title="Código postal"
                  maxLength={5}
                  onChange={(e) => onChangeZipCode(e, true)}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                />
                <Input
                  disabled
                  name="procedenceEntity"
                  title="Estado"
                  type="text"
                />
                <Input
                  disabled
                  name="procedenceTown"
                  title="Municipio"
                  type="text"
                />
                <InputSelect
                  error={touched.procedenceLocale && errors.procedenceLocale}
                  name="procedenceLocale"
                  title="Colonia o localidad"
                  options={optionZipCode}
                  isLoading={loading}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      "currentLocale",
                      values.checkCurrent ? selectedOption.label : "",
                      false
                    );
                    handleChange("procedenceLocale")(selectedOption.label);
                  }}
                />
              </div>
            </Collapse>
            <Collapse title="Domicilio actual">
              <div className={classnames(style.formDate, style.threeColumn)}>
                <ToggleText
                  name="checkCurrent"
                  title="¿Tu domicilio actual es tu entidad de procedencia?"
                  onChange={onChangeCheckCurrent}
                />
                <Input
                  disabled={values.checkCurrent}
                  name="currentCp"
                  title="Código postal"
                  type="text"
                  onChange={(e) => onChangeZipCode(e, false)}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                />
                <Input
                  disabled
                  name="currentEntity"
                  title="Estado"
                  type="text"
                />
                <Input
                  disabled
                  name="currentTown"
                  title="Municipio"
                  type="text"
                />
                {values.checkCurrent ? (
                  <Input
                    disabled
                    name="currentLocale"
                    title="Colonia o localidad"
                    values={values.currentLocale}
                    type="text"
                  />
                ) : (
                  <InputSelect
                    error={touched.currentLocale && errors.currentLocale}
                    name="currentLocale"
                    title="Colonia o localidad"
                    options={optionZipCodeCu}
                    isLoading={!values.checkCurrent && loading}
                    onChange={(selectedOption) => {
                      handleChange("currentLocale")(selectedOption.label);
                    }}
                  />
                )}
                <InputToggle
                  nameToggle="checkNumberHouse"
                  name="numberHouse"
                  type="number"
                  valueToggle={values.checkNumberHouse}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.match(/^\d+$/)) {
                      if (value.length <= 4) handleChange(e);
                    }
                  }}
                  onChangeToggle={(e) => {
                    handleChange("checkNumberHouse")(e);
                    setFieldValue(
                      "numberHouse",
                      values.checkNumberHouse ? "" : 0,
                      false
                    );
                  }}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                  title="Número exterior del domicilio"
                />
                <div className={classnames(style.inputLong)}>
                  <Input
                    name="currentStreet"
                    title="Calle"
                    type="text"
                    onPaste={(e) => e.preventDefault()}
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal text="Guardar y continuar" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormAddress;
