import React from "react";

import styles from "../../assets/css/components/TextArea.module.css";

import { useField } from "formik";

function TextArea({ title, subtitle, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {title}
        {subtitle ? (
          <span className={styles.subtitle}>({subtitle})</span>
        ) : null}
      </p>
      <textarea
        {...field}
        {...props}
        className={styles.txtArea}
        placeholder="Escribir"
      ></textarea>
      {meta.touched && meta.error && (
        <p className={styles.txtWarning}>{meta.error}</p>
      )}
    </div>
  );
}

export default TextArea;
