import React, { useState, useMemo } from "react";
import style from "assets/css/divEstudios/Subject.module.css";
import DashboardAlt from "../dashboards/DashboardAlt";
import InputSearch from "components/Inputs/InputSearch";
import LinkPage from "components/LinkPage";
import { useQuery } from "@apollo/client";
import { GET_SUBJECTS } from "utils/graphql/querys";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";

const filterSubjects = (value) => (item) => {

  const { name, subjectKey } = item;
  const { name: nameSchoolLevel } = item.school_level;
  const { name: nameAcademicArea } = item.academic_area;
  const { name: nameSubjectType } = item.subject_type;
  if (name.toLowerCase().trim().includes(value.toLowerCase().trim())) {
    return true;
  }
  if (subjectKey.toLowerCase().trim().includes(value.toLowerCase().trim())) {
    return true;
  }
  if (
    nameSchoolLevel.toLowerCase().trim().includes(value.toLowerCase().trim())
  ) {
    return true;
  }
  if (
    nameAcademicArea.toLowerCase().trim().includes(value.toLowerCase().trim())
  ) {
    return true;
  }
  if (
    nameSubjectType.toLowerCase().trim().includes(value.toLowerCase().trim())
  ) {
    return true;
  }
  return false;
};

function Subject() {
  const { loading, data, error } = useQuery(GET_SUBJECTS);
  const [searchedValue, setSearchedValue] = useState("");

  const memoizedSubjects = useMemo(
    () =>
      data ? data.schoolSubjects.filter(filterSubjects(searchedValue)) : [],
    [searchedValue, data]
  );

  if (loading) return <Loading />;

  if (error) {
    return <ErrorData btn />;
  }

  const handleChangeSearchedValue = (e) => {
    const value = e.target.value;
    setSearchedValue(value);
  };

  return (
    <DashboardAlt>
      <div className={style.header}>
        <h1 className={style.title}>Materias registradas</h1>
        <div className={style.action}>
          <LinkPage text="Crear materia" link="/personal/AddSubject" />
          <div className={style.search}>
            <InputSearch
              onChange={handleChangeSearchedValue}
              value={searchedValue}
            />
          </div>
        </div>
      </div>
      <div className={style.contSubject}>
        <div className={style.contItem}>
          {data &&
            memoizedSubjects.map((subject) => (
              <ItemSubject
                key={subject._id}
                image="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                item={subject}
              />
            ))}
          {/* <ItemSubject
            image="https://miro.medium.com/max/1400/1*U2OC6Jc2W28l_AWzh7jaKQ.jpeg"
            subject="Topicos avanzados de programación movil"
            keySubject="itikey2022"
          />
          <ItemSubject
            image="https://i2.wp.com/anaktik.com/wp-content/uploads/2020/03/apa-itu-database-scaled.jpg?fit=2560%2C1707&ssl=1"
            subject="Base de datos"
            keySubject="itikey2022"
          /> */}
        </div>
      </div>
    </DashboardAlt>
  );
}

const ItemSubject = ({ item, image }) => (
  <div className={style.item}>
    <div className={style.headItem}>
      <div className={style.contImage}>
        <img className={style.image} src={image} alt="" />
      </div>
      <div className={style.info}>
        <p className={style.name}>{item.name}</p>
        <p className={style.key}>
          <span className={style.icon}>
            <i className="fas fa-key"></i>
          </span>
          {item.subjectKey}a
        </p>
      </div>
    </div>
    <div className={style.data}>
      <div className={style.infoSubject}>
        <DescSubject
          icon="fas fa-dot-circle"
          title="Tipo"
          desc={item.subject_type.name}
        />
        <DescSubject
          icon="fas fa-sort-amount-up"
          title="Nivel escolar"
          desc={item.school_level.name}
        />
        <DescSubject
          icon="fas fa-building"
          title="Área académica"
          desc={item.academic_area.name}
        />
      </div>
      <div className={style.qualification}>
        <p className={style.title}>Calificación aprobatoria</p>
        <p className={style.desc}>
          <span>
            <i className="fas fa-star"></i>
          </span>
          {item.accreditationClassification}
        </p>
      </div>
    </div>
  </div>
);

const DescSubject = ({ icon, title, desc }) => (
  <div className={style.contDesc}>
    <div className={style.contIcon}>
      <p className={style.icon}>
        <i className={icon}></i>
      </p>
    </div>
    <div className={style.contText}>
      <p className={style.title}>{title}</p>
      <p className={style.desc}>{desc}</p>
    </div>
  </div>
);

export default Subject;
