import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useLogin() {
  const [loadingLogin, setLoadingLogin] = useState(false);

  const loginUser = async (loginData) => {
    try {
      setLoadingLogin(true);
      const LOGIN_URL = process.env.REACT_APP_REST_URI + "/auth/local";
      const login = await axios.post(LOGIN_URL, loginData);
      ConsoleHelper(login.data);
      const BEARER_TOKEN = `Bearer ${login?.data?.jwt}`;
      const GET_ROLE_URL =
        process.env.REACT_APP_REST_URI +
        "/users-permissions/roles/" +
        login?.data?.user?.role?.id;
      const roleResponse = await axios.get(GET_ROLE_URL, {
        headers: { Authorization: BEARER_TOKEN },
      });
      ConsoleHelper(roleResponse.data);
      const data = await roleResponse.data;
      if (data) {
        const permissions = data.role.permissions.application.controllers;
        ConsoleHelper(permissions);
        return {
          jwt: login?.data?.jwt,
          id: login?.data?.user?.id,
          role: {
            id: login?.data?.user?.role?.id,
            name: login?.data?.user?.role?.name,
          },
          permissions,
        };
      }
    } catch (e) {
      if (e?.response?.data?.statusCode >= 401) {
        return "error-server";
      }
      return "error";
    } finally {
      setLoadingLogin(false);
    }
  };

  return { loadingLogin, loginUser };
}

export default useLogin;
