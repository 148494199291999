import React from "react";
import { useHistory } from "react-router";
import { warning } from "components/Alerts";

import styles from "../../assets/css/components/Menu.module.css";
import classnames from "classnames";
import ConsoleHelper from "ConsoleHelper";
import { Link } from "react-router-dom";
import useGetRoutes from "./useGetRoutes";

function Menu({ open, routesAspirant }) {
  const history = useHistory();
  const routes = useGetRoutes();

  const signOut = () => {
    try {
      warning(
        "¿Estás seguro?",
        "Tendrás que iniciar sesión nuevamente.",
        "Cerrar sesión",
        logoutAction
      );
    } catch (error) {
      ConsoleHelper(error);
    }
  };

  const logoutAction = () => {
    window.localStorage.clear();
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <ul
          className={
            open
              ? classnames(styles.menuItems, styles.show)
              : classnames(styles.menuItems, styles.noShow)
          }
        >
          <div className={styles.contOptions}>
            {routesAspirant
              ? routesAspirant.map((route, i) => (
                  <Option
                    key={i}
                    icon={route.icon}
                    text={route.name}
                    onClick={route.onClick}
                    active={route.active}
                  />
                ))
              : routes.map(
                  (route, i) =>
                    route.enabled && (
                      <Link key={i} to={route.path} className={styles.option}>
                        <div className={styles.contIcon}>
                          <i className={route.icon}></i>
                        </div>
                        <div className={styles.contText}>
                          <p className={styles.text}>{route.name}</p>
                        </div>
                      </Link>
                    )
                )}
            {/* <Option
              icon="fas fa-users-cog"
              text="Mantenimiento"
              active={true}
            />
            <Option icon="far fa-user" text="Consultas" />
            <Option icon="far fa-file" text="Documentos oficiales" />
            <Option icon="fas fa-coins" text="PTA - POA" />
            <Option icon="fas fa-pencil-ruler" text="Utilerías" /> */}
          </div>
          <Option
            onClick={signOut}
            icon="fas fa-sign-out-alt"
            text="Cerrar sesión"
          />
        </ul>
      </div>
    </div>
  );
}

const Option = ({ icon, text, active, onClick }) => (
  <div
    onClick={onClick}
    className={
      active ? classnames(styles.option, styles.opActive) : styles.option
    }
  >
    <div className={styles.contIcon}>
      <i className={icon}></i>
    </div>
    <div className={styles.contText}>
      <p className={styles.text}>{text}</p>
    </div>
  </div>
);

export default Menu;
