export const createMatterInitialState = {
  academicArea: "",
  matter: "",
  theoreticalHours: "",
  practicalHours: "",
  totalCredits: "",
  requestCredits: "",
  officialKey: "",
  specialty: "",
  certificateOrder: "",
  matterStatus: "",
};

export const addMatterReticleInitialState = {
  academicArea: "",
  matter: "",
  afterMatter: "",
};
