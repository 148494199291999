import React from "react";

import FormInitialAdmission from "../forms/FormInitialAdmission"
import DashboardAlt from "../dashboards/DashboardAlt";

function ConfigInitialAdmission() {
  return (
    <DashboardAlt>
      <FormInitialAdmission />
    </DashboardAlt>
  );
}

export default ConfigInitialAdmission;
