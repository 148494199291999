import React, { useState, useEffect } from "react";
import axios from "axios";

import styles from "../../../assets/css/Forms/Form.module.css";
import classnames from "classnames";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import InputDate from "components/Inputs/InputDate";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import TextArea from "components/Inputs/TextArea";
import { success, error } from "components/Alerts";

import { configAdmisionInitialState } from "utils/states/admision";
import { configAdmisionSchema } from "utils/schemas/admision.schemas";

import { Formik, Form } from "formik";
import formatDate from "utils/formatDate";
import formatTime from "utils/formatTime";
import Loading from "components/Loading";
import useGetPermissions from "hooks/useGetPermissions";

const dataSelectsAdmisionModality = [
  { value: "1", label: "Presencial" },
  { value: "2", label: "En línea" },
  { value: "3", label: "Mixto" },
];

const dataSelectsAdmisionType = [
  { value: "1", label: "CENEVAL" },
  { value: "2", label: "PROPIO" },
];

function selectValue(state = "", data = []) {
  const stateMatches = data.filter(({ label }) => {
    const parseLabel = label
      .toLowerCase()
      .trim()
      .includes(state.toLowerCase().trim());
    return parseLabel;
  });
  if (stateMatches.length > 0) {
    return stateMatches[0];
  }
  return null;
}

function FormInitialAdmission() {
  const [configAdmission, setConfigAdmission] = useState(null);
  const [loading, setLoading] = useState(false);
  const { avaible } = useGetPermissions("setting-candidates", [
    "create",
    "update",
  ]);

  const getAdmisionData = async () => {
    setLoading(true);
    const token = window.localStorage.getItem("jwt");
    if (!token) return;
    const { REACT_APP_REST_URI: uri } = process.env;
    const API_URL = uri + "/setting-candidates";
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.get(API_URL, { headers });
    const [admision] = data;
    if (admision) {
      setConfigAdmission(admision);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAdmisionData();
    return () => {};
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const headers = { Authorization: `Bearer ${token}` };

      const valuesToSend = {
        startDate: values.startDate,
        finalDate: values.endDate,
        examDate: values.testDate,
        examTime: values.testTime,
        typeOfExam: values.testType,
        examModality: values.testMode,
        urlExam: values.testUrl,
        urlGuiaExam: values.urlGuiaExam,
        linkSII: values.linkSII,
        comments: values.note,
        bank: values.bankPay,
        accountNumber: values.bankAccount,
        costFicha: values.testCost,
        linkPagosIti: values.payUrl,
        jefeDep: {
          nameJefe: values.nameJefe,
          gradoJefe: values.gradoJefe,
          sexoJefe: "Jefe",
          emailJefe: values.emailJefe,
        },
        userTelegram: values.userTelegram,
      };

      const axiosRequestConfig = {
        method: configAdmission ? "put" : "post",
        url: `${process.env.REACT_APP_REST_URI}/setting-candidates`,
        headers,
        data: valuesToSend,
      };

      if (configAdmission) {
        axiosRequestConfig.url = `${axiosRequestConfig.url}/${configAdmission.id}`;
      }

      const response = await axios(axiosRequestConfig);

      if (response.status === 200) {
        setConfigAdmission(response.data);
        success(
          "Operación exitosa",
          "La configuración del proceso de admisión de aspirantes se ha guardado correctamente.",
          "Aceptar",
          () => {
            setLoading(false);
          }
        );
      }
    } catch {
      error(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    }
  };

  if (loading || avaible === null) return <Loading />;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Configuración inicial para el proceso de admisión
      </h1>
      <div className={styles.contForm}>
        <Formik
          initialValues={configAdmisionInitialState(configAdmission)}
          validationSchema={configAdmisionSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { errors, handleChange, touched } = props;
            return (
              <Form>
                <Collapse title="Registrar periodo" idCollapse="date">
                  <div
                    className={classnames(styles.formDate, styles.twoColumn)}
                  >
                    <InputDate
                      initialValue={props.values.startDate}
                      error={touched.startDate && errors.startDate}
                      name="startDate"
                      title="Fecha inicial"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("startDate")(dateFromValue.toISOString());
                      }}
                    />
                    <InputDate
                      initialValue={props.values.endDate}
                      error={touched.endDate && errors.endDate}
                      name="endDate"
                      title="Fecha final"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("endDate")(dateFromValue.toISOString());
                      }}
                    />
                  </div>
                </Collapse>
                <Collapse title="Datos del examen" idCollapse="test">
                  <div
                    className={classnames(styles.formDate, styles.fourColumn)}
                  >
                    <InputDate
                      initialValue={props.values.testDate}
                      error={touched.testDate && errors.testDate}
                      name="testDate"
                      title="Fecha del examen"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("testDate")(formatDate(dateFromValue));
                      }}
                    />
                    <InputDate
                      initialValue={props.values.testTime}
                      error={touched.testTime && errors.testTime}
                      name="testTime"
                      title="Hora del examen"
                      time={true}
                      date={false}
                      timeFormat="hh:mm a"
                      onChange={(timeFromValue) => {
                        handleChange("testTime")(formatTime(timeFromValue));
                      }}
                    />
                    <InputSelect
                      error={touched.testType && errors.testType}
                      name="testType"
                      title="Tipo de examen"
                      options={dataSelectsAdmisionType}
                      onChange={(selectedOption) => {
                        handleChange("testType")(selectedOption.label);
                      }}
                      defaultValue={selectValue(
                        props.values.testType,
                        dataSelectsAdmisionType
                      )}
                    />
                    <InputSelect
                      error={touched.testMode && errors.testMode}
                      name="testMode"
                      title="Modalidad del examen"
                      options={dataSelectsAdmisionModality}
                      onChange={(selectedOption) => {
                        handleChange("testMode")(selectedOption.label);
                      }}
                      defaultValue={selectValue(
                        props.values.testMode,
                        dataSelectsAdmisionModality
                      )}
                    />
                    <div className={styles.inputMedium}>
                      <Input
                        name="testUrl"
                        title="URL registro de examen"
                        type="text"
                      />
                    </div>
                    <div className={styles.inputMedium}>
                      <Input
                        name="urlGuiaExam"
                        title="URL guia de examen"
                        type="text"
                      />
                    </div>
                    <div className={styles.inputMedium}>
                      <Input name="linkSII" title="URL del SII" type="text" />
                    </div>
                  </div>
                  <div className={styles.contTxtArea}>
                    <TextArea
                      name="note"
                      subtitle="opcional"
                      title="Agregar nota"
                    />
                  </div>
                </Collapse>
                <Collapse title="Datos bancarios" idCollapse="bank">
                  <div
                    className={classnames(styles.formDate, styles.twoColumn)}
                  >
                    <Input
                      name="bankPay"
                      title="Banco para realizar el pago"
                      type="text"
                    />
                    <Input
                      name="bankAccount"
                      title="Cuenta bancaria"
                      type="text"
                    />
                    <Input
                      name="testCost"
                      title="Costo de la ficha"
                      type="number"
                    />
                    <Input
                      name="payUrl"
                      title="Enlace a pagos ITI"
                      type="text"
                    />
                  </div>
                </Collapse>
                <Collapse title="Jefe de departamento">
                  <div
                    className={classnames(styles.formDate, styles.twoColumn)}
                  >
                    <Input
                      name="nameJefe"
                      title="Nombre completo"
                      type="text"
                    />
                    <Input
                      name="gradoJefe"
                      title="Titulo del jefe"
                      type="text"
                    />
                    <Input
                      name="emailJefe"
                      title="Correo del jefe"
                      type="email"
                    />
                    <Input
                      name="userTelegram"
                      title="Usuario de telegram"
                      type="text"
                    />
                  </div>
                </Collapse>
                <div className={styles.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormInitialAdmission;
