import React, { useState } from "react";
import style from "assets/css/Card/CardCredential.module.css";
import classNames from "classnames";

import logoTec from "../../assets/img/theme/tecNMblue.svg";

function CardCredential({ data, loading, error }) {
  const [spin, setSpin] = useState(false);

  const ShowCareer = (career) => {
    if (career?.split(" ")[0] === "ingenieria") {
      return career.replace("ingenieria", "ing.");
    }
    return career;
  };

  return (
    <div className={style.contCard}>
      <div
        className={
          loading
            ? classNames(style.cardInner, style.spinLoading)
            : spin
            ? classNames(style.cardInner, style.spin)
            : style.cardInner
        }
        onClick={() => setSpin(!spin)}
      >
        <div className={classNames(style.card, style.cardFront)}>
          <div className={style.head}>
            <div
              className={classNames(style.designPattern, style.pattern)}
            ></div>
            <HeadSchool />
          </div>
          <div className={style.information}>
            {data && !error ? (
              <>
                <div className={style.contPhoto}>
                  <img
                    className={style.img}
                    src={data.student.photography.url}
                    alt=""
                  />
                </div>
                <div className={style.student}>
                  <DataStudent
                    title="Estudiante"
                    data={`${data?.name ? data?.name : ""} ${
                      data?.firstLastName ? data?.firstLastName : ""
                    } ${data?.secondLastName ? data?.secondLastName : ""}`}
                  />
                  <DataStudent
                    title="Carrera"
                    data={ShowCareer(data?.student?.career?.name.toLowerCase())}
                  />
                  <DataStudent
                    title="Núm. de control"
                    data={data?.student?.matricula}
                  />
                </div>
              </>
            ) : (
              <div className={style.contWarning}>
                <p className={error ? style.textDanger : style.text}>
                  {loading
                    ? "Buscando.."
                    : error
                    ? error.message
                    : "Buscar estudiante"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            loading
              ? classNames(style.card, style.cardBack, style.loadingBack)
              : classNames(style.card, style.cardBack)
          }
        >
          <div className={classNames(style.designPattern, style.pattern)}></div>
          <div className={style.infoSchool}>
            <HeadSchool />
          </div>
        </div>
      </div>
    </div>
  );
}

const HeadSchool = () => (
  <div className={style.school}>
    <div className={style.contName}>
      <p className={style.name}>Tecnológico nacional de méxico</p>
      <p className={style.desc}>Campus istmo</p>
    </div>
    <img className={style.logo} src={logoTec} alt="logo tecnm" />
  </div>
);

const DataStudent = ({ title, data }) => (
  <div className={style.contData}>
    <p className={style.title}>{title}</p>
    <p className={style.desc}>{data}</p>
  </div>
);

export default CardCredential;
