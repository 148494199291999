import React from "react";

import style from "assets/css/Charts/Charts.module.css";
import ReactApexChart from "react-apexcharts";

function Classroom({ assigned = 1, available = 1 }) {
  const series = [assigned, available];

  const options = {
    chart: {
      type: "donut",
    },
    labels: ["Asignados", "Disponible"],
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val;
      },
      pie: {
        customScale: 0.8,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: false,
            // name: {
            //   show: true,
            //   fontSize: 10,
            //   formatter: function (val) {
            //     return val;
            //   },
            // },
            // value: {
            //   show: true,
            //   fontSize: 10,
            //   formatter: function (val) {
            //     return val;
            //   },
            // },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    colors: ["#1b396a", "#f47b20"],
  };

  return (
    <>
      <ReactApexChart
        className={style.chart}
        options={options}
        series={series}
        type="donut"
        width={"100%"}
        height={"100%"}
      />
    </>
  );
}

export default Classroom;
