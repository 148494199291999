import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const generateQueryParams = ({ page, limit }) => {
  return `limit: ${limit}, start: ${limit * (page - 1)}`;
};

const generateName = (fullName = "") => {
  const name = { firstLastName: "", secondLastName: "", name: "", CURP: null };
  const splitNames = fullName
    .toLowerCase()
    .trim()
    .split(/\s/g)
    .filter((splited) => splited !== "");

  if (splitNames.length === 1) {
    name.CURP = splitNames[0];
  } else if (splitNames.length === 2) {
    name.firstLastName = splitNames[0];
    name.secondLastName = splitNames[1];
  } else if (splitNames.length > 2) {
    name.firstLastName = splitNames.splice(0, 1).join(" ");
    name.secondLastName = splitNames.splice(0, 1).join(" ");
    name.name = splitNames.join(" ");
  } else {
    name.firstLastName = splitNames[0];
  }
  return name;
};

const useGetAspirants = () => {
  const LIMIT = 10;
  const [loading, setLoading] = useState(false);
  const [aspirants, setAspirants] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isOnlyPaidAspirants, setIsOnlyPaidAspirants] = useState(false);

  const toggleOnlyPaidAspirants = () => {
    setIsOnlyPaidAspirants(!isOnlyPaidAspirants);
  };

  const nextPage = () => {
    if (pages === page) return page;
    if (aspirants.length > 0) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    setPage((prevState) => {
      if (prevState === 1) return 1;
      if (prevState > 1) {
        return prevState - 1;
      }
    });
  };

  const getPages = async (filters = "") => {
    const token = window.localStorage.getItem("jwt");
    if (!token) return;
    const { REACT_APP_REST_URI: uri } = process.env;
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.get(uri + "/users/count" + filters, {
      headers,
    });
    setPages(Math.ceil(data / LIMIT));
  };

  const getAspirants = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const token = window.localStorage.getItem("jwt");
        if (!token) return;
        const { REACT_APP_GRAPHQL_URI: uri } = process.env;
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          uri,
          {
            query: `{
            users(where: { role: { name: "Aspirante" }${
              isOnlyPaidAspirants
                ? ', student: { processStatus: "pagado" }'
                : ""
            } }, ${generateQueryParams({ limit: LIMIT, page })}) {
              id
              name
              firstLastName
              secondLastName
              dateOfBirth
              gender
              student {
                id
                processStatus
                careerOptions {
                  career {
                    shortName
                  }
                }
              }
            }
          }
          `,
          },
          { headers }
        );
        if (data.data.users) {
          setAspirants(data.data.users);
        } else if (data.error) {
          console.log("error");
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [isOnlyPaidAspirants]
  );

  const filterAspirants = useCallback(async (fullName = "") => {
    const name = generateName(fullName);
    try {
      setLoading(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_GRAPHQL_URI: uri } = process.env;
      const headers = { Authorization: `Bearer ${token}` };
      const query = name.CURP
        ? `CURP_contains: "${name.CURP}"`
        : `name_contains: "${name.name}",
      firstLastName_contains: "${name.firstLastName}",
      secondLastName_contains: "${name.secondLastName}"`;

      const { data } = await axios.post(
        uri,
        {
          query: `{
            users(where: { ${query} }) {
              id
              name
              firstLastName
              secondLastName
              dateOfBirth
              gender
              student {
                id
                processStatus
                careerOptions {
                  career {
                    shortName
                  }
                }
              }
            }
          }
          `,
        },
        { headers }
      );
      if (data.data.users) {
        setAspirants(data.data.users);
      } else if (data.error) {
        console.log("error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOnlyPaidAspirants) {
      getPages("?[student.processStatus][$eq]=pagado");
    } else {
      getPages();
    }
    getAspirants(page);
    return () => {};
  }, [getAspirants, page, isOnlyPaidAspirants]);

  return {
    loadingAspirants: loading,
    aspirants,
    pages,
    page,
    isOnlyPaidAspirants,
    getAspirants,
    nextPage,
    previousPage,
    filterAspirants,
    getPages,
    toggleOnlyPaidAspirants,
  };
};

export default useGetAspirants;
