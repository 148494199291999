import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormCreateDegree from "../forms/FormCreateDegree";

function CreateDegree() {
  return (
    <DashboardAlt>
      <FormCreateDegree />
    </DashboardAlt>
  );
}

export default CreateDegree;
