import React from "react";

import style from "assets/css/components/InfoPhoto.module.css";

import photo from "assets/img/theme/photoExample.jpeg";

function InfoPhoto() {
  return (
    <div className={style.container}>
      <div className={style.contImage}>
        <img className={style.image} src={photo} alt="Imagen de ejemplo" />
      </div>
      <div className={style.contInfo}>
        <TextInformation
          index="1"
          text="Sube una fotografía de buena calidad con fondo claro."
        />
        <TextInformation
          index="2"
          text="debe mostrar solo tu rostro, tomada de frente."
        />
        <TextInformation index="3" text="a color, reciente y bien enfocada." />
        <TextInformation
          index="4"
          text="Recuerda esta fotografía sera usada durante el proceso de admisión y durante tu estancia en la institución.
          "
        />
        <TextInformation
          index="5"
          text="El tamaño máximo de la foto es 600kb.
          "
        />
      </div>
    </div>
  );
}

const TextInformation = ({ index, text }) => (
  <div className={style.info}>
    <p className={style.text}>{index}.-</p>
    <p className={style.text}>{text}</p>
  </div>
);

export default InfoPhoto;
