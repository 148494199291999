export const estadoCivil = [
  { value: "Soltero", label: "Soltero (a)" },
  { value: "Casado", label: "Casado (a)" },
  { value: "Divorciado", label: "Divorciado (a)" },
  { value: "Viudo", label: "Viudo (a)" },
  { value: "UnionLibre", label: "Unión Libre" },
];

export const zonaProcedencia = [
  { value: "1", label: "Zona indígena" },
  { value: "2", label: "Rural" },
  { value: "3", label: "Urbana" },
  { value: "4", label: "Urbana Marginal" },
];

export const siNo = [
  { value: "true", label: "Sí" },
  { value: "false", label: "No" },
];

export const medios = [
  { value: "1", label: "En las instalaciones del instituto" },
  {
    value: "2",
    label: "Pagina del Instituto Tecnológico del Istmo (istmo.tecnm.mx)",
  },
  {
    value: "3",
    label: "Redes sociales (Facebook, Youtube, Twitter, Instagram, etc.)",
  },
  { value: "4", label: "Radio" },
  { value: "5", label: "Ferias y Exposiciones" },
  { value: "6", label: "Bardas publicitarias" },
  { value: "7", label: "Perifoneo" },
  { value: "8", label: "Otro medio" },
];

export const ocupaciones = [
  { value: "ocupacion1", label: "Obrero" },
  { value: "ocupacion2", label: "Profesionista" },
  { value: "ocupacion3", label: "Maestro" },
  { value: "ocupacion4", label: "Comerciante" },
  { value: "ocupacion5", label: "Amo (a) de casa" },
  { value: "ocupacion6", label: "Abogado" },
  { value: "ocupacion7", label: "Licenciado" },
  { value: "ocupacion8", label: "Otro" },
];

export const maximoNivelEstudios = [
  { value: "preescolar", label: "Preescolar" },
  { value: "primaria", label: "Primaria" },
  { value: "secundaria", label: "Secundaria" },
  { value: "preparatoria", label: "Bachillerato" },
  { value: "licenciatura", label: "Licenciatura" },
  { value: "maestria", label: "Maestría" },
  { value: "doctorado", label: "Doctorado" },
];

export const catalogoFamilia = [
  { value: "Padre", label: "Padre" },
  { value: "Madre", label: "Madre" },
  { value: "Hermanos", label: "Hermanos" },
  { value: "Hijos", label: "Hijos" },
  { value: "Abuelos", label: "Abuelos" },
  { value: "Cónyuge", label: "Cónyuge" },
  { value: "Otros familiares", label: "Otros familiares" },
  { value: "Amigos", label: "Amigos" },
  { value: "Solo", label: "Solo" },
  { value: "Otro", label: "Otro" },
];

export const casaVives = [
  { value: "propia", label: "Propia" },
  { value: "rentada", label: "Rentada" },
  { value: "prestada", label: "Prestada" },
  { value: "pagando", label: "Se esta pagando" },
  { value: "otro", label: "Otro" },
];

export const catalogosNumerosPersonas = [
  { value: "uno", label: "Uno" },
  { value: "dos", label: "Dos" },
  { value: "tres", label: "Tres" },
  { value: "cuatro", label: "Cuatro" },
  { value: "cinco", label: "Cinco" },
  { value: "seis", label: "Seis" },
  { value: "siete", label: "Siete" },
  { value: "ocho", label: "Ocho" },
  { value: "nueve", label: "Nueve" },
  { value: "mas", label: "Más de nueve" },
];

export const servicioMedico = [
  { value: "Ninguno", label: "Ninguno" },
  { value: "IMSS", label: "IMSS" },
  { value: "ISSSTE", label: "ISSSTE" },
  { value: "SEDENA", label: "SEDENA" },
  { value: "PEMEX", label: "PEMEX" },
  { value: "BIENESTAR", label: "INSABI" },
  { value: "SEGURO_PRIVADO", label: "SEGURO PRIVADO" },
];

export const tipoSangre = [
  { value: "A_positivo", label: "A +" },
  { value: "B_positivo", label: "B +" },
  { value: "O_positivo", label: "O +" },
  { value: "AB_positivo", label: "AB +" },
  { value: "A_negativo", label: "A -" },
  { value: "B_negativo", label: "B -" },
  { value: "O_negativo", label: "O -" },
  { value: "AB_negativo", label: "AB -" },
];

export const donador = [
  { value: "true", label: "Sí" },
  { value: "false", label: "No" },
];

export const entidadPreparatoria = [
  { value: "1", label: "Oaxaca" },
  { value: "2", label: "Chiapas" },
];

export const municipioPreparatoria = [
  { value: "1", label: "Juchitán" },
  { value: "2", label: "Salina Cruz" },
];
