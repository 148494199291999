import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import GroupsTable from "../tables/GroupsTable";

function ListGroups() {
  return (
    <DashboardAlt noPadding={true}>
      <GroupsTable />
    </DashboardAlt>
  );
}

export default ListGroups;
