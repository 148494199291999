function formatDate(str = "") {
  const [day, month, year] = str?.split("T")[0].split("-").reverse();
  const date = new Date(`${month}/${day}/${year}`);
  return date;
}

export const configAdmisionInitialState = (defaultValues = {}) => {
  const startDate = formatDate(defaultValues.startDate);
  // Nota: endDate no viene de la API
  const finalDate = formatDate(defaultValues.finalDate);
  const examDate = formatDate(defaultValues.examDate);

  const initialState = {
    startDate: startDate || "",
    endDate: finalDate || "",
    testDate: examDate || "",
    testTime: defaultValues.examTime || "",
    testType: defaultValues.typeOfExam || "",
    testMode: defaultValues.examModality || "",
    testUrl: defaultValues.urlExam || "",
    urlGuiaExam: defaultValues.urlGuiaExam || "",
    linkSII: defaultValues.linkSII || "",
    note: defaultValues.comments || "",
    bankPay: defaultValues.bank || "",
    bankAccount: defaultValues.accountNumber || "",
    testCost: defaultValues.costFicha || "",
    payUrl: defaultValues.linkPagosIti || "",
    nameJefe: defaultValues.jefeDep.nameJefe || "",
    gradoJefe: defaultValues.jefeDep.gradoJefe || "",
    emailJefe: defaultValues.jefeDep.emailJefe || "",
    userTelegram: defaultValues.userTelegram || "",
  };

  return initialState;
};
