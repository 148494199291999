import React from "react";
import style from "assets/css/tables/DesignTableGral.module.css";
import InputSearch from "components/Inputs/InputSearch";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputCheckTable from "components/Inputs/InputCheckTable";

function GroupsTable() {
  return (
    <div className={style.container}>
      <div className={style.contTitle}>
        <h1 className={style.title}>Grupos registrados</h1>
        <div className={style.contActions}>
          <p>Página 1 de 80</p>
          <div className={style.actFilter}>
            <button className={style.btnFilter}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className={style.btnFilter}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
          <div className={style.contSearch}>
            <InputSearch />
          </div>
        </div>
      </div>
      <div className={style.contTable}>
        <table className={style.table}>
          <HeadTable />
          <tbody className={style.bodyTable}>
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
            <DataTable />
          </tbody>
        </table>
      </div>
    </div>
  );
}

const HeadTable = () => (
  <thead className={style.headTable}>
    <tr className={style.contField}>
      <th className={style.field}>Carrera</th>
      <th className={style.field}>Materia</th>
      <th className={style.field}>Grupo</th>
      <th className={style.field}>Capacidad</th>
      <th className={style.field}>Semestre</th>
      <th className={style.field}>Periodo</th>
      <th className={style.field}>Exclusivo carrera</th>
    </tr>
  </thead>
);

const DataTable = () => (
  <tr className={style.row}>
    <td className={style.data}>Ing Sistemas computacionales, informatica</td>
    <td className={style.data}>Nombre de la materia</td>
    <td className={style.data}>X1</td>
    <td className={style.data}>32</td>
    <td className={style.data}>2</td>
    <td className={style.data}>Enero-Junio 2022</td>
    <td className={style.data}>
      <i className="fas fa-check" />
    </td>
  </tr>
);

export default GroupsTable;
