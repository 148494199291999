export default function getDateBirth(curp) {
  const currentYearCentury = parseInt(
    new Date().getFullYear().toString().substr(0, 2) + "00"
  );
  const lastYearCentury = currentYearCentury - 100;
  const yearCURP = parseInt(curp.substr(0, 2));
  const monthCURP = parseInt(curp.substr(2, 2)) - 1; //Enero inicia en 0
  const dayCURP = parseInt(curp.substr(4, 2));
  let birthdate = null;

  if (monthCURP >= 0 && monthCURP <= 11 && dayCURP >= 1 && dayCURP <= 31) {
    if (currentYearCentury + yearCURP > new Date().getFullYear()) {
      birthdate = new Date(
        lastYearCentury + yearCURP,
        monthCURP,
        dayCURP,
        0,
        0,
        0,
        0
      );
    } else {
      birthdate = new Date(
        currentYearCentury + yearCURP,
        monthCURP,
        dayCURP,
        0,
        0,
        0,
        0
      );
    }
  }

  return birthdate;
}
