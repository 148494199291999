import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useRegister() {
  const [loadingRegister, setLoadingRegister] = useState(false);

  const registerAspirant = async (registerData) => {
    try {
      setLoadingRegister(true);
      const REGISTER_ASPIRANT_URL =
        process.env.REACT_APP_REST_URI + "/auth/local/register";
      const registerResponse = await axios.post(
        REGISTER_ASPIRANT_URL,
        registerData
      );
      return { id: registerResponse.data.user.id };
    } catch (e) {
      ConsoleHelper(e.response.data, "error");
      if (e.response?.data?.error === "Bad Request") {
        return { error: e.response?.data?.message };
      }
    } finally {
      setLoadingRegister(false);
    }
  };

  return { loadingRegister, registerAspirant };
}

export default useRegister;
