// import React, { useState, useMemo, useEffect } from "react";
import React, { useEffect, useState } from "react";
// import { useQuery } from "@apollo/client";
import axios from "axios";

import styles from "assets/css/tables/Table.module.css";
import classnames from "classnames";
import FormNoteAspirant from "../forms/FormNoteAspirant";

import { AspirantSkeleton } from "skeletons/AspirantSkeleton";

import InputSearch from "components/Inputs/InputSearch";
import Modal from "components/modal/Modal";
import Profile from "components/Profile/Profile";
import Loading from "components/Loading";
import { error, success, warning } from "components/Alerts";
import { SimpleToggle } from "components/SimpleToggle";

// import Filter from "components/Inputs/Filter";

import { useLocalStorage } from "hooks/useLocalStorage";
import useGetPermissions from "hooks/useGetPermissions";
import useGetAspirants from "hooks/useGetAspirants";

// import ConsoleHelper from "ConsoleHelper";
// import { TABLE_ASPIRANTS } from "utils/graphql/querys";

const modalStates = { showAspirant: false, noteAspirant: false };

// const filterUsers = (value) => (item) => {
//   const { name, firstLastName, secondLastName, gender } = item;
//   const nombreCompleto = `${name} ${firstLastName} ${secondLastName}`;
//   if (
//     nombreCompleto.toLowerCase().trim().includes(value.toLowerCase().trim())
//   ) {
//     return true;
//   }
//   if (
//     gender &&
//     gender.toLowerCase().trim().includes(value.toLowerCase().trim())
//   ) {
//     return true;
//   }
//   if (item.student) {
//     const { processStatus, careerOptions } = item.student;
//     const careerOne = careerOptions[0]?.career?.shortName;
//     const careerTwo = careerOptions[1]?.career?.shortName;
//     if (
//       careerOne &&
//       careerOne.toLowerCase().trim().includes(value.toLowerCase().trim())
//     ) {
//       return true;
//     }
//     if (
//       careerTwo &&
//       careerTwo.toLowerCase().trim().includes(value.toLowerCase().trim())
//     ) {
//       return true;
//     }
//     if (
//       processStatus.toLowerCase().trim().includes(value.toLowerCase().trim())
//     ) {
//       return true;
//     }
//   }

//   return false;
// };

function Aspirants() {
  const { avaible, permiss } = useGetPermissions("student", [
    "find",
    "findone",
  ]);
  // ConsoleHelper(permiss);
  const {
    aspirants,
    getAspirants,
    loadingAspirants,
    pages,
    nextPage,
    previousPage,
    page,
    filterAspirants,
    toggleOnlyPaidAspirants,
    isOnlyPaidAspirants,
  } = useGetAspirants();
  // const { loading, data, refetch } = useQuery(TABLE_ASPIRANTS);
  const [loadingClick, setLoadingClick] = useState(false);
  const [user, setUser] = useState(null);
  const [student, setStudent] = useState(null);
  const [jwt] = useLocalStorage("jwt", null);
  const [activeModals, setActiveModals] = useState(modalStates);
  const [searchedValue, setSearchedValue] = useState("");
  const [statusAspirant, setStatusAspirant] = useState(null);

  // const [count, setCount] = useState(1);
  //const [options, setOptions] = useState([]);

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  // const memoizedAspirants = useMemo(
  //   () => (data ? data.users.filter(filterUsers(searchedValue)) : []),
  //   [searchedValue, data]
  // );

  useEffect(() => {
    if (searchedValue.length === 0) {
      getAspirants(page);
    } else {
      filterAspirants(searchedValue);
    }
  }, [getAspirants, filterAspirants, searchedValue, page]);

  if (avaible === null || permiss === null) return <Loading />;

  if (loadingClick) return <Loading />;

  const handleChangeSearchedValue = async (e) => {
    const value = e.target.value;
    setSearchedValue(value);
  };

  const handleClickAccept = async (id) => {
    warning(
      "¿Estás seguro de que los datos son correctos?",
      "Estas confirmando que los datos del aspirante son correctos y se le generará su ficha oficial.",
      "Confirmar",
      async () => {
        try {
          setLoadingClick(true);
          const BEARER_TOKEN = `Bearer ${jwt}`;
          const UPDATE_STUDENT_URL =
            process.env.REACT_APP_REST_URI + "/students/" + id;
          // const studentResponse =
          await axios.put(
            UPDATE_STUDENT_URL,
            {
              processStatus: "aspirante",
            },
            {
              headers: { Authorization: BEARER_TOKEN },
            }
          );
          // ConsoleHelper(studentResponse?.data);
          success(
            "Has confirmado los datos del aspirante",
            "Gracias por confirmar a este aspirante, ya se le ha mandado un correo con su ficha oficial",
            "Aceptar",
            () => {
              setActiveModals({
                ...activeModals,
                showAspirant: !activeModals["showAspirant"],
              });
              getAspirants(page);
            }
          );
        } catch (e) {
          // ConsoleHelper(e.response, "error");
          error(
            "Lo sentimos",
            "Ocurrió un error al confirmar los datos del aspirante"
          );
        } finally {
          setLoadingClick(false);
        }
      }
    );
  };

  const handleClickEditAspirant = async (text) => {
    try {
      setLoadingClick(true);
      const BEARER_TOKEN = `Bearer ${jwt}`;
      const SEND_NOTIFICATION_URL =
        process.env.REACT_APP_REST_URI + "/notifications";
      const UPDATE_STUDENT_URL =
        process.env.REACT_APP_REST_URI + "/students/" + student;
      const notificationResponse = await axios.post(
        SEND_NOTIFICATION_URL,
        {
          type: "observaciones",
          name: "Datos incorrectos",
          text,
          user,
        },
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      // ConsoleHelper(notificationResponse?.data);
      const idNoti = notificationResponse?.data?.id;
      if (idNoti) {
        // const studentResponse =
        await axios.put(
          UPDATE_STUDENT_URL,
          {
            processStatus: "observaciones",
          },
          {
            headers: { Authorization: BEARER_TOKEN },
          }
        );
        // ConsoleHelper(studentResponse?.data);
        setActiveModals({
          ...activeModals,
          noteAspirant: !activeModals["noteAspirant"],
        });
        success(
          "Mensaje enviado correctamente",
          "Se le ha enviado una notificación al aspirante con tu mensaje de corección",
          "Aceptar",
          () => {
            getAspirants(page);
          }
        );
      }
    } catch (e) {
      // ConsoleHelper(e.response, "error");
      error(
        "Lo sentimos",
        "Ocurrió un error al enviar el mensaje de correción al aspirante"
      );
    } finally {
      setLoadingClick(false);
    }
  };

  const Item = ({
    folio,
    name,
    degree,
    status,
    date,
    sex,
    idUser,
    idStudent,
  }) => {
    let isOk = false;
    if (
      status === "aspirante" ||
      status === "observaciones" ||
      status === "pagado" ||
      status === "corregido"
    ) {
      isOk = true;
    }

    const handleClickItem = () => {
      if (isOk) {
        setUser(idUser);
        setStudent(idStudent);
        setStatusAspirant(status);
        setActiveModals({
          ...activeModals,
          showAspirant: !activeModals["showAspirant"],
        });
      }
    };

    return (
      <tr>
        <td onClick={handleClickItem}>
          <div
            className={classnames(
              styles.contRow,
              styles.contStart,
              styles.contText
            )}
          >
            <p className={styles.text}>{folio}</p>
          </div>
        </td>
        <td onClick={handleClickItem}>
          <div className={styles.contRow}>
            <p className={styles.text}>{name}</p>
          </div>
        </td>
        <td onClick={handleClickItem}>
          <div className={styles.contRow}>
            {/* <p className={styles.text}>Ing. sistemas computacionales</p> */}
            <p className={styles.text}>{degree}</p>
          </div>
        </td>
        <td onClick={handleClickItem}>
          <div className={classnames(styles.contRow, styles.contText)}>
            <p
              className={classnames(
                styles.text,
                styles.txtStatus,
                styles[status]
              )}
            >
              {status}
            </p>
          </div>
        </td>
        <td onClick={handleClickItem}>
          <div className={classnames(styles.contRow, styles.contText)}>
            <p className={styles.text}>{date}</p>
          </div>
        </td>
        <td onClick={handleClickItem}>
          <div className={classnames(styles.contRow, styles.contText)}>
            <p className={styles.text}>{sex}</p>
          </div>
        </td>
        <td className={styles.columIcon}>
          <div
            className={classnames(
              styles.contRow,
              styles.contEnd,
              styles.contText
            )}
          >
            <div className={styles.contIcon}>
              {isOk && status !== "aspirante" && permiss?.update?.enabled && (
                <>
                  <button
                    className={styles.icon}
                    onClick={() => {
                      setUser(idUser);
                      setStudent(idStudent);
                      setActiveModals({
                        ...activeModals,
                        noteAspirant: !activeModals["noteAspirant"],
                      });
                    }}
                  >
                    <i className="fas fa-pen"></i>
                  </button>
                  {/* <button className={styles.icon}>
              <i className="fas fa-times"></i>
            </button> */}
                  <button
                    className={styles.icon}
                    onClick={() => handleClickAccept(idStudent)}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.contTitle}>
        <h1 className={styles.title}>Lista de solicitud de aspirantes</h1>
        <div className={styles.contActions}>
          <div className={styles.onlyPaidFilter}>
            <p>Solo Pagados</p>
            <SimpleToggle
              status={isOnlyPaidAspirants}
              onClick={toggleOnlyPaidAspirants}
            />
          </div>
          <p>
            Página {page} de {pages}
          </p>
          <div className={styles.actFilter}>
            <button className={styles.btnFilter} onClick={previousPage}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className={styles.btnFilter} onClick={nextPage}>
              <i className="fas fa-chevron-right"></i>
            </button>
            {/* {data?.users?.length > 10 && (
              <div className={styles.contFilter}>
                <Filter
                  onChange={(selectedValue) => {
                    const value = Number(selectedValue.value);
                    setCount(value);
                    setPage(1);
                  }}
                  options={options}
                />
              </div>
            )} */}
          </div>
          <div className={styles.contSearch}>
            <InputSearch
              onChange={handleChangeSearchedValue}
              value={searchedValue}
            />
          </div>
        </div>
      </div>
      <div className={styles.contTable}>
        <table className={styles.table}>
          <thead className={styles.headTable}>
            <tr className={styles.contColumn}>
              <th className={classnames(styles.column, styles.alignText)}>
                No
              </th>
              <th className={styles.column}>Nombre de aspirante</th>
              <th className={styles.column}>Carreras</th>
              <th className={classnames(styles.column, styles.alignText)}>
                Estado
              </th>
              <th className={classnames(styles.column, styles.alignText)}>
                Nacimiento
              </th>
              <th className={classnames(styles.column, styles.alignText)}>
                Sexo
              </th>
              <th className={classnames(styles.column, styles.alignText)}></th>
            </tr>
          </thead>
          <tbody className={styles.bodyTable}>
            {loadingAspirants
              ? Array(3)
                  .fill(0)
                  .map((value, index) => <AspirantSkeleton key={index} />)
              : aspirants.map((user, index) => {
                  return (
                    <Item
                      key={index}
                      folio={index + 1}
                      idUser={user.id}
                      idStudent={user.student?.id}
                      name={`${user.name} ${user.firstLastName} ${user.secondLastName}`}
                      degree={user.student?.careerOptions?.map(
                        (option, index) =>
                          `${option.career.shortName}` +
                          (index < user.student?.careerOptions.length - 1
                            ? `, `
                            : ``)
                      )}
                      // degree={user?.careerOptions.career}
                      status={
                        user.student
                          ? user.student?.processStatus
                          : "registrado"
                      }
                      date={user.dateOfBirth}
                      sex={user.gender === "Hombre" ? "m" : "f"}
                    />
                  );
                })}
            {/* {searchedValue
              ? memoizedAspirants.map((user, index) => (
                  <Item
                    key={index}
                    folio={index + 1}
                    idUser={user.id}
                    idStudent={user.student?.id}
                    name={
                      user &&
                      `${user.name} ${user.firstLastName} ${user.secondLastName}`
                    }
                    degree={user.student?.careerOptions?.map(
                      (option, index) =>
                        `${option.career.shortName}` +
                        (index < user.student?.careerOptions.length - 1
                          ? `, `
                          : ``)
                    )}
                    // degree={user?.careerOptions.career}
                    status={
                      user.student ? user.student?.processStatus : "registrado"
                    }
                    date={user.dateOfBirth}
                    sex={user.gender === "Hombre" ? "m" : "f"}
                  />
                ))
              : data &&
                dataPaginate.map((user, index) => (
                  <Item
                    key={index}
                    folio={user.i}
                    idUser={user.id}
                    idStudent={user.student?.id}
                    name={
                      user &&
                      `${user.name} ${user.firstLastName} ${user.secondLastName}`
                    }
                    degree={user.student?.careerOptions?.map(
                      (option, index) =>
                        `${option.career.shortName}` +
                        (index < user.student?.careerOptions.length - 1
                          ? `, `
                          : ``)
                    )}
                    // degree={user?.careerOptions.career}
                    status={
                      user.student ? user.student?.processStatus : "registrado"
                    }
                    date={user.dateOfBirth}
                    sex={
                      user.gender === "Hombre"
                        ? "m"
                        : user.gender === "Mujer" && "f"
                    }
                  />
                ))} */}
          </tbody>
        </table>
      </div>
      <Modal
        title="Perfil aspirante"
        show={activeModals.showAspirant}
        close={changeModalState("showAspirant")}
      >
        <div className={styles.contModal}>
          <Profile idUser={user} />
          {statusAspirant &&
            statusAspirant !== "aspirante" &&
            permiss?.update?.enabled && (
              <div className={styles.contBtnAction}>
                <button
                  className={styles.comment}
                  onClick={() => {
                    setActiveModals({
                      ...activeModals,
                      noteAspirant: !activeModals["noteAspirant"],
                    });
                  }}
                >
                  <i className="far fa-envelope"></i>
                  Mensaje
                </button>
                <button
                  className={styles.btnIcon}
                  onClick={() => handleClickAccept(student)}
                >
                  <i className="fas fa-check"></i>
                </button>
              </div>
            )}
        </div>
      </Modal>
      <Modal
        title="Nota para el aspirante"
        show={activeModals.noteAspirant}
        close={changeModalState("noteAspirant")}
      >
        <div className={styles.modalNote}>
          <FormNoteAspirant
            method={handleClickEditAspirant}
            user={user}
            student={student}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Aspirants;
