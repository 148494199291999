function validateRFC(rfc, aceptarGenerico = true) {
  const re =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var validado = rfc.match(re);

  if (!validado)
    //Coincide con el formato general del regex?
    return false;

  //Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
    rfcSinDigito = validado.slice(1).join(""),
    len = rfcSinDigito.length,
    //Obtener el digito esperado
    diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    indice = len + 1;
  var suma, digitoEsperado;

  if (Number(len) === 12) suma = 0;
  else suma = 481; //Ajuste para persona moral

  for (var i = 0; i < len; i++)
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - (suma % 11);
  if (Number(digitoEsperado) === 11) digitoEsperado = 0;
  else if (Number(digitoEsperado) === 10) digitoEsperado = "A";

  //El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if (
    Number(digitoVerificador) !== Number(digitoEsperado) &&
    (!aceptarGenerico ||
      Number(rfcSinDigito + digitoVerificador) !== "XAXX010101000")
  )
    return false;
  else if (
    !aceptarGenerico &&
    Number(rfcSinDigito + digitoVerificador) === "XEXX010101000"
  )
    return false;
  return rfcSinDigito + digitoVerificador;
}

export default validateRFC;
