import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormSchoolLevel from "../forms/FormSchoolLevel";

function CreateSchoolLevel() {
  return (
    <DashboardAlt>
      <FormSchoolLevel />
    </DashboardAlt>
  );
}

export default CreateSchoolLevel;
