import * as Yup from "yup";

import validateCurp from "utils/validateCurp";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const addDataGeneralSchema = Yup.object({
  app: Yup.string().required("Este campo es requerido."),
  apm: Yup.string().required("Este campo es requerido."),
  name: Yup.string().required("Este campo es requerido."),
  dateOfBirth: Yup.string().required("Este campo es requerido."),
  gender: Yup.string().required("Este campo es requerido."),
  nationality: Yup.string(),
  curp: Yup.string()
    .required("Este campo es requerido.")
    .test(
      "curp-valida",
      "Ingresa una curp válida",
      (curp = "default") => validateCurp(curp) === "Válido"
    ),
  RFC: Yup.string(),
  email: Yup.string().required("Este campo es requerido."),
  phone: Yup.string()
    .min(10, "Ingresa el número a 10 digitos.")
    .matches(phoneRegExp, "Número de teléfono inválido.")
    .required("Este campo es requerido."),
  phoneHouse: Yup.string()
    .min(10, "Ingresa el número a 10 digitos.")
    .matches(phoneRegExp, "Número de teléfono inválido."),
  maritalStatus: Yup.string().required("Este campo es requerido."),
  disabilities: Yup.string().required("Selecciona una opción."),
  areaOfOrigin: Yup.string().required("Selecciona una opción."),
  dialects: Yup.string().required("Selecciona una opción."),
  hasChildren: Yup.string().required("Selecciona una opción."),
});

export const addCareerSelectionSchema = Yup.object({
  optionOne: Yup.string().required("Selecciona una opción."),
  optionTwo: Yup.string().required("Selecciona una opción."),
  found: Yup.string().required("Selecciona una opción."),
  especifyFound: Yup.string().required("Este campo es requerido."),
  advertisement: Yup.string().required("Selecciona una opción."),
  utility: Yup.string().required("Selecciona una opción."),
  information: Yup.string().required("Selecciona una opción."),
  comments: Yup.string().required("Este campo es requerido."),
});

export const addHighSchoolSchema = Yup.object({
  code: Yup.string().required("Este campo es requerido."),
  name: Yup.string().required("Este campo es requerido."),
  federalEntity: Yup.string().required("Este campo es requerido."),
  municipality: Yup.string().required("Este campo es requerido."),
  graduationDate: Yup.date().typeError("Debes seleccionar una fecha."),
  average: Yup.number()
    .min(60, "Los datos solo pueden ser de 60 - 100.")
    .max(100, "Los datos solo pueden ser de 60 - 100.")
    .positive("Este campo solo admite números positivos."),
});

export const addAddressSchema = Yup.object({
  procedenceCp: Yup.string().required("Este campo es requerido."),
  procedenceEntity: Yup.string().required("Este campo es requerido."),
  procedenceTown: Yup.string().required("Este campo es requerido."),
  procedenceLocale: Yup.string().required("Selecciona una opción."),
  checkCurrent: Yup.boolean(),
  currentCp: Yup.string().required("Este campo es requerido."),
  currentEntity: Yup.string().required("Este campo es requerido."),
  currentTown: Yup.string().required("Este campo es requerido."),
  currentLocale: Yup.string().required("Selecciona una opción."),
  checkNumberHouse: Yup.boolean(),
  currentStreet: Yup.string().required("Este campo es requerido."),
  numberHouse: Yup.number().required("Este campo es requerido."),
});

export const addFatherSchema = Yup.object({
  firstLastName: Yup.string().required("Este campo es requerido."),
  secondLastName: Yup.string().required("Este campo es requerido."),
  name: Yup.string().required("Este campo es requerido."),
  occupation: Yup.string().required("Selecciona una opción."),
  degreeStudies: Yup.string().required("Selecciona una opción."),
});

export const addEconomySchema = Yup.object({
  currentlyLiveWith: Yup.array()
    .min(1, "Selecciona al menos una opción.")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  dependsEconomically: Yup.array()
    .min(1, "Selecciona al menos una opción.")
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  typeHouse: Yup.string().required("Selecciona una opción."),
  countRooms: Yup.string().required("Selecciona una opción."),
  countPeopleLiving: Yup.string().required("Selecciona una opción."),
  countPeopleDependentEconomically: Yup.string().required(
    "Selecciona una opción."
  ),
  working: Yup.boolean(),
  work: Yup.string().required("Este campo es requerido."),
  monthlyIncome: Yup.number()
    .min(1, "Este campo es requerido.")
    .positive("Este campo solo admite números positivos.")
    .required("Este campo es requerido."),
});

export const addEmergencySchema = Yup.object({
  medicalService: Yup.string().required("Selecciona una opción."),
  medicalServiceNumber: Yup.string().required("Este campo es requerido."),
  bloodType: Yup.string().required("Selecciona una opción."),
  organDonor: Yup.string().required("Selecciona una opción."),
  nameContactEmergency: Yup.string().required("Selecciona una opción."),
  phoneContactEmergency: Yup.string()
    .min(10, "Ingresa el número a 10 digitos.")
    .matches(phoneRegExp, "Número de teléfono inválido.")
    .required("Este campo es requerido."),
  postalCode: Yup.string().required("Este campo es requerido."),
  country: Yup.string().required("Este campo es requerido."),
  municipaly: Yup.string().required("Este campo es requerido."),
  colony: Yup.string().required("Selecciona una opción."),
  street: Yup.string().required("Este campo es requerido."),
});
