import React from "react";

import style from "assets/css/Charts/Classroom.module.css";
import ReactApexChart from "react-apexcharts";

function ChartStudentsCareer() {
  const series = [
    {
      name: "Estudiantes",
      data: [
        {
          x: "CAR1",
          y: 120,
          goals: [
            {
              name: "Expectativa",
              value: 150,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR2",
          y: 150,
          goals: [
            {
              name: "Expectativa",
              value: 100,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR3",
          y: 130,
          goals: [
            {
              name: "Expectativa",
              value: 150,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR4",
          y: 145,
          goals: [
            {
              name: "Expectativa",
              value: 170,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR5",
          y: 160,
          goals: [
            {
              name: "Expectativa",
              value: 100,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR6",
          y: 180,
          goals: [
            {
              name: "Expectativa",
              value: 190,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR7",
          y: 175,
          goals: [
            {
              name: "Expectativa",
              value: 190,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR8",
          y: 180,
          goals: [
            {
              name: "Expectativa",
              value: 200,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR9",
          y: 185,
          goals: [
            {
              name: "Expectativa",
              value: 100,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR10",
          y: 190,
          goals: [
            {
              name: "Expectativa",
              value: 200,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR11",
          y: 180,
          goals: [
            {
              name: "Expectativa",
              value: 190,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
        {
          x: "CAR12",
          y: 170,
          goals: [
            {
              name: "Expectativa",
              value: 180,
              strokeHeight: 3,
              strokeColor: "#084de2",
            },
          ],
        },
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Estudiantes por carreras",
      align: "left",
      offsetY: 16,
      floating: false,
      style: {
        fontSize: "0.9rem",
        fontWeight: "bold",
        fontFamily: "Arial, Helvetica, sans-serif",
        color: "#1b396a",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: "40%",
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Actual", "Expectativa"],
      markers: {
        fillColors: ["#ff374b", "#084de2"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      colors: ["#ff374b"],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#bbc3ec",
          fontSize: ".65rem",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#bbc3ec",
          fontSize: ".7rem",
        },
      },
    },
  };

  return (
    <>
      <ReactApexChart
        className={style.chart}
        options={options}
        series={series}
        type="bar"
        width={"100%"}
        height={"100%"}
      />
    </>
  );
}

export default ChartStudentsCareer;
