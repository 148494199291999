import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormEspecialties from "../forms/FormEspecialties";

function CreateSpecialties() {
  return (
    <DashboardAlt>
      <FormEspecialties />
    </DashboardAlt>
  );
}

export default CreateSpecialties;
