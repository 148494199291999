import React from "react";

import TemplateUser from "./components/TemplateUser";
import FormSettingPassword from "./forms/FormSettingPassword";

function SettingPassword() {
  return (
    <TemplateUser>
      <FormSettingPassword />
    </TemplateUser>
  );
}

export default SettingPassword;