import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useAddAddress() {
  const [loadingAddress, setLoadingAddress] = useState(false);

  const addAddress = async (token, addressData) => {
    try {
      setLoadingAddress(true);
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_USER_ADDRESS_URL =
        process.env.REACT_APP_REST_URI + "/addresses";
      const addressResponse = await axios.post(
        ADD_USER_ADDRESS_URL,
        addressData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(addressResponse?.data);
      return addressResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoadingAddress(false);
    }
  };

  const addUserAddress = async (token, addressData) => {
    const idOne = await addAddress(token, addressData[0]);
    if (idOne) {
      const idTwo = await addAddress(token, addressData[1]);
      if (idTwo) {
        return idTwo;
      }
    } else {
      return "error";
    }
  };

  return { loadingAddress, addUserAddress };
}

export default useAddAddress;
