import * as Yup from "yup";

export const reticlesInitialState = {
  career: "",
  officialKey: "",
  tecnmKey: "",
  totalCredits: "",
  minimunLoad: "",
  maximumLoad: "",
  startDate: "",
  liquidationDate: ""
};

export const reticlesValidationSchema = Yup.object({
  career: Yup.string().required("Este campo es requerido"),
  officialKey: Yup.string().required("Este campo es requerido"),
  tecnmKey: Yup.string().required("Este campo es requerido"),
  liquidationDate: Yup.date()
    .typeError("Debes seleccionar una fecha.")
    .required("Debes seleccionar una fecha."),
  startDate: Yup.date()
    .typeError("Debes seleccionar una fecha.")
    .required("Debes seleccionar una fecha."),
  maximumLoad: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  minimunLoad: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  totalCredits: Yup.number()
    .positive("Este campo solo admite números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido.")
});
