import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormCreateReticle from "../forms/FormCreateReticle";

function CreateReticle() {
  return (
    <DashboardAlt>
      <FormCreateReticle />
    </DashboardAlt>
  );
}

export default CreateReticle;
