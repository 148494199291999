import * as Yup from "yup";

export const createMatterSchema = Yup.object({
  academicArea: Yup.string().required("Selecciona una opción."),
  matter: Yup.string().required("Selecciona una opción."),
  theoreticalHours: Yup.number()
    .min(1, "No pueder ser menor o igual a 0.")
    .positive("Solo se admiten números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  practicalHours: Yup.number()
    .min(1, "No pueder ser menor o igual a 0.")
    .positive("Solo se admiten números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  totalCredits: Yup.number()
    .min(1, "No pueder ser menor o igual a 0.")
    .positive("Solo se admiten números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  requestCredits: Yup.number()
    .min(1, "No pueder ser menor o igual a 0.")
    .positive("Solo se admiten números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  officialKey: Yup.string().required("Selecciona una opción."),
  specialty: Yup.string().required("Selecciona una opción."),
  certificateOrder: Yup.number()
    .min(1, "No pueder ser menor o igual a 0.")
    .positive("Solo se admiten números positivos.")
    .integer("Solo se admiten números enteros.")
    .required("Este campo es requerido."),
  matterStatus: Yup.string().required("Selecciona una opción."),
});

export const addMatterReticle = Yup.object({
  academicArea: Yup.string().required("Selecciona una opción."),
  matter: Yup.string().required("Selecciona una opción."),
  afterMatter: Yup.string().required("Selecciona una opción."),
});
