import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useAddUserCareer() {
  const [loadingCareer, setLoadingCareer] = useState(false);

  const addCareer = async (token, careerData) => {
    try {
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_USER_CAREER_URL =
        process.env.REACT_APP_REST_URI + "/user-careers";
      const careerResponse = await axios.post(ADD_USER_CAREER_URL, careerData, {
        headers: { Authorization: BEARER_TOKEN },
      });
      ConsoleHelper(careerResponse?.data);
      return careerResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    }
  };

  const addQuestion = async (token, questionData) => {
    try {
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_QUESTION_URL = process.env.REACT_APP_REST_URI + "/questions";
      const questionResponse = await axios.post(
        ADD_QUESTION_URL,
        questionData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(questionResponse?.data);
      return questionResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    }
  };

  const addInquest = async (idUser, token) => {
    try {
      const BEARER_TOKEN = `Bearer ${token}`;
      const ADD_INQUEST_URL = process.env.REACT_APP_REST_URI + "/inquests";
      const inquestResponse = await axios.post(
        ADD_INQUEST_URL,
        { type: "Encuesta aspirante", user: idUser },
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(inquestResponse?.data);
      return inquestResponse?.data?.id;
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    }
  };

  const addUserCareer = async (idUser, token, careerData, questionsData) => {
    setLoadingCareer(true);
    const idOne = await addCareer(token, careerData[0]);
    if (idOne) {
      const idTwo = await addCareer(token, careerData[1]);
      if (idTwo) {
        const idInquest = await addInquest(idUser, token);
        if (idInquest) {
          questionsData.forEach((questionData) => {
            addQuestion(token, { ...questionData, inquest: idInquest });
          });
          setLoadingCareer(false);
          return idInquest;
        }
      }
    } else {
      setLoadingCareer(false);
      return "error";
    }
  };

  return { loadingCareer, addUserCareer };
}

export default useAddUserCareer;
