import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormGroups from "../forms/FormGroups";

function Groups() {
  return (
    <DashboardAlt>
      <FormGroups />
    </DashboardAlt>
  );
}

export default Groups;
