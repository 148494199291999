import React from "react";

import style from "assets/css/Error/ErrorData.module.css";
import BtnNotFound from "components/Buttons/BtnNotFound";
import image from "assets/img/theme/homepage.png";

function ErrorData({ title, btn, desc }) {
  return (
    <div className={style.container}>
      <img className={style.image} src={image} alt="" />
      <p className={style.title}>
        {title ? title : "Whoops! Tu sesión ha expirado"}
      </p>
      <p className={style.desc}>
        {desc
          ? desc
          : "Lo sentimos, tu sesión ha expirado por una inactividad. Debes iniciar sesión otra vez."}
      </p>
      {btn && <BtnNotFound />}
    </div>
  );
}

export default ErrorData;
