export const dataGeneralInitialState = {
  app: "",
  apm: "",
  name: "",
  dateOfBirth: "",
  gender: "",
  nationality: "",
  curp: "",
  RFC: "",
  email: "",
  phone: "",
  phoneHouse: "",
  maritalStatus: "",
  disabilities: "",
  areaOfOrigin: "",
  dialects: "",
  hasChildren: "",
};

export const careerSelectionInitialState = {
  optionOne: "",
  optionTwo: "",
  found: "",
  especifyFound: "",
  advertisement: "",
  utility: "",
  information: "",
  comments: "",
};

export const highSchoolInitialState = {
  code: "",
  name: "",
  federalEntity: "",
  municipality: "",
  graduationDate: "",
  average: "",
};

export const addressInitialState = {
  procedenceCp: "",
  procedenceEntity: "",
  procedenceTown: "",
  procedenceLocale: "",
  checkCurrent: false,
  currentCp: "",
  currentEntity: "",
  currentTown: "",
  currentLocale: "",
  checkNumberHouse: false,
  currentStreet: "",
  numberHouse: "",
};

export const dataFatherInitialState = {
  firstLastName: "",
  secondLastName: "",
  name: "",
  occupation: "",
  degreeStudies: "",
};

export const economyInitialState = {
  currentlyLiveWith: [],
  dependsEconomically: [],
  typeHouse: "",
  countRooms: "",
  countPeopleLiving: "",
  countPeopleDependentEconomically: "",
  working: false,
  work: "",
  monthlyIncome: "",
};

export const emergencyInitialState = {
  medicalService: "",
  medicalServiceNumber: "",
  bloodType: "",
  organDonor: "",
  nameContactEmergency: "",
  phoneContactEmergency: "",
  postalCode: "",
  country: "",
  municipaly: "",
  colony: "",
  street: "",
};
