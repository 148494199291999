import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import ListSpecialties from "../tables/ListSpecialties";

function Specialties() {
  return (
    <DashboardAlt>
      <ListSpecialties />
    </DashboardAlt>
  );
}

export default Specialties;
