import React, { useState } from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import Input from "components/Inputs/Input";
import InputDate from "components/Inputs/InputDate";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Collapse from "components/Collapse/Collapse";
import Modal from "components/modal/Modal";

import OptionEvent from "components/ItemOption";

import { Formik, Form } from "formik";

const modalStates = { addEvent: false };

function FormSchoolPeriods() {
  const [periods, setPeriods] = useState({
    holiday: false,
    holyWeek: false,
    exam: false,
    survey: false,
    selecMateria: false,
    qualification: false,
  });

  const [activeModals, setActiveModals] = useState(modalStates);

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Periodos escolares</h1>
      <div className={style.contForm}>
        <Formik>
          <Form>
            <Collapse title="Datos del periodo escolar">
              <div className={classnames(style.formDate, style.threeColumn)}>
                <InputDate title="Año" time={false} date="YYYY" />
                <div className={style.inputMedium}>
                  <InputSelect name="schoolLevel" title="Periodo" />
                </div>
                <InputDate
                  title="Inicio del periodo"
                  time={false}
                  date={true}
                />
                <InputDate title="Fin del periodo" time={false} date={true} />
                <Input name="careerKey" title="Días de clases" type="number" />
              </div>
            </Collapse>
            <Collapse title="Calendario escolar">
              {periods.holiday && <ItemPeriods title="Periodo vacacional" />}
              {periods.holyWeek && (
                <ItemPeriods title="Vacaciones de semana santa" />
              )}
              {periods.exam && <ItemPeriods title="Examenes espaciales" />}
              {periods.survey && <ItemPeriods title="Encuesta estudiantil" />}
              {periods.selecMateria && (
                <ItemPeriods title="Selección de materias para los alumnos" />
              )}
              {periods.qualification && (
                <ItemPeriods title="Registro de parciales" />
              )}
              <div className={style.contBtn}>
                <BtnPrincipal
                  type="button"
                  text="Agregar evento"
                  onClick={changeModalState("addEvent")}
                />
              </div>
            </Collapse>
            <div className={style.contBtn}>
              <BtnPrincipal
                text="Guardar"
                onClick={changeModalState("addEvent")}
              />
            </div>
          </Form>
        </Formik>
      </div>
      <Modal
        title="Agregar evento"
        width="40%"
        height="65%"
        show={activeModals.addEvent}
        close={changeModalState("addEvent")}
      >
        <div className={style.contModal}>
          <OptionEvent
            title="Periodo vacacional"
            status={periods.holiday}
            onClick={() =>
              setPeriods({ ...periods, holiday: !periods.holiday })
            }
          />
          <OptionEvent
            title="Vacaciones de semana santa"
            status={periods.holyWeek}
            onClick={() =>
              setPeriods({ ...periods, holyWeek: !periods.holyWeek })
            }
          />
          <OptionEvent
            title="Examenes espaciales"
            status={periods.exam}
            onClick={() => setPeriods({ ...periods, exam: !periods.exam })}
          />
          <OptionEvent
            title="Encuesta estudiantil"
            status={periods.survey}
            onClick={() => setPeriods({ ...periods, survey: !periods.survey })}
          />
          <OptionEvent
            title="Selección de materias para los alumnos"
            status={periods.selecMateria}
            onClick={() =>
              setPeriods({ ...periods, selecMateria: !periods.selecMateria })
            }
          />
          <OptionEvent
            title="Registro de parciales"
            status={periods.qualification}
            onClick={() =>
              setPeriods({ ...periods, qualification: !periods.qualification })
            }
          />
        </div>
      </Modal>
    </div>
  );
}

const ItemPeriods = ({ title }) => (
  <div className={style.contItem}>
    <h1 className={classnames(style.title, style.titleItem)}>{title}</h1>
    <div className={classnames(style.formDate, style.twoColumn)}>
      <InputDate title="Inicio" time={false} date={true} />
      <InputDate title="Fin" time={false} date={true} />
    </div>
  </div>
);

export default FormSchoolPeriods;
