import React, {useState, useEffect} from "react";
import { useQuery } from "@apollo/client";
import axios from "axios";

import styles from "assets/css/Profile/Profile.module.css";
import classnames from "classnames";

import man from "assets/img/theme/hombre.png";
import woman from "assets/img/theme/mujer.png";
import tutor from "assets/img/theme/tutor.png";
import { PROFILE_USER } from "utils/graphql/querys";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import { ocupaciones, tipoSangre } from "utils/dataSelectsAspirant";

function Profile({ idUser }) {
  const { data, loading, error } = useQuery(PROFILE_USER, {
    variables: {
      IdUser: idUser,
      State: "LIVE",
    },
  });

  const solicitud = data?.user.archivos.find(archivo => archivo.type === "solicitud aspirante")
  const ficha = data?.user.archivos.find(archivo => archivo.type === "ficha aspirante")

  if (loading) return <Loading />;

  if (error)
    return (
      <div>
        <p>Lo sentimos a ocurrido un error al traer los datos</p>
      </div>
    );

  const transformOccupation = (value) => {
    const result = ocupaciones.find((occupation) => occupation.value === value);
    return result ? result.label : "";
  };

  const transformBloodType = (value) => {
    const result = tipoSangre.find((blood) => blood.value === value);
    return result ? result.label : "";
  };

  return (
    <div className={styles.container}>
      <div className={styles.contInfo}>
        <Banner data={data} error={error} />
        <div className={styles.infoPrincipal}>
          <Item title="Telefono" desc={data?.user?.phone} />
          <Item
            title="Teléfono de casa"
            desc={data?.user?.phoneHouse ? data?.user?.phoneHouse : "-"}
          />
          <Item title="RFC" desc={data?.user?.RFC} />
          <Item title="Fecha de nacimiento" desc={data?.user?.dateOfBirth} />
        </div>
        <div className={styles.infoGral}>
          <div className={styles.relevant}>
            <div className={styles.contTitle}>
              <p className={styles.titleCard}>Datos generales</p>
            </div>
            <div className={styles.contData}>
              <DataGral title="Estado Civil" desc={data?.user?.maritalStatus} />
              <DataGral
                title="hijos"
                desc={!error && data?.user?.hasChildren ? "Sí" : "No"}
              />
              <DataGral
                title="Zona de procedencia"
                desc={data?.user?.areaOfOrigin}
              />
              <DataGral
                title="Lengua indígena"
                desc={data?.user?.dialects[0]?.name}
              />
              <DataGral
                title="Discapacidad"
                desc={data?.user?.disabilities[0]?.name}
              />
            </div>
          </div>
          <div className={styles.highSchool}>
            <div className={styles.school}>
              <p className={styles.title}>Preparatoria de procedencia</p>
              <p className={styles.nameSchool}>
                {data?.user?.student?.highSchool?.name}
              </p>
              <p className={styles.codeSchool}>
                {data?.user?.student?.highSchool?.code}
              </p>
              <div className={styles.ubication}>
                <p className={styles.icon}>
                  <i className="fas fa-map-marker-alt"></i>
                </p>
                <p className={styles.desc}>
                  {data?.user?.student?.highSchool &&
                    `${data?.user?.student?.highSchool?.municipality}, ${data?.user?.student?.highSchool?.federalEntity}`}
                </p>
              </div>
            </div>
            <div className={styles.date}>
              <div className={styles.graduation}>
                <p className={styles.title}>Fecha de egreso</p>
                <p className={styles.desc}>
                  {data?.user?.student?.highSchool?.graduationDate}
                </p>
              </div>
              <div className={styles.average}>
                <div className={styles.contNumber}>
                  <p className={styles.desc}>
                    {data?.user?.student?.highSchool?.average}
                  </p>
                </div>
                <div className={styles.contTitle}>
                  <p className={styles.title}>Promedio general</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.entity}>
            <Location
              title="Entidad de procedencia"
              data={data?.user?.addresses?.length !== 0}
              postal={!error && `CP. ${data?.user?.addresses[0]?.postalCode}`}
              neighborhood={data?.user?.addresses[0]?.colony}
              city={data?.user?.addresses[0]?.municipaly}
              entity={data?.user?.addresses[0]?.country}
            />
          </div>
          <div className={styles.address}>
            <Location
              title="Domicilio actual"
              data={data?.user?.addresses?.length !== 0}
              postal={!error && `CP. ${data?.user?.addresses[1]?.postalCode}`}
              neighborhood={data?.user?.addresses[1]?.colony}
              city={data?.user?.addresses[1]?.municipaly}
              entity={data?.user?.addresses[1]?.country}
              street={data?.user?.addresses[1]?.street}
              number={data?.user?.addresses[1]?.number}
            />
          </div>
        </div>
        <div className={styles.infoEconomic}>
          <div className={styles.contTitle}>
            <p className={styles.titleCard}>Datos económicos</p>
          </div>
          <div className={styles.dataEconomic}>
            <ItemDesc
              title="Vive actualmente con"
              desc={data?.user?.student?.economicData?.currentlyLiveWith?.lives}
            />
            <ItemDesc
              title="Depende económicamente de"
              desc={
                data?.user?.student?.economicData?.dependsEconomically?.depend
              }
            />
            <ItemDesc
              title="La vivienda es"
              desc={data?.user?.student?.economicData?.typeHouse}
            />
            <ItemDesc
              title="Cuartos en la vivienda"
              desc={data?.user?.student?.economicData?.countRooms}
            />
            <ItemDesc
              title="Cuantos viven en la vivienda"
              desc={data?.user?.student?.economicData?.countPeopleLiving}
            />
            <ItemDesc
              title="Dependen de la eco. principal"
              desc={
                data?.user?.student?.economicData
                  ?.countPeopleDependentEconomically
              }
            />
            <ItemDesc
              title="Trabajo"
              desc={data?.user?.student?.economicData?.work}
            />
            <ItemDesc
              title="Ingreso familiar"
              desc={
                data?.user?.student?.economicData
                  ? `$ ${data?.user?.student?.economicData?.monthlyIncome}`
                  : ""
              }
            />
          </div>
        </div>
        <div className={styles.contFile}>
          <div className={styles.contTitle}>
            <p className={styles.titleCard}>Documentación</p>
          </div>
          <div className={classnames(styles.viewFile, styles.itemAlt)}>
            {data?.user?.student?.certificate && (
              <ItemDoc
                icon="far fa-file-pdf"
                title="Documento"
                desc="Certificado de bachillerato"
                url={data?.user?.student?.certificate?.url}
              />
            )}
            {data?.user?.student?.birthCertificate && (
              <ItemDoc
                icon="far fa-file-pdf"
                title="Documento"
                desc="Acta de naciemiento"
                url={data?.user?.student?.birthCertificate?.url}
              />
            )}
            {data?.user?.student?.curp && (
              <ItemDoc
                icon="far fa-file-pdf"
                title="Documento"
                desc="CURP"
                url={data?.user?.student?.curp?.url}
              />
            )}
          
             {solicitud?.file?.url && (
              <ItemDoc
                icon="far fa-file-pdf"
                title="Documento"
                desc="Solicitud Aspirante"
                url={solicitud?.file?.url}
              />
            )}
             {ficha?.file?.url && (
              <ItemDoc
                icon="far fa-file-pdf"
                title="Documento"
                desc="Ficha Aspirante"
                url={ficha?.file?.url}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.contContact}>
        <div className={styles.contTutor}>
          <div className={styles.contTitle}>
            <p className={styles.title}>Tutores registrados</p>
          </div>
          <div className={styles.contItems}>
            {data?.user?.parents?.map((parent) => (
              <Tutor
                key={parent.id}
                image={
                  parent.type === "Mother"
                    ? woman
                    : parent.type === "Father"
                    ? man
                    : tutor
                }
                kinship={
                  parent.type === "Mother"
                    ? "Madre"
                    : parent.type === "Father"
                    ? "padre"
                    : "Tutor"
                }
                name={`${parent.name} ${parent.firstLastName} ${parent.secondLastName}`}
                occupation={transformOccupation(parent.occupation)}
                study={parent.degreeStudies}
              />
            ))}
          </div>
        </div>
        <div className={styles.contact}>
          <div className={styles.contTitle}>
            <p className={styles.title}>Datos de emergencia</p>
          </div>
          <div className={classnames(styles.infoContact, styles.itemAlt)}>
            <ItemAlt
              icon="fas fa-hospital"
              title="Servicio médico"
              desc={data?.user?.emergencyData?.medicalService}
            />
            <ItemAlt
              icon="fas fa-id-card-alt"
              title="Clave de derechohabiente"
              desc={data?.user?.emergencyData?.medicalServiceNumber}
            />
            <ItemAlt
              icon="fas fa-tint"
              title="Tipo de sangre"
              desc={transformBloodType(data?.user?.emergencyData?.bloodType)}
            />
            <ItemAlt
              icon="fas fa-lungs"
              title="¿Donador de organos?"
              desc={
                data?.user?.emergencyData
                  ? data?.user?.emergencyData?.organDonor
                    ? "Sí"
                    : "No"
                  : ""
              }
            />
            <ItemAlt
              icon="fas fa-user"
              title="Contacto de emergencia"
              desc={
                data?.user?.emergencyData?.nameContactEmergency &&
                `${data?.user?.emergencyData?.nameContactEmergency?.name} 
                ${data?.user?.emergencyData?.nameContactEmergency?.firstLastName} 
                ${data?.user?.emergencyData?.nameContactEmergency?.secondLastName}`
              }
            />
            <ItemAlt
              icon="fas fa-mobile-alt"
              title="Teléfono de contacto"
              desc={data?.user?.emergencyData?.phoneContactEmergency}
            />
            <ItemAlt
              icon="fas fa-hashtag"
              title="Código postal"
              desc={
                data?.user?.emergencyData?.nameContactEmergency?.address
                  ?.postalCode
              }
            />
            <ItemAlt
              icon="fas fa-globe-americas"
              title="Estado"
              desc={
                data?.user?.emergencyData?.nameContactEmergency?.address
                  ?.country
              }
            />
            <ItemAlt
              icon="fas fa-map-marker-alt"
              title="Municipio"
              desc={
                data?.user?.emergencyData?.nameContactEmergency?.address
                  ?.municipaly
              }
            />
            <ItemAlt
              icon="fas fa-street-view"
              title="Colonia localidad"
              desc={
                data?.user?.emergencyData?.nameContactEmergency?.address?.colony
              }
            />
            <ItemAlt
              icon="fas fa-road"
              title="Calle"
              desc={
                data?.user?.emergencyData?.nameContactEmergency?.address?.street
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Banner = ({ data, error }) => (
  <div className={styles.contBanner}>
    <div className={styles.banner}>
      <div className={styles.infoProfile}>
        <div className={styles.imgProfile}>
          {data?.user?.student?.photography?.url ? (
            <img
              className={styles.image}
              src={data?.user?.student?.photography?.url}
              alt="Foto de perfil"
            />
          ) : (
            <ProfileDefault />
          )}
        </div>
        <div className={styles.dataProfile}>
          <div className={styles.otherDesc}>
            <div className={styles.contCurp}>
              <p className={styles.title}>CURP</p>
              <p className={styles.desc}>{data?.user?.CURP}</p>
            </div>
            <div className={styles.contFrom}>
              <p className={styles.title}>País</p>
              <p className={styles.desc}>{data?.user?.nationality}</p>
            </div>
          </div>
          <div className={styles.profileDesc}>
            <h1 className={styles.name}>
              {!error &&
                `${data?.user?.name} ${data?.user?.firstLastName} ${data?.user?.secondLastName}`}
            </h1>
            <div className={styles.contDesc}>
              <p className={styles.desc}>
                {data?.user?.student?.careerOptions?.map(
                  (option, index) =>
                    `${option.career.name}` +
                    (index < data?.user?.student?.careerOptions.length - 1
                      ? ` · `
                      : ``)
                )}
              </p>
              <p className={styles.desc}>{data?.user?.email}</p>
            </div>
          </div>
          {/* <div className={styles.otherDesc}>
            <div className={styles.contCurp}>
              <p className={styles.title}>CURP</p>
              <p className={styles.desc}>{data?.user?.CURP}</p>
            </div>
            <img
              className={styles.flag}
              src="https://flagcdn.com/mx.svg"
              alt="Imagen de bandera"
            />
          </div> */}
        </div>
      </div>
    </div>
  </div>
);

const ProfileDefault = () => (
  <div className={styles.profileDefault}>
    <p className={styles.icon}>
      <i className="fas fa-user"></i>
    </p>
  </div>
);

const Item = ({ title, desc }) => (
  <div className={styles.itemBanner}>
    <p className={styles.title}>{title}</p>
    <p className={styles.desc}>{desc}</p>
  </div>
);

const ItemDesc = ({ title, desc }) => (
  <div className={styles.itemDesc}>
    <p className={styles.title}>{title}</p>
    {typeof desc === "object" ? (
      <div className={styles.contDesc}>
        {desc &&
          desc.map((data, index) => (
            <p key={index} className={styles.desc}>
              {data}
              {index < desc.length - 1 && ", "}
            </p>
          ))}
      </div>
    ) : (
      <p className={styles.desc}>{desc}</p>
    )}
  </div>
);

const ItemAlt = ({ icon, title, desc }) => (
  <div className={styles.item}>
    <div className={styles.itemIcon}>
      <p className={styles.icon}>
        <i className={icon}></i>
      </p>
    </div>
    <div className={styles.itemText}>
      <p className={styles.title}>{title}</p>
      <p className={styles.desc}>{desc}</p>
    </div>
  </div>
);

const ItemDoc = ({ icon, title, desc, url }) => (
  <Link
    className={styles.item}
    to={{ pathname: url }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className={styles.itemIcon}>
      <p className={styles.icon}>
        <i className={icon}></i>
      </p>
    </div>
    <div className={styles.itemText}>
      <p className={styles.title}>{title}</p>
      <p className={styles.desc}>{desc}</p>
    </div>
  </Link>
);

const DataGral = ({ title, desc }) => (
  <div className={styles.dataGral}>
    <p className={styles.title}>{title}</p>
    <p className={styles.desc}>{desc}</p>
  </div>
);

const Location = ({
  title,
  postal,
  neighborhood,
  city,
  entity,
  street,
  number,
  data,
}) => (
  <div className={styles.contLocation}>
    <div className={styles.contTitle}>
      <p className={styles.icon}>
        <i className="fas fa-map-marker-alt"></i>
      </p>
      <p className={styles.title}>{title}</p>
    </div>
    {data && (
      <div className={styles.contText}>
        <p className={styles.desc}>{city + ", " + entity}</p>
        <p className={styles.desc}>
          {(street ? street : "") +
            " " +
            neighborhood +
            " " +
            (number ? number : "")}
        </p>
        <p className={styles.desc}>{postal}</p>
      </div>
    )}
  </div>
);

const Tutor = ({ image, kinship, name, occupation, study }) => (
  <div className={styles.tutor}>
    <div className={styles.contImage}>
      <img className={styles.image} src={image} alt="" />
    </div>
    <div className={styles.infoTutor}>
      <p className={styles.kinship}>{kinship}</p>
      <p className={styles.name}>{name}</p>
      <p className={styles.desc}>{occupation}</p>
      <p className={styles.desc}>{study}</p>
    </div>
  </div>
);

export default Profile;
