import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import Collapse from "components/Collapse/Collapse";
import Dropzone from "components/Dropzone";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import { error, success } from "components/Alerts";
import Loading from "components/Loading";
import ConsoleHelper from "ConsoleHelper";
import InfoPhoto from "components/InfoPhoto";
import { useLocalStorage } from "hooks/useLocalStorage";

function FormDocumentation({ idStudent, idUser, token, edit }) {
  const [certificate, setCertificate] = useState([]);
  const [birthCertificate, setBirthCertificate] = useState([]);
  const [curp, setCurp] = useState([]);
  const [picture, setPicture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [student] = useLocalStorage("student", null);

  if (loading) return <Loading />;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (
      certificate.length === 0 ||
      birthCertificate.length === 0 ||
      curp.length === 0 ||
      picture.length === 0
    ) {
      error("Verificar", "Verifica que has subido todos los documentos");
    } else {
      delete certificate[0].preview;
      delete birthCertificate[0].preview;
      delete curp[0].preview;
      ConsoleHelper(certificate[0]);
      ConsoleHelper(birthCertificate[0]);
      ConsoleHelper(curp[0]);
      ConsoleHelper(picture);
      const response = await fillFormData();
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          if (edit) {
            success(
              "Se han actualizado tus documentos",
              "Gracias por corregir tus datos, serás dirigido a tu inicio",
              "Aceptar",
              () => window.location.reload(true)
            );
          } else {
            success(
              "Has finalizado el proceso de registro",
              "Se te ha generado tu solicitud de registro y se te mandó por correo, serás dirigido a tu inicio",
              "Aceptar",
              () => window.location.reload(true)
            );
          }
        }
      }
    }
  };

  const fillFormData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          user: idUser,
        })
      );
      formData.append(
        "files.certificate",
        certificate[0],
        "certificate_" + uuidv4()
      );
      formData.append("files.curp", curp[0], "curp_" + uuidv4());
      formData.append(
        "files.birthCertificate",
        birthCertificate[0],
        "birt_certificate_" + uuidv4()
      );
      formData.append(
        "files.photography",
        picture,
        "photo_" + uuidv4() + ".jpg"
      );
      const BEARER_TOKEN = `Bearer ${token}`;
      const UPLOAD_FILES_STUDENT_URL = `${
        process.env.REACT_APP_REST_URI
      }/students/${idStudent ? idStudent : student}`;
      const responseUploadFiles = await axios.put(
        UPLOAD_FILES_STUDENT_URL,
        formData,
        {
          headers: { Authorization: BEARER_TOKEN },
        }
      );
      ConsoleHelper(responseUploadFiles?.data);
      const idFiles = responseUploadFiles?.data?.id;
      if (idFiles) {
        const responseUpdateStatus = await axios.put(
          UPLOAD_FILES_STUDENT_URL,
          {
            processStatus: edit ? "corregido" : "enviado",
          },
          {
            headers: { Authorization: BEARER_TOKEN },
          }
        );
        ConsoleHelper(responseUpdateStatus?.data);
        return responseUpdateStatus?.data?.id;
      }
      return "error";
    } catch (e) {
      ConsoleHelper(e.response?.data, "error");
      if (e.response?.status >= 400) {
        return "error";
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Collapse title="Certificado de bachillerato o constancia de estudios">
        <div className={classnames(style.formDate, style.oneColumn)}>
          <Dropzone files={certificate} setFiles={setCertificate} />
        </div>
      </Collapse>
      <Collapse title="Acta de nacimiento">
        <div className={classnames(style.formDate, style.oneColumn)}>
          <Dropzone files={birthCertificate} setFiles={setBirthCertificate} />
        </div>
      </Collapse>
      <Collapse title="CURP">
        <div className={classnames(style.formDate, style.oneColumn)}>
          <Dropzone files={curp} setFiles={setCurp} />
        </div>
      </Collapse>
      <Collapse title="Fotografía">
        <div className={classnames(style.formDate, style.oneColumn)}>
          <InfoPhoto />
          <Dropzone crop type="image/jpeg" src={picture} setSrc={setPicture} />
        </div>
      </Collapse>
      <div className={style.contBtn}>
        <BtnPrincipal text="Finalizar registro" />
      </div>
    </form>
  );
}

export default FormDocumentation;
