import React from "react";

import style from "assets/css/Charts/Classroom.module.css";
import ProgressBar from "react-customizable-progressbar";

function RegisterAspirant({ progress }) {
  return (
    <div className={style.chart}>
      <ProgressBar
        // className={style.chart}
        strokeColor="#f47b20"
        strokeWidth={30}
        trackStrokeColor="#bbc3ec"
        trackStrokeWidth={30}
        progress={progress}
        radius={77}
      >
        <div className={style.indicator}>
          <span>{progress}%</span>
        </div>
      </ProgressBar>
    </div>
  );
}

export default RegisterAspirant;
