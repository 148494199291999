import React from "react";

import TemplateUser from "./components/TemplateUser";
import FormRegister from "./forms/FormRegister";

function PageRegister() {
  return (
    <TemplateUser>
      <FormRegister />
    </TemplateUser>
  );
}

export default PageRegister;
