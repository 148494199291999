import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import style from "assets/css/Forms/FormAlt.module.css";

// import { Form, Formik } from "formik";
// import BtnPrincipal from "components/Buttons/BtnPrincipal";
import DataTable from "./DataTable";
// import InputIcon from "components/Inputs/InputIcon";
import Loading from "components/Loading";
import { GET_SPECIALTIES } from "utils/graphql/querys";
import ErrorData from "components/Error/ErrorData";
import useUpdateData from "hooks/useUpdateData";
import Swal from "sweetalert2";
import ConsoleHelper from "ConsoleHelper";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "components/Inputs/Input";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputDate from "components/Inputs/InputDate";
import formatDate from "utils/formatDate";
import { error as errorAlert, success } from "../../../components/Alerts";

function ListSpecialties() {
  const { data, loading, error, refetch } = useQuery(GET_SPECIALTIES);
  const [loadingAdd] = useState(false);
  const [item, setItem] = useState();
  const { updateData } = useUpdateData();

  if (loadingAdd || loading) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleUpdate = async (values) => {
    Swal.fire({
      title: "Editar nombre",
      text: "Confirmar nuevo nombre del nivel",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, editar!",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = window.localStorage.getItem("jwt");
        const table = "/specialties/";
        ConsoleHelper(item);
        ConsoleHelper(values);
        const response = await updateData(item.id, token, table, {
          name: values.name,
          credits: values.credits,
          startPeriod: values.startPeriod,
          liquidationPeriod: values.liquidationPeriod,
        });
        ConsoleHelper(response);
        if (response?.idData) {
          success(
            "Actualizado correctamente",
            "Se actualizó la especialidad",
            "Aceptar",
            () => {},
            true
          );
        } else {
          errorAlert(
            "Ocurrió un error al actualizar",
            "No se pudo actualizar la especialidad",
            () => {}
          );
        }
        refetch();
      }
    });
  };

  const transformData = () => {
    let newData = [];
    if (data && data.specialties) {
      data.specialties.forEach((element) => {
        newData.push({
          id: element.id,
          name: element.name,
          secondary: element.credits,
          extraOne: element.startPeriod,
          extraTwo: element.liquidationPeriod,
        });
      });
    }
    return newData;
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.contTitle}>
          <h1 className={style.title}>Lista de especialidades</h1>
        </div>
      </div>
      <div className={style.contTable}>
        <DataTable
          data={transformData()}
          secondary="Creditos"
          extraOne="Inicio"
          extraTwo="Final"
          setItem={setItem}
          textLink="Crear especialidad"
          formcreate="/personal/createSpecialties"
        >
          <div className={style.editSpecialties}>
            <Formik
              initialValues={{
                name: item?.name,
                credits: item?.secondary,
                startPeriod: item?.extraOne,
                liquidationPeriod: item?.extraTwo,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Este campo es requerido."),
                credits: Yup.string().required("Este campo es requerido."),
                startPeriod: Yup.string().required("Este campo es requerido."),
                liquidationPeriod: Yup.string().required(
                  "Este campo es requerido."
                ),
              })}
              onSubmit={handleUpdate}
            >
              {({ values, errors, handleChange, touched }) => (
                <Form className={style.formEditSpecialties}>
                  <div className={style.inputs}>
                    <InputDate
                      error={touched.startPeriod && errors.startPeriod}
                      name="startPeriod"
                      title="Fecha inicial"
                      value={values?.startPeriod}
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("startPeriod")(formatDate(dateFromValue));
                      }}
                    />
                    <InputDate
                      error={
                        touched.liquidationPeriod && errors.liquidationPeriod
                      }
                      name="liquidationPeriod"
                      title="Fecha final"
                      value={values?.liquidationPeriod}
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("liquidationPeriod")(
                          formatDate(dateFromValue)
                        );
                      }}
                    />
                    <Input
                      name="name"
                      title="Especialidad"
                      value={values.name.toLowerCase()}
                    />
                    <Input
                      name="credits"
                      title="Creditos"
                      type="number"
                      value={values?.credits}
                    />
                  </div>
                  <BtnPrincipal text="Guardar" />
                </Form>
              )}
            </Formik>
          </div>
        </DataTable>
      </div>
    </div>
  );
}

export default ListSpecialties;
