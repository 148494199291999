import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";

import styles from "assets/css/tables/DataTable.module.css";
import InputSearch from "components/Inputs/InputSearch";
import Filter from "components/Inputs/Filter";
import Modal from "components/modal/Modal";
import LinkPage from "components/LinkPage";

const modalStates = { edit: false };

const filterData = (value) => (item) => {
  const { name, secondary } = item;
  if (name && name.toLowerCase().trim().includes(value.toLowerCase().trim())) {
    return true;
  }
  if (secondary) {
    if (
      secondary &&
      secondary.toLowerCase().trim().includes(value.toLowerCase().trim())
    ) {
      return true;
    }
  }
  return false;
};

function DataTable({
  data,
  hasCreado,
  secondary,
  name,
  extraOne,
  extraTwo,
  credits,
  clickModal,
  setItem,
  formcreate,
  textLink,
  children,
}) {
  const [searchedValue, setSearchedValue] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [dataPaginate, setDataPaginate] = useState([]);
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [activeModals, setActiveModals] = useState(modalStates);

  const memoizedData = useMemo(
    () => (data ? data.filter(filterData(searchedValue)) : []),
    [searchedValue, data]
  );

  useEffect(() => {
    let newData = [];
    data?.forEach((element, index) => {
      newData.push({ ...element, i: index + 1 });
    });
    const totalPages = Math.ceil(data?.length / count);
    const offset = (page - 1) * count;
    const current = newData.slice(offset, offset + count);
    const filter = Math.ceil(data?.length / 25);
    let options = [];
    options.push({ value: "" + 10, label: "" + 10 });
    for (let i = 1; i < filter; i++) {
      options.push({ value: "" + i * 25, label: "" + i * 25 });
    }
    options.push({ value: "" + data?.length, label: "Todos" });
    setDataPaginate(current);
    setTotalPages(totalPages);
    setOptions(options);
  }, [page, data, count]);

  const handleChangeSearchedValue = (e) => {
    const value = e.target.value;
    setSearchedValue(value);
  };

  const changeModalState = (modal, element) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
    setItem(element);
    // setDataSelect(element.name);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.contControl}>
          <div className={styles.contSearch}>
            <InputSearch
              onChange={handleChangeSearchedValue}
              value={searchedValue}
            />
          </div>
          {formcreate ? (
            <LinkPage text={textLink} link={formcreate} />
          ) : (
            <button className={styles.btnAdd} onClick={clickModal}>
              <span className={styles.icon}>
                <i className="fas fa-plus"></i>
              </span>
              Agregar
            </button>
          )}
        </div>
        <div className={styles.contFilter}>
          <p>
            Pág {page} de {totalPages}
          </p>
          <button
            className={styles.btnFilter}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className={styles.btnFilter}
            onClick={() => {
              if (page < totalPages) {
                setPage(page + 1);
              }
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
          <div className={styles.filter}>
            {data?.length > 10 && (
              <div className={styles.contFilter}>
                <Filter
                  onChange={(selectedValue) => {
                    const value = Number(selectedValue.value);
                    setCount(value);
                    setPage(1);
                  }}
                  options={options}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.contTable}>
        <table className={styles.table}>
          <thead className={styles.headTable}>
            <tr className={styles.contColumn}>
              <th className={styles.column}>No</th>
              <th className={styles.column}>{name ? name : "Nombre"}</th>
              {secondary && <th className={styles.column}>{secondary}</th>}
              {hasCreado && <th className={styles.column}>Creado por</th>}
              {extraOne ? (
                <th className={styles.column}>{extraOne}</th>
              ) : (
                <th className={styles.column}>Fecha creación</th>
              )}
              {extraTwo ? (
                <th className={styles.column}>{extraTwo}</th>
              ) : (
                <th className={styles.column}>Fecha modificado</th>
              )}
              {credits && <th className={styles.column}>Creditos</th>}
              <th className={styles.column}></th>
            </tr>
          </thead>
          <tbody className={styles.bodyTable}>
            {searchedValue
              ? memoizedData.map((element, index) => (
                  <RowTable
                    key={index}
                    index={index + 1}
                    data={element}
                    secondary={secondary}
                    hasCreado={hasCreado}
                    extraOne={extraOne}
                    extraTwo={extraTwo}
                  >
                    <button
                      className={styles.btnAction}
                      onClick={changeModalState(
                        "edit",
                        element.id,
                        element.name
                      )}
                    >
                      <i className="fas fa-pen"></i>
                    </button>
                  </RowTable>
                ))
              : data &&
                dataPaginate.map((element, index) => (
                  <RowTable
                    key={index}
                    index={element.i}
                    data={element}
                    secondary={secondary}
                    hasCreado={hasCreado}
                    extraOne={extraOne}
                    extraTwo={extraTwo}
                  >
                    <button
                      className={styles.btnAction}
                      onClick={changeModalState("edit", element)}
                    >
                      <i className="fas fa-pen"></i>
                    </button>
                  </RowTable>
                ))}
          </tbody>
        </table>
      </div>
      <Modal
        title="Editar"
        show={activeModals.edit}
        close={changeModalState("edit")}
      >
        {children}
      </Modal>
    </div>
  );
}

const RowTable = ({
  index,
  secondary,
  hasCreado,
  extraOne,
  extraTwo,
  data,
  children,
}) => (
  <tr>
    <td>{index}</td>
    <td>{data.name.toLowerCase()}</td>
    {secondary && <td>{data.secondary}</td>}
    {hasCreado && <td>{data.createdBy}</td>}
    {extraOne ? (
      <td>{data.extraOne}</td>
    ) : (
      <td>{moment(data.createdAt).format("DD MMMM, YYYY")}</td>
    )}
    {extraTwo ? (
      <td>{data.extraTwo}</td>
    ) : (
      <td>{moment(data.updatedAt).format("DD MMMM, YYYY")}</td>
    )}
    {data.credits && <td>{data.credits}</td>}
    <td>{children}</td>
  </tr>
);

export default DataTable;
