import React from "react";

import DashboardAlt from "../dashboards/DashboardAlt";
import FormAddClassroom from "../forms/FormAddClassroom";

function ListAspirant() {
  return (
    <DashboardAlt>
      <FormAddClassroom />
    </DashboardAlt>
  );
}

export default ListAspirant;
