import React, { useState } from "react";
import style from "assets/css/Dashboard/FindCredentials.module.css";

import DashboardAlt from "views/pages/dashboards/DashboardAlt";
import InputSearch from "components/Inputs/InputSearch";
import { warning } from "components/Alerts";


import {
  getAspirantByIdentifier,
  generateCredential,
  rejectPhotography,
} from "../../../../api/aspirants";
import CardCredential from "components/Card/CardCredential";
import BtnPrincipal from "components/Buttons/BtnPrincipal";

const FindCredentials = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchedValue, setSearchedValue] = useState("");
  const [aspirant, setAspirant] = useState(null);
  const [uploadPhoto, setUploadPhoto] = useState(false);

  const confirmReject = () => {
    try {
      warning(
        "¿Estás seguro?",
        "Esta acción no se puede revertir.",
        "Entiendo",
        onClickRejectPhotograpy
      );
    } catch (error) {
      // error
    }
  };

  const onChangeSearchedValue = (evt) => {
    setSearchedValue(evt.target.value);
  };

  const onSearchStudentByControlNumber = async () => {
    setError(null);
    setAspirant(null);
    setLoading(true);
    const aspirant = await getAspirantByIdentifier(searchedValue);
    if (aspirant.aspirantFinded) {
      if (!aspirant.aspirant.student.photography) {
        setError({
          message: "El estudiante no tiene una fotografía registrada",
          icon: "fas fa-user-times",
        });
        setUploadPhoto(true);
      } else {
        setAspirant(aspirant.aspirant);
        setUploadPhoto(false);
      }
    } else {
      setError({
        message: "Estudiante no encontrado, verifique sus datos",
        icon: "fas fa-user-times",
      });
      setUploadPhoto(false);
    }
    setLoading(false);
  };

  const onClickRejectPhotograpy = async () => {
    setLoading(true);
    await rejectPhotography(aspirant.student.photography._id);
    setAspirant(null);
    setLoading(false);
  };

  const onClickGenerateCredential = async () => {
    setLoading(true);
    await generateCredential(aspirant.student.matricula);
    setLoading(false);
  };

  return (
    <DashboardAlt>
      <div className={style.header}>
        <h1 className={style.title}>Buscar credenciales</h1>
        <div className={style.action}>
          <form className={style.search}>
            {!loading && (
              <InputSearch
                value={searchedValue}
                onChange={onChangeSearchedValue}
                onClickSearch={onSearchStudentByControlNumber}
              />
            )}
          </form>
        </div>
      </div>
      <section className={style.contCards}>
        <CardCredential data={aspirant} error={error} loading={loading} />
        <div className={style.contButtons}>
          {aspirant && !error ? (
            <>
              <BtnPrincipal
                onClick={confirmReject}
                danger
                text="Rechazar Fotografía"
              />
              <BtnPrincipal
                onClick={onClickGenerateCredential}
                text={loading ? "Generando..." : "Generar credencial"}
              />
            </>
          ) : uploadPhoto ? (
            <BtnPrincipal text="Subir fotografía" />
          ) : null}
        </div>
      </section>
    </DashboardAlt>
  );
};

export default FindCredentials;
