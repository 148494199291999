import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import style from "assets/css/Forms/FormRegister.module.css";

import BtnPrincipal from "components/Buttons/BtnPrincipal";
import InputIcon from "components/Inputs/InputIcon";
import InputCheck from "components/Inputs/InputCheck";
import InputBtnAction from "components/Inputs/InputBtnAction";
import Loading from "components/Loading";

import { registerInitialState } from "utils/states/register";
import { registerSchema } from "utils/schemas/register.schemas";

import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import useReCaptcha from "hooks/useReCaptcha";
import useRegister from "hooks/useRegister";
import { error } from "components/Alerts";
import { info } from "components/Alerts";
import ConsoleHelper from "ConsoleHelper";

function FormRegister() {
  const history = useHistory();
  const { loadingRegister, registerAspirant } = useRegister();
  const [stateApi, setStateApi] = useState(true);
  const { getToken } = useReCaptcha();

  if (loadingRegister) {
    return <Loading />;
  }

  const handleSubmit = async (values) => {
    ConsoleHelper(values);
    const {
      curp: CURP,
      email,
      name,
      confirmEmail,
      lastname: firstLastName,
      lastnameTwo: secondLastName
    } = values;

    if (email !== confirmEmail) {
      error(
        "Verifica que los correos estén bien",
        "Los correos ingresados no coinciden"
      );
    } else {
      getToken();
      const response = await registerAspirant({
        CURP,
        username: CURP,
        email,
        password: CURP,
        name,
        firstLastName,
        secondLastName
      });
      if (response.id) {
        info(
          "Ve a revisar tu correo",
          "Te hemos mandado un enlace a tu correo para que lo confirmes, revisa tu bandeja de spam si no lo encuentras.",
          () => history.push("/")
        );
      } else if (response.error) {
        error("Verifica", response.error);
        setStateApi(true);
      } else {
        error(
          "Lo sentimos esto no debió pasar",
          "Estamos trabajando para solucionarlo"
        );
      }
    }
  };

  const capitalizeText = (text) => {
    const split = text.split(" ");
    if (split.length > 1) {
      let texts = "";
      split.forEach((e) => {
        const first = e.charAt(0).toUpperCase();
        const remain = e.slice(1);
        texts += first + remain + " ";
      });
      return texts;
    }
    const first = text.charAt(0).toUpperCase();
    const remain = text.slice(1);
    return first + remain;
  };

  return (
    <Formik
      initialValues={registerInitialState}
      validationSchema={registerSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { handleChange, setFieldValue } = props;

        // const onChangeCurp = async (e) => {
        //   const curp = e.target.value;
        //   handleChange("curp")(curp.toUpperCase());
        //   if (curp.length === 18) {
        //     setStateApi(false);
        //   }
        // };

        const onChangeCurp = async (e) => {
          const curp = e.target.value;
          handleChange("curp")(curp.toUpperCase());
          if (curp.length === 18) {
            try {
              const SEARCH_CURP_URL = process.env.REACT_APP_CURP_URL;
              const curpResponse = await axios.post(SEARCH_CURP_URL, { curp });
              ConsoleHelper(curpResponse.data);
              if (curpResponse.data?.["0001"] === "CURP no encontrada.") {
                setStateApi(false);
              } else {
                capitalizeText(curpResponse.data?.Apellido1.toLowerCase());
                setFieldValue(
                  "lastname",
                  capitalizeText(curpResponse.data?.Apellido1.toLowerCase()),
                  false
                );
                setFieldValue(
                  "lastnameTwo",
                  capitalizeText(curpResponse.data?.Apellido2.toLowerCase()),
                  false
                );
                setFieldValue(
                  "name",
                  capitalizeText(curpResponse.data?.Nombres.toLowerCase()),
                  false
                );
                setStateApi(true);
              }
            } catch (e) {
              ConsoleHelper(e, "error");
              setStateApi(false);
            }
          }
        };

        return (
          <Form className={style.form}>
            <p className={style.title}>Crear cuenta de aspirante</p>
            <InputBtnAction
              name="curp"
              icon="far fa-id-card"
              iconBtn="fas fa-search"
              type="text"
              link
              href="https://www.gob.mx/curp/"
              maxLength={18}
              placeholder="CURP"
              onChange={onChangeCurp}
              autoComplete="new-password"
            />
            <div className={style.twoColumn}>
              <InputIcon
                name="lastname"
                icon="far fa-user"
                placeholder="Apellido paterno"
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
                disabled={stateApi}
              />
              <InputIcon
                name="lastnameTwo"
                icon="far fa-user"
                placeholder="Apellido materno"
                disabled={stateApi}
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
              />
            </div>
            <InputIcon
              name="name"
              icon="far fa-user"
              placeholder="Nombre"
              disabled={stateApi}
              onPaste={(e) => e.preventDefault()}
              autoComplete="new-password"
            />
            <div className={style.twoColumn}>
              <InputIcon
                name="email"
                icon="far fa-envelope"
                placeholder="Correo"
                onPaste={(e) => e.preventDefault()}
                autoComplete="off"
              />
              <InputIcon
                name="confirmEmail"
                icon="far fa-envelope"
                type="text"
                placeholder="Confirma tu correo"
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
              />
            </div>
            <div className={style.contAction}>
              <InputCheck
                name="privacy"
                text="Estoy de acuerdo con la"
                TxtLink="Política de privacidad"
                link
              />
              <Link className={style.descLog} to="/login">
                ¿Ya tienes cuenta? Inicia sesión
              </Link>
            </div>
            <BtnPrincipal text="Crear cuenta" />
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormRegister;
