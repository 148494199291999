import { useEffect, useState } from "react";
import axios from "axios";
import { error } from "components/Alerts";

const generateQueryParams = ({ page, limit }) => {
  return `limit: ${limit}, start: ${limit * (page - 1)}`;
};

const useGetStudents = (carrera, status) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  let estudiantes = [];
  const LIMIT = 100;

  const nextPage = () => {
    if (pages === page) return page;
    if (list.length > 0) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    setPage((prevState) => {
      if (prevState === 1) return 1;
      if (prevState > 1) {
        return prevState - 1;
      }
    });
  };

  const getPages = async (filters = "") => {
    const token = window.localStorage.getItem("jwt");
    if (!token) return;
    const { REACT_APP_REST_URI: uri } = process.env;
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.get(uri + "/users/count" + filters, {
      headers,
    });
    setPages(Math.ceil(data / LIMIT));
  };

  const getData = async (carrera, status, page = 1) => {
    try {
      setLoading(true);

      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_GRAPHQL_URI: uri } = process.env;
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.post(
        uri,
        {
          query: `{
            users(where: { student: { processStatus: "${status}", careerOptions:{career: "${carrera}"}}},${generateQueryParams(
            { limit: LIMIT, page }
          )} ),{
              id
              name
              firstLastName
              secondLastName
              dateOfBirth
              gender
              student {
                id
                processStatus
                careerOptions {
                  firstOption
                  career {
                    shortName
                  }
                }
              }
            }
          }
          `,
        },
        { headers }
      );
      setList(data.data.users);
    } catch (error) {
      setErr(error);
    } finally {
      setLoading(false);
    }
  };
  const updateStatus = async (statusIds, nextStatus) => {
    try {
      const idstudents = [];
      statusIds.forEach((id) => {
        idstudents.push({ student: id });
      });

      const token = window.localStorage.getItem("jwt");
      const updateData = {
        processStatus: nextStatus,
        students: idstudents,
      };
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const headers = { Authorization: `Bearer ${token}` };
      // await axios.post(uri + "/actualizacion-estado-lote", updateData, {
      //   headers,
      // });
      list.forEach((student) => {
        if (statusIds.includes(student.student.id)) {
          estudiantes.push(student);
        }
      });
      const response = {
        status: true,
        arrayStudents: estudiantes,
      };

      return response;
    } catch (e) {
      error(e.response.data.message);
      const response = {
        status: false,
      };

      return response;
    }
  };
  useEffect(() => {
    if (true) {
      getPages(`?[student.processStatus][$eq]=${status}`);
    } else {
      getPages();
    }
    getData(carrera, status, page);
  }, [carrera, status, page]);

  return {
    list,
    loading,
    err,
    updateStatus,
    pages,
    page,
    nextPage,
    previousPage,
  };
};

export default useGetStudents;
