import React from "react";

import style from "assets/css/components/LinkPage.module.css";
import { Link } from "react-router-dom";

function LinkPage({link, text}) {
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className={style.link}
    >
      {text}
      <span className={style.iconLink}>
        <i className="fas fa-plus"></i>
      </span>
    </Link>
  );
}

export default LinkPage;
