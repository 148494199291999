import { useState } from "react";
import axios from "axios";
import ConsoleHelper from "ConsoleHelper";

function useHighSchools() {
  const [loadingHigh, setLoadingHigh] = useState(false);

  const getHighSchool = async (code) => {
    try {
      setLoadingHigh(true);
      const HIGH_SCHOOL_URL = process.env.REACT_APP_PREPAS_URL;
      const response = await axios.post(HIGH_SCHOOL_URL, { cct: code });
      const highSchool = response?.data;
      const split = highSchool.C_NOMBRE.split('"');
      const objHigh = {
        name: split.length > 1 ? split[1] : split[0],
        entity: highSchool.INMUEBLE_C_NOM_ENT,
        town: highSchool.INMUEBLE_C_NOM_MUN,
      };
      return objHigh;
    } catch (e) {
      ConsoleHelper(e.response.data, "error");
    } finally {
      setLoadingHigh(false);
    }
  };

  return { loadingHigh, getHighSchool };
}

export default useHighSchools;
