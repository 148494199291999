export const LISTS_FOR_ADD_ENGLISH_CLASSES = `
  query ListsForAddEnglishClasses {
    englishLevels(where: { active: true }) {
      _id
      name
      active
    }
    englishPeriods {
      _id
      key
      startDate
      endDate
    }
    teachers {
      _id
      key
    }
  }
`;
