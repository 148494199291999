import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import HomeDivision from "../divEstudios/HomeDivision";
import DashboardAlt from "./DashboardAlt";

import { useLocalStorage } from "hooks/useLocalStorage";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";
import { GET_USER_ID, GET_CLASSROOMS } from "utils/graphql/querys";
import axios from "axios";

function DashboardDivision() {
  const [id] = useLocalStorage("id", null);
  const [role] = useLocalStorage("role", null);
  const [loadingUI, setLoadingUI] = useState(false);
  const [totalStudents, setTotalStudents] = useState(null);
  const history = useRef(useHistory());

  const getTotalStudents = async () => {
    setLoadingUI(true);
    const token = window.localStorage.getItem("jwt");
    if (!token) return;
    const { REACT_APP_REST_URI: uri } = process.env;
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.get(uri + "/users/count", {
      headers
    });
    setTotalStudents(data);
    setLoadingUI(false);
  };

  useEffect(() => {
    if (role && role === "Aspirante") {
      history.current.push("/404");
    }
    getTotalStudents();
  }, [role]);

  const { loading, data, error } = useQuery(GET_USER_ID, {
    variables: {
      IdUser: id,
      State: "LIVE"
    }
  });

  const classrooms = useQuery(GET_CLASSROOMS);

  if (loading || classrooms.loading || loadingUI) return <Loading />;

  if (error || classrooms.error) {
    return <ErrorData btn />;
  }

  return (
    <DashboardAlt>
      <HomeDivision
        data={data}
        classrooms={classrooms.data}
        students={totalStudents}
      />
    </DashboardAlt>
  );
}

export default DashboardDivision;
