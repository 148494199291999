import React from "react";

import { Link } from "react-router-dom";

import styles from "assets/css/Forms/FormReticle.module.css";
import Reticula from "../tables/Reticula";

function FormReticle() {
  return (
    <>
      <div className={styles.contTitle}>
        <h1 className={styles.title}>Reticula</h1>
        <div className={styles.contMenu}>
          <Link
            to="/personal/createReticle"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkPage}
          >
            Crear reticula
            <span className={styles.iconLink}>
              <i className="fas fa-plus"></i>
            </span>
          </Link>
          <Link
            to="/personal/AddSubject"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkPage}
          >
            Crear materia
            <span className={styles.iconLink}>
              <i className="fas fa-plus"></i>
            </span>
          </Link>
        </div>
      </div>
      <Reticula />
    </>
  );
}

export default FormReticle;
