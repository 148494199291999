import axios from "axios";

const { baseURL, headers } = {
  headers: { Authorization: `Bearer ${window.localStorage.getItem("jwt")}` },
  baseURL: process.env.REACT_APP_REST_URI || "http://localhost:3000",
};

const axiosInstance = axios.create({ baseURL, headers });

export default axiosInstance;
