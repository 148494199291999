import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";

import style from "assets/css/Forms/Form.module.css";

import { Form, Formik } from "formik";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Loading from "components/Loading";
import ErrorData from "components/Error/ErrorData";
import ConsoleHelper from "ConsoleHelper";
import { error as errorAlert, success } from "../../../components/Alerts";
import Collapse from "components/Collapse/Collapse";
import classNames from "classnames";
import InputSelect from "components/Inputs/InputSelect";
import Input from "components/Inputs/Input";
import InputDate from "components/Inputs/InputDate";
import formatDate from "utils/formatDate";
import {
  specialtiesValidationSchema,
  specialtiesInitialState
} from "utils/schemas/specialties.schemas";
import { GET_RETICLES } from "utils/graphql/querys";

function FormEspecialties() {
  const { data, loading, error, refetch } = useQuery(GET_RETICLES);
  const [loadingAdd, setLoadingAdd] = useState(false);

  if (loadingAdd || loading) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleSubmit = async (values) => {
    try {
      setLoadingAdd(true);
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/specialties";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      console.log(response);
      if (response) {
        success(
          "Agregado correctamente",
          "La especialidad ha sido creado correctamente.",
          "Aceptar",
          () => refetch(),
          true
        );
      }
    } catch (e) {
      ConsoleHelper(e.response, "error");
      errorAlert(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoadingAdd(false);
    }
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Crear especialidad</h1>
      <div className={style.contForm}>
        <Formik
          initialValues={specialtiesInitialState}
          validationSchema={specialtiesValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { errors, handleChange, touched } = props;
            return (
              <Form>
                <Collapse title="Datos de la especialidad">
                  <div className={classNames(style.formDate, style.twoColumn)}>
                    <Input
                      name="key"
                      title="Clave de la especialidad"
                      type="text"
                    />
                    <Input
                      name="name"
                      title="Nombre de especialidad"
                      type="text"
                    />
                    <InputSelect
                      error={touched.reticle && errors.reticle}
                      name="reticle"
                      title="Reticula"
                      options={data.reticles}
                      onChange={(selectedOption) => {
                        handleChange("reticle")(selectedOption.value);
                      }}
                    />
                    <InputDate
                      error={touched.startPeriod && errors.startPeriod}
                      name="startPeriod"
                      title="Inicio de periodo"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("startPeriod")(formatDate(dateFromValue));
                      }}
                    />
                    <InputDate
                      error={
                        touched.liquidationPeriod && errors.liquidationPeriod
                      }
                      name="liquidationPeriod"
                      title="Fin de periodo"
                      time={false}
                      date={true}
                      onChange={(dateFromValue) => {
                        handleChange("liquidationPeriod")(
                          formatDate(dateFromValue)
                        );
                      }}
                    />
                    <Input name="credits" title="Creditos" type="number" />
                  </div>
                </Collapse>
                <div className={style.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormEspecialties;
