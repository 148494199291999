import React, { useState } from "react";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";

import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Modal from "components/modal/Modal";
import ItemOption from "components/ItemOption";

import { dataFatherInitialState } from "utils/states/aspirant";
import { addFatherSchema } from "utils/schemas/aspirant.schemas";
import { error } from "components/Alerts";
import { maximoNivelEstudios } from "utils/dataSelectsAspirant";
import { ocupaciones } from "utils/dataSelectsAspirant";
import useAddParent from "hooks/useAddParent";
import Loading from "components/Loading";
import ConsoleHelper from "ConsoleHelper";
import CheckData from "components/CheckData";

const modalStates = { addTutor: false };

function FormTutor({ setPosition, idUser, token }) {
  const { loadingParent, addUserParent } = useAddParent();
  const [data, setData] = useState({
    father: {},
    mother: {},
    tutor: {},
  });
  const [tutor, setTutor] = useState({
    father: false,
    mother: false,
    tutor: false,
  });
  const [activeModals, setActiveModals] = useState(modalStates);

  if (loadingParent) return <Loading />;

  const changeModalState = (modal) => async () => {
    setActiveModals({ ...activeModals, [modal]: !activeModals[modal] });
  };

  const handleSubmitFinish = async () => {
    ConsoleHelper(data);
    if (
      Object.keys(data.father).length === 0 &&
      Object.keys(data.mother).length === 0 &&
      Object.keys(data.tutor).length === 0
    ) {
      error("Verificar", "Debes agregar al menos un familiar");
    } else {
      ConsoleHelper("esta bien");
      const response = await addUserParent(idUser, token, data);
      if (response) {
        if (response === "error") {
          error(
            "Lo sentimos",
            "Ah ocurrido un error al registrar los datos, intenta de nuevo más tarde"
          );
        } else {
          setPosition(5);
        }
      }
    }
  };

  return (
    <>
      {tutor.father && (
        <Collapse
          title="Datos del padre"
          deletebtn={true}
          onClick={() => {
            setTutor({ ...tutor, father: !tutor.father });
            setData({ ...data, father: {} });
          }}
        >
          <FormInputs
            name="father"
            text="Datos del padre registrados"
            data={data}
            setData={setData}
          />
        </Collapse>
      )}
      {tutor.mother && (
        <Collapse
          title="Datos de la madre"
          deletebtn={true}
          onClick={() => {
            setTutor({ ...tutor, mother: !tutor.mother });
            setData({ ...data, mother: {} });
          }}
        >
          <FormInputs
            name="mother"
            text="Datos de la madre registrados"
            data={data}
            setData={setData}
          />
        </Collapse>
      )}
      {tutor.tutor && (
        <Collapse
          title="Datos del tutor"
          deletebtn={true}
          onClick={() => {
            setTutor({ ...tutor, tutor: !tutor.tutor });
            setData({ ...data, tutor: {} });
          }}
        >
          <FormInputs
            name="tutor"
            text="Datos del tutor registrados"
            data={data}
            setData={setData}
          />
        </Collapse>
      )}
      <div className={style.contButtons}>
        <BtnPrincipal
          type="button"
          text="Agregar"
          secondary={true}
          onClick={changeModalState("addTutor")}
        />

        <BtnPrincipal
          type="button"
          text="Guardar y continuar"
          onClick={handleSubmitFinish}
        />
      </div>
      <Modal
        title="Agregar tutor"
        show={activeModals.addTutor}
        close={changeModalState("addTutor")}
      >
        <div className={classnames(style.contModal, style.modalSmall)}>
          <ItemOption
            title="Padre"
            status={tutor.father}
            onClick={() => {
              setData({ ...data, father: {} });
              setTutor({ ...tutor, father: !tutor.father });
            }}
          />
          <ItemOption
            title="Madre"
            status={tutor.mother}
            onClick={() => {
              setData({ ...data, mother: {} });
              setTutor({ ...tutor, mother: !tutor.mother });
            }}
          />
          <ItemOption
            title="Tutor"
            status={tutor.tutor}
            onClick={() => {
              setData({ ...data, tutor: {} });
              setTutor({ ...tutor, tutor: !tutor.tutor });
            }}
          />
        </div>
      </Modal>
    </>
  );
}

const FormInputs = ({ name, text, data, setData }) => {
  const handleSubmit = (values) => {
    ConsoleHelper({ [name]: values });
    setData({ ...data, [name]: values });
  };

  return (
    <Formik
      initialValues={dataFatherInitialState}
      validationSchema={addFatherSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { errors, handleChange, touched } = props;
        return (
          <Form>
            <div className={classnames(style.formDate, style.threeColumn)}>
              <Input
                name="firstLastName"
                title="Apellido paterno"
                type="text"
                onPaste={(e) => e.preventDefault()}
                autoComplete="off"
              />
              <Input
                name="secondLastName"
                title="Apellido materno"
                type="text"
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
              />
              <Input
                name="name"
                title="Nombre"
                type="text"
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
              />
              <InputSelect
                error={touched.occupation && errors.occupation}
                name="occupation"
                title="Ocupación o trabajo"
                options={ocupaciones}
                onChange={(selectedOption) => {
                  handleChange("occupation")(selectedOption.value);
                }}
              />
              <InputSelect
                error={touched.degreeStudies && errors.degreeStudies}
                name="degreeStudies"
                title="Máximo grado de estudios"
                options={maximoNivelEstudios}
                onChange={(selectedOption) => {
                  handleChange("degreeStudies")(selectedOption.value);
                }}
              />
            </div>
            {data[name].hasOwnProperty("name") ? (
              <CheckData text={text} />
            ) : (
              <div className={style.contButtons}>
                <BtnPrincipal text="Registrar" />
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormTutor;
