import React, { useState } from "react";
import axios from "axios";

import style from "assets/css/Forms/Form.module.css";
import classnames from "classnames";

import { Form, Formik } from "formik";
import Collapse from "components/Collapse/Collapse";
import Input from "components/Inputs/Input";
import InputSelect from "components/Inputs/InputSelect";
import BtnPrincipal from "components/Buttons/BtnPrincipal";
import Loading from "components/Loading";
import useGetPermissions from "hooks/useGetPermissions";
import { useQuery } from "@apollo/client";
import { GET_SUBJECT_TYPE_LEVEL_AREA } from "utils/graphql/querys";
import ErrorData from "components/Error/ErrorData";
import ConsoleHelper from "ConsoleHelper";
import { addSubjectInitialState } from "utils/states/subjects";
import { addSubjectSchema } from "utils/schemas/subjects.schemas";
import { error as errorAlert, success } from "../../../components/Alerts";

function FormAddSubject() {
  const { data, loading, error } = useQuery(GET_SUBJECT_TYPE_LEVEL_AREA);
  const { avaible } = useGetPermissions("school-subject", ["create", "update"]);
  const [loadingSubject, setLoadingSubject] = useState(false);

  if (avaible === null || loading || loadingSubject) return <Loading />;

  if (error)
    return (
      <ErrorData
        title="Ocurrió un error con los datos"
        desc="Lo sentimos no pudimos traer los datos para poder mostrarlos, intenta más tarde"
      />
    );

  const handleSubmit = async (values) => {
    try {
      setLoadingSubject(true);
      ConsoleHelper(values);
      values = {
        ...values,
        status: true,
        accreditationClassification:
          values.accreditationClassification.toString()
      };
      delete values.key;
      const token = window.localStorage.getItem("jwt");
      if (!token) return;
      const { REACT_APP_REST_URI: uri } = process.env;
      const API_URL = uri + "/school-subjects";
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(API_URL, values, { headers });
      console.log(response);
      if (response.status === 201) {
        success(
          "Agregado correctamente",
          "La materia ha sido creado correctamente.",
          "Aceptar",
          () => {}
        );
      } else if (response.status === 200) {
        errorAlert("Lo sentimos", response.data.message);
      }
    } catch (e) {
      ConsoleHelper(e.response);
      errorAlert(
        "Error inesperado",
        "Lo sentimos esto no debió pasar, estamos trabajando en eso."
      );
    } finally {
      setLoadingSubject(false);
    }
  };

  return (
    <div className={style.container}>
      <h1 className={style.title}>Crear nueva materia</h1>
      <div className={style.contForm}>
        <Formik
          initialValues={addSubjectInitialState}
          validationSchema={addSubjectSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { handleChange, touched, errors } = props;

            return (
              <Form>
                <Collapse title="Nombre de la materia">
                  <div className={classnames(style.formDate, style.twoColumn)}>
                    <div className={style.inputLong}>
                      <Input
                        name="name"
                        title="Nombre completo de la materia"
                        type="text"
                      />
                    </div>
                    <Input
                      name="subjectKey"
                      title="Clave de la materia"
                      type="text"
                    />
                    <Input
                      name="shortName"
                      title="Nombre abreviado de la materia"
                      type="text"
                    />
                  </div>
                </Collapse>
                <Collapse title="Datos de la materia">
                  <div className={classnames(style.formDate, style.twoColumn)}>
                    <InputSelect
                      error={touched.school_level && errors.school_level}
                      name="school_level"
                      title="Nivel escolar"
                      options={data.schoolLevels}
                      onChange={(selectedOption) => {
                        handleChange("school_level")(selectedOption.value);
                      }}
                    />
                    <InputSelect
                      error={touched.subject_type && errors.subject_type}
                      name="subject_type"
                      title="Tipo de materia"
                      options={data.subjectTypes}
                      onChange={(selectedOption) => {
                        handleChange("subject_type")(selectedOption.value);
                      }}
                    />
                    <InputSelect
                      error={touched.academic_area && errors.academic_area}
                      name="academic_area"
                      title="Área académica"
                      options={data.academicAreas}
                      onChange={(selectedOption) => {
                        handleChange("academic_area")(selectedOption.value);
                      }}
                    />
                    <Input
                      name="accreditationClassification"
                      title="Calificación acreditación"
                      type="number"
                    />
                  </div>
                </Collapse>
                <div className={style.contBtn}>
                  <BtnPrincipal text="Guardar" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FormAddSubject;
